<!-- <div class="schedulerPopUp-container"> -->
<mat-card class="schedulerPopUp" [class.schedulerEnabled]="schedulerEnabled" (click)="cancelThru($event)" cdkDrag
  cdkDragBoundary=".dragBoundary" [ngStyle]="{'z-index': _zIndex}" (cdkDragStarted)="dragged()">
  <mat-card-title style="margin-bottom: 0px;">
    <div class="popUp-example-handle" cdkDragHandle>
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px" style="align-items: center;height: 45px;">
        <div id="temp-rtmp-player" style="display: none;">

        </div>
        <div fxFlex="90" style="text-align:left">
          {{'Run of Show' | translate}}
        </div>
        <!-- <div fxFlex="70" style="text-align:center">
          <span *ngIf="!sessionStarted">{{'Live' | translate}}</span>
          <span *ngIf="sessionStarted">{{'Offline' | translate}}</span>
        </div> -->
        <div fxFlex="10" style="text-align:right">
          <mat-icon class="actions" (click)="toggleScheduler();">cancel</mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px" class="liveRow"
        [class.liveRowOffline]="!sessionStarted">
        <div fxFlex="30" style="text-align:left;font-size:14px">
          <mat-form-field style="width: 100%;margin-left:5px;font-weight:bold">
            <mat-select (selectionChange)="goToSession($event.value)" [(ngModel)]="sessionId" class="colorWhite">
              <mat-option value="" disabled>Select {{'session' | translate}}</mat-option>
              <mat-option *ngFor="let itm of sessionList" [value]="itm._id" [disabled]="itm.isDisabled"
                title="{{itm.name}}">{{itm.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="20" style="font-size: 0.95rem;font-weight: bold;text-align: center;">
          <span *ngIf="sessionStarted">{{'Status' | translate}}<br>{{'Live' | translate}}</span>
          <span *ngIf="!sessionStarted">{{'Status' | translate}}<br>{{'Offline' | translate}}</span>
        </div>
        <div fxFlex="25" style="text-align:right" *ngIf="isHost">
          <button mat-button *ngIf="sessionStarted" style="font-weight: bold;" [matMenuTriggerFor]="refreshSession"
            title="{{'Refresh Session' | translate}}">{{'Refresh Session' |
            translate}}</button>
          <mat-menu #refreshSession="matMenu">
            <button mat-menu-item (click)="sendRefreshSession('attendees')">
              <span>{{ 'Attendees' | translate }}</span>
            </button>
            <button mat-menu-item (click)="sendRefreshSession('teammember')">
              <span>{{ 'Team members' | translate }}</span>
            </button>
            <button mat-menu-item (click)="sendRefreshSession('both')">
              <span>{{ 'Both' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div fxFlex="25" style="text-align:right" *ngIf="!isKeepActive">
          <button mat-button *ngIf="!sessionStarted" style="font-weight: bold;"
            (click)="callToStartSession('start-session')" title="{{'Start Session' | translate}}">{{'Start Session' |
            translate}}
          </button>
          <button mat-button *ngIf="sessionStarted" style="font-weight: bold;"
            (click)="callToStartSession('end-session')" title="{{'End Session' | translate}}">{{'End Session' |
            translate}}
          </button>
        </div>
      </div>
    </div>
  </mat-card-title>
  <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px" class="schedulerTopActions">
        <div fxFlex="30">
          <mat-form-field style="font-size: 0.95rem;margin-left:5px;">
            <!-- <mat-label>Add {{'Task' | translate}}</mat-label> -->
            <mat-select (selectionChange)="addTask($event.value)" [(ngModel)]="taskSelected">
              <mat-option value="" disabled>Add a task</mat-option>
              <ng-container *ngFor="let itm of tasksKeysToShow">
                <mat-option [value]="itm">{{tasksName[itm].displayName | translate}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="70" style="text-align:right">
          <button mat-button (click)="resetSessionData()" title="{{'Reset Tasks' | translate}}"
            style="margin-left: 15px;float: left;">
            {{'Reset Tasks' | translate}}
          </button>
          <button mat-button (click)="refreshSessionData()" title="{{'Refresh' | translate}}" style="margin-right:5px">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-button (click)="savescheduler()" title="{{'Save' | translate}}">Save <mat-icon>
              playlist_add_check</mat-icon></button>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px" class="schedulerTop2Actions">
        <div fxFlex>
          <span class="disablePlaceholders" title="Show/Hide component placeholders">
            <mat-icon *ngIf="disablePlaceholders" (click)="setDisablePlaceholders(false)">toggle_off</mat-icon>
            <mat-icon *ngIf="!disablePlaceholders" class="isOn" (click)="setDisablePlaceholders(true)">toggle_on
            </mat-icon>
            <span>Placeholders</span>
          </span>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px">
        <div fxFlex class="schedulerListContent">
          <div class="loadingBox" *ngIf="loading">
            <mat-progress-spinner [diameter]="30" class="mat-spinner-color" color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <div *ngIf="schedulerTasks.length > 0" cdkDropList class="scheduler-example-list"
            (cdkDropListDropped)="dropschedulerTask($event)">
            <ng-container *ngFor="let task of schedulerTasks;let index = index">
              <div class="scheduler-example-box" cdkDrag title="{{tasksName[task.name].tooltip | translate}}">
                <!-- <div class="scheduler-example-handle" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div> -->
                <div fxLayout="row" fxLayout.lt-sm="column" fxFlex fxLayoutGap="20px" style="align-items: center;">
                  <div fxFlex="5" style="text-align:left">
                    <mat-icon *ngIf="task.action === '' && task.name !== 'end-session'" class="subActions"
                      [class.subActionsStart]="task.action === 'play'"
                      [class.subActionsCompleted]="task.action === 'stop'"
                      [class.subActionsDisable]="actionStatus(task)" (click)="callGaurdAction(index, task);"
                      title="{{'Start' | translate}}">play_circle_outline</mat-icon>
                    <mat-icon *ngIf="task.action === 'play'" class="subActions"
                      [class.subActionsStart]="task.action === 'play'"
                      [class.subActionsCompleted]="task.action === 'stop'"
                      [class.subActionsDisable]="actionStatus(task)" (click)="callGaurdAction(index, task);"
                      title="{{ 'End' | translate}}">stop_circle</mat-icon>
                    <mat-icon *ngIf="task.action === 'stop'" class="subActions"
                      [class.subActionsStart]="task.action === 'play'"
                      [class.subActionsCompleted]="task.action === 'stop'"
                      [class.subActionsDisable]="actionStatus(task)" (click)="callGaurdAction(index, task);"
                      title="{{'Completed - Start Again' | translate}}">task_alt</mat-icon>
                  </div>
                  <div fxFlex fxFlex="20" style="text-align:left" [class.subActionsStart]="task.action === 'play'"
                    [class.subActionsCompleted]="task.action === 'stop'">
                    <span *ngIf="task.name && tasksName.hasOwnProperty(task.name)">{{tasksName[task.name].displayName |
                      translate}}</span>
                  </div>
                  <div fxFlex="60" style="text-align:left">
                    <mat-form-field *ngIf="task.name === 'start-lead-camera'" style="width: 100%;">
                      <mat-select (selectionChange)="setCamerasAttribute(index,$event.value)"
                        [(ngModel)]="task.metadata.id" [disabled]="task.action === 'play'">
                        <mat-option value="" disabled>{{'Select' | translate}} {{'Lead' | translate}} / {{'Guest' |
                          translate}}</mat-option>
                        <mat-option *ngFor="let itm of leadList" [value]="itm._id" title="{{itm.name}}">{{itm.name |
                          truncate : ['20','...']}}</mat-option>
                        <mat-option *ngFor="let itm of guestsList" [value]="itm._id" title="{{itm.name}}">{{itm.name |
                          truncate : ['20','...']}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="task.name === 'start-guest-camera'" style="width: 70%;">
                      <mat-select (selectionChange)="setCamerasAttribute(index, $event.value)"
                        [(ngModel)]="task.metadata.id" [disabled]="task.action === 'play'">
                        <mat-option value="" disabled>{{'Select' | translate}} {{'Lead' | translate}} / {{'Guest' |
                          translate}}</mat-option>
                        <mat-option *ngFor="let itm of leadList" [value]="itm._id" title="{{itm.name}}">{{itm.name |
                          truncate : ['20','...']}}</mat-option>
                        <mat-option *ngFor="let itm of guestsList" [value]="itm._id" title="{{itm.name}}">{{itm.name |
                          truncate : ['20','...']}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="task.name === 'start-guest-camera'" style="width: 28%;padding-left: 2%;">
                      <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                        [disabled]="task.action === 'play'" title="{{'Select' | translate}} {{'Camera' | translate}}">
                        <mat-option value="" disabled>{{'Camera' | translate}}</mat-option>
                        <mat-option *ngFor="let itm of guestCamerasList;let idx = index" [value]="itm['uid']">
                          Cam-{{idx+1}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="task.name === 'push-tile'" style="width: 100%;">
                      <mat-select (selectionChange)="setMetaDataAttribute(index, $event.value,tileList)"
                        [(ngModel)]="task.metadata.id" [disabled]="task.action === 'play'">
                        <mat-option value="" disabled>{{'Select' | translate}} {{'Tile' | translate}}</mat-option>
                        <mat-option *ngFor="let itm of tileList" [value]="itm._id" title="{{itm.name}}">{{itm.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="task.name === 'select-session'" style="width: 100%;">
                      <mat-select (selectionChange)="setMetaDataAttribute(index, $event.value, sessionTaskList)"
                        [(ngModel)]="task.metadata.id" [disabled]="task.action === 'play'">
                        <mat-option value="" disabled>{{'Select' | translate}} {{'session' | translate}}</mat-option>
                        <mat-option *ngFor="let itm of sessionTaskList" [value]="itm._id" [disabled]="itm.isDisabled"
                          title="{{itm.name}}">{{itm.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-container
                      *ngIf="task.name !== 'start-lead-camera' && task.name !== 'start-guest-camera' && task.name !== 'push-tile' && task.name !== 'select-session' && task.name !== 'rtmp-pull' && task.name !== 'generate-rtmp' && task.name !== 'update-editor' && task.name !== 'update-picture' && task.name !== 'update-iframe' && task.name !== 'update-defaultvideo'">
                      <input style="width: 100%;" [(ngModel)]="task.note" placeholder="{{'Note' | translate}}"
                        [disabled]="task.action === 'play'" maxlength="1000">
                      <!-- <mat-form-field style="width: 100%;margin-top: 12px;">
  										    <input  [(ngModel)]="task.note" placeholder="{{'Note' | translate}}" >
  											</mat-form-field> -->
                    </ng-container>
                    <ng-container
                      *ngIf="task.name === 'update-editor' || task.name === 'update-iframe' || task.name === 'update-picture' || task.name === 'update-defaultvideo' || task.name === 'generate-rtmp' || task.name === 'rtmp-pull'">
                      <ng-container *ngIf="task.name !== 'rtmp-pull'">
                        <input style="width: 60%;" [(ngModel)]="task.note" placeholder="{{'Note' | translate}}"
                          [disabled]="task.action === 'play'" maxlength="1000">
                      </ng-container>
                      <ng-container *ngIf="task.name === 'rtmp-pull'">
                        <input style="width: 60%;" [(ngModel)]="task.rtmpUrl" placeholder="rtmp://.../.../..."
                          [disabled]="!(!task.channelId || task.channelId === '')">
                        <!-- [disabled]="task.action === 'play' || (task.channelId !== '' && sessionRTMPLinks[task.channelId] === 'na')" -->
                      </ng-container>
                      <!-- <mat-form-field style="width: 27%;padding-left: 2%;" *ngIf="task.name === 'update-panel'">
                        <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                          [disabled]="task.action === 'play'" title="{{'Select' | translate}} {{'Panel' | translate}}">
                          <mat-option value="" disabled>{{'Panel' | translate}}</mat-option>
                          <mat-option *ngFor="let itm of panelList;let idx = index" [value]="itm['uid']">Pnl-{{idx+1}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field> -->
                      <mat-form-field style="width: 27%;padding-left: 2%;" *ngIf="task.name === 'update-editor'">
                        <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                          [disabled]="task.action === 'play'" title="{{'Select' | translate}} {{'Editor' | translate}}">
                          <mat-option value="" disabled>{{'Editor' | translate}}</mat-option>
                          <mat-option *ngFor="let itm of editorsList;let idx = index" [value]="itm['uid']">Ed-{{idx+1}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 27%;padding-left: 2%;" *ngIf="task.name === 'update-iframe'">
                        <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                          [disabled]="task.action === 'play'" title="{{'Select' | translate}} {{'Iframe' | translate}}">
                          <mat-option value="" disabled>{{'Iframe' | translate}}</mat-option>
                          <mat-option *ngFor="let itm of iframeList;let idx = index" [value]="itm['uid']">iFr-{{idx+1}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 27%;padding-left: 2%;" *ngIf="task.name === 'update-picture'">
                        <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                          [disabled]="task.action === 'play'"
                          title="{{'Select' | translate}} {{'Picture' | translate}}">
                          <mat-option value="" disabled>{{'Picture' | translate}}</mat-option>
                          <mat-option *ngFor="let itm of picturesList;let idx = index" [value]="itm['uid']"
                            [disabled]="itm['fixed']">Pic-{{idx+1}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 27%;padding-left: 2%;"
                        *ngIf="task.name === 'update-defaultvideo' || task.name === 'generate-rtmp' || task.name === 'rtmp-pull'">
                        <mat-select (selectionChange)="setAttribute(index, 'uid', $event.value)" [(ngModel)]="task.uid"
                          [disabled]="task.action === 'play'" title="{{'Select' | translate}} {{'Media' | translate}}">
                          <mat-option value="" disabled>{{'Media' | translate}}</mat-option>
                          <mat-option *ngFor="let itm of defaultvideoList;let idx = index" [value]="itm['uid']"
                            [disabled]="itm['fixed']">Med-{{idx+1}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <div fxFlex="15" style="text-align:right">
                    <ng-container
                      *ngIf="(task.name === 'start-lead-camera' || task.name === 'start-guest-camera') && (task.overlay && task.overlay.trigger === 'manual' && task.overlay.content !== '')">
                      <mat-icon *ngIf="!task.overlay.running" class="subActionsOverlay editSubActions"
                        [class.subActionsOverlayDisable]="task.action !== 'play'"
                        (click)="runOverlay(index, task, true);" title="{{'Show Overlay' | translate}}">layers_clear
                      </mat-icon>
                      <mat-icon *ngIf="task.overlay.running" class="subActionsOverlay editSubActions"
                        [class.subActionsOverlayDisable]="task.action !== 'play'"
                        (click)="runOverlay(index, task, false);" title="{{'Hide Overlay' | translate}}">layers
                      </mat-icon>
                    </ng-container>
                    <mat-icon *ngIf="task.name === 'start-lead-camera' || task.name === 'start-guest-camera'"
                      class="subActions editSubActions"
                      [class.subActionsContains]="(task.hasOwnProperty('overlay') && task.overlay.content && task.overlay.content !== '')"
                      (click)="openEdit(index, task);" title="{{'Edit Task' | translate}}">edit</mat-icon>
                    <mat-icon
                      *ngIf="task.name === 'make-announcement' || task.name === 'share-video' || task.name === 'update-editor' || task.name === 'update-defaultvideo' || task.name === 'update-iframe'"
                      class="subActions editSubActions"
                      [class.subActionsContains]="(task.hasOwnProperty('data') && task.data && task.data !== '') || (task.hasOwnProperty('metadata') && task.metadata.id && task.metadata.id !== '') || (task.hasOwnProperty('metadata') && task.metadata.link && task.metadata.link !== '') || (task.hasOwnProperty('url') && task.url && task.url !== '')"
                      [class.subActionsDisable]="task.action === 'play'" (click)="openEdit(index, task);"
                      title="{{'Edit Task' | translate}}">edit</mat-icon>
                    <button class="imgPreview" title="Preview"
                      *ngIf="task.name === 'update-picture' && task.hasOwnProperty('image') && task.image && task.image !== ''"
                      mat-icon-button [matMenuTriggerFor]="imgPreview">
                      <mat-icon class="subActionsContains">remove_red_eye</mat-icon>
                    </button>
                    <mat-menu #imgPreview="matMenu">
                      <img *ngIf="task.hasOwnProperty('image') && task.image && task.image !== ''"
                        [src]="task.thumbImage" (error)="task['thumbImage'] = task.image" style="max-width: 260px" />
                    </mat-menu>
                    <mat-icon *ngIf="task.name === 'update-picture'" class="subActions editSubActions"
                      [class.subActionsContains]="task.hasOwnProperty('image') && task.image && task.image !== ''"
                      [class.subActionsDisable]="task.action === 'play'" (click)="openGallery(index, task);"
                      title="Select {{'image' | translate}}">image</mat-icon>
                    <mat-icon *ngIf="task.name === 'update-panel'" class="subActions editSubActions"
                      [class.subActionsContains]="task.hasOwnProperty('metadata') && task.metadata.length > 0"
                      (click)="openEdit(index, task);" title="{{'Edit Task' | translate}}">edit</mat-icon>
                    <ng-container *ngIf="task.name === 'generate-rtmp' && sessionRTMP">
                      <!-- <mat-icon
                        *ngIf="sessionRTMP.channelId && sessionRTMP.channelId !== '' && sessionRTMPLinks[sessionRTMP.channelId] === 'running'"
                        class="subActions editSubActions"
                        [class.subActionsContains]="sessionRTMP.channelId !== '' && sessionRTMPLinks && sessionRTMPLinks[sessionRTMP.channelId] !== 'na'"
                        [class.subActionsDisable]="task.action === 'play'" (click)="showRTMPGenerated()"
                        title="{{'Channel Information' | translate}}">info</mat-icon>
                      <mat-progress-spinner
                        *ngIf="sessionRTMP.channelId && sessionRTMP.channelId !== '' && sessionRTMPLinks[sessionRTMP.channelId] !== 'running'"
                        class="preparing-section" [diameter]="22" color="primary" mode="indeterminate"
                        (click)="analyseRTMPLinksData();" title="{{'Preparing Channel, Click to refresh' | translate}}">
                      </mat-progress-spinner> -->
                      <!-- <mat-icon *ngIf="sessionRTMP.channelId && sessionRTMP.channelId !== '' && sessionRTMPLinks[sessionRTMP.channelId] !== 'running'"
                        class="subActions editSubActions" (click)="analyseRTMPLinksData();"
                        title="{{'Preparing Channel, Click to refresh' | translate}}">cached</mat-icon> -->
                      <!-- <mat-icon
                        *ngIf="sessionStarted && (!sessionRTMP.channelId || sessionRTMP.channelId === '' || (sessionRTMP.channelId !== '' && sessionRTMPLinks[sessionRTMP.channelId] === 'na'))"
                        class="subActions editSubActions" (click)="generateRTMPSettings(undefined);"
                        title="{{'Generate RTMP Link' | translate}}">video_settings</mat-icon> -->
                      <!-- <mat-icon *ngIf="sessionRTMP.channelId && sessionRTMP.channelId !== '' && sessionRTMPLinks.hasOwnProperty(sessionRTMP.channelId) && sessionRTMPLinks[sessionRTMP.channelId] === 'idle'"
                        class="subActions editSubActions subActionsStopped"
                        (click)="generateRTMPSettings('start');" title="{{'Start RTMP Link' | translate}}">video_settings</mat-icon>
                      <mat-icon *ngIf="sessionRTMP.channelId && sessionRTMP.channelId !== '' && sessionRTMPLinks.hasOwnProperty(sessionRTMP.channelId) && sessionRTMPLinks[sessionRTMP.channelId] === 'running'"
                        class="subActions editSubActions subActionsContains"
                        (click)="generateRTMPSettings('stop');" title="{{'Stop RTMP Link' | translate}}">cancel_presentation</mat-icon> -->
                    </ng-container>
                    <!-- <ng-container
                      *ngIf="task.name === 'rtmp-pull' && task.rtmpUrl && task.rtmpUrl !== '' && task.action !== 'play'">
                      <mat-icon
                        *ngIf="!task.channelId || task.channelId === '' || (task.channelId !== '' && sessionRTMPLinks[task.channelId] === 'na')"
                        class="subActions editSubActions" (click)="generateRTMPPullSettings(undefined, index, task);"
                        title="{{'RTMP Pull' | translate}}">video_settings</mat-icon>
                      <mat-icon
                        *ngIf="task.channelId !== '' && sessionRTMPLinks.hasOwnProperty(task.channelId) && (sessionRTMPLinks[task.channelId] === 'idle')"
                        class="subActions editSubActions subActionsStopped"
                        (click)="generateRTMPPullSettings('start', index, task);"
                        title="{{'Start RTMP Stream' | translate}}">video_settings</mat-icon>
                      <mat-icon
                        *ngIf="task.channelId !== '' && sessionRTMPLinks.hasOwnProperty(task.channelId) && (sessionRTMPLinks[task.channelId] === 'running')"
                        class="subActions editSubActions subActionsContains"
                        (click)="generateRTMPPullSettings('stop', index, task);"
                        title="{{'Stop RTMP Stream' | translate}}">video_settings</mat-icon>
                      <mat-progress-spinner
                        *ngIf="task.channelId !== '' && sessionRTMPLinks.hasOwnProperty(task.channelId) && (sessionRTMPLinks[task.channelId] === 'starting')"
                        class="preparing-section" [diameter]="22" color="primary" mode="indeterminate"
                        (click)="analyseRTMPLinksData();" title="{{'Preparing Channel, Click to refresh' | translate}}">
                      </mat-progress-spinner>
                      <mat-progress-spinner
                        *ngIf="task.channelId !== '' && sessionRTMPLinks.hasOwnProperty(task.channelId) && (sessionRTMPLinks[task.channelId] === 'stopping')"
                        class="preparing-section" [diameter]="22" color="primary" mode="indeterminate"
                        (click)="analyseRTMPLinksData();" title="{{'Stopping Channel, Click to refresh' | translate}}">
                      </mat-progress-spinner>
                    </ng-container> -->
                    <mat-icon class="subActions deleteSubActions" (click)="removeTask(index, task);"
                      title="{{'Delete Task' | translate}}">delete</mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
<!-- </div> -->