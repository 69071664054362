<div class="mb-20" fxLayout="row" fxFlex fxLayoutGap="20px">
  <div fxFlex>
    <div id="cont" fxLayout="column" fxLayoutAlign="space-between center" (keydown.arrowleft)="previousButtonClick()"
      (keydown.arrowright)="nextButtonClick()" (swipeleft)="nextButtonClick()"
      (swiperight)="previousButtonClick()" style="height: 100%; ">

      <div class="header-date" fxLayout="row" fxLayoutAlign="space-between center" style="min-height: 60px;">

        <!-- <button mat-icon-button class="buttons" (click)="previousButtonClick()">
          <mat-icon>
            arrow_back_ios
          </mat-icon>
        </button> -->
        <mat-button-toggle-group [(ngModel)]="viewMode" aria-label="Font Style">
          <mat-button-toggle value="{{itm}}" *ngFor="let itm of viewModesArray">{{viewModes[itm]}}</mat-button-toggle>
        </mat-button-toggle-group>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-icon-button class="buttons" (click)="previousButtonClick()">
            <mat-icon>
              arrow_back_ios
            </mat-icon>
          </button>
          <span *ngIf="viewMode === 'month'">
            {{ actDate }}
          </span>
          <span *ngIf="viewMode === 'week' && currentWeek !== undefined">
            {{ actDate }} Week {{currentWeek+1}}
          </span>
          <span *ngIf="viewMode === 'day' && today !== undefined">
            <!-- {{today | date: 'yyyy. MMMM dd - EEEE'}} -->
            {{actFullDate}}
          </span>
          <button *ngIf="viewMode === 'month'" mat-icon-button (click)="showChangeDate = !showChangeDate">
            <mat-icon *ngIf="!showChangeDate">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="showChangeDate">keyboard_arrow_up</mat-icon>

          </button>
          <button mat-icon-button class="buttons" (click)="nextButtonClick()">
            <mat-icon>
              arrow_forward_ios
            </mat-icon>
          </button>
          <!-- <button mat-icon-button (click)="showChangeDate = !showChangeDate">
            <mat-icon *ngIf="!showChangeDate">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="showChangeDate">keyboard_arrow_up</mat-icon>

          </button> -->
          
        </div>
        <div>
          <button mat-button class="clearButtons" (click)="goToToday()" title="Today">Today</button>
          <button mat-icon-button *ngIf="canAddEvent && eventsActions.length == 0" (click)="createEvent('occurrence')">
            <mat-icon>add</mat-icon>
          </button>
          <button *ngIf="canAddEvent && eventsActions.length > 0" mat-icon-button [matMenuTriggerFor]="addEvent" >
            <mat-icon>add</mat-icon>
          </button>
            <mat-menu #addEvent="matMenu">
              <div class="header" style="text-align: center;font-weight: bold;">
                  Select Event Type
              </div>
              <button *ngFor="let itm of eventsActions" mat-menu-item (click)="createEvent(itm.value)">{{itm.displayName}}</button>
              <!-- <button mat-menu-item (click)="createOccurrence()">One Event Occurrence</button>
              <button mat-menu-item (click)="createBlueprint()">Event Blueprint</button>
              <button mat-menu-item (click)="createEvent()">Select from Blueprints</button> -->
            </mat-menu>
        </div>
        <!-- <mat-button-toggle-group aria-label="Font Style">
          <mat-button-toggle (click)="goToToday()">Today</mat-button-toggle>
          <mat-button-toggle (click)="addEventClicked()"><mat-icon>add</mat-icon></mat-button-toggle>
        </mat-button-toggle-group> -->
        <!-- <button *ngIf="btnAddShow" mat-icon-button (click)="addEventClicked()">
          <mat-icon>add</mat-icon>
        </button> -->
        


      </div>

      <div class="header-select-date" fxLayout="row" fxLayoutAlign="center center" style="height: auto;"
        *ngIf="showChangeDate && viewMode === 'month'">
        <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="space-between center" [@enterAnimation]>

            <mat-form-field style="margin: 5px;">
                <mat-select (selectionChange)="onYearChange($event)" [(ngModel)]="currentYear">
                  <mat-option *ngFor="let y of years" [value]="y" >{{ y }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

          <mat-form-field style="margin: 5px;">
              <mat-select (selectionChange)="onMonthChange($event)" [(ngModel)]="currentMonth">
                <mat-option *ngFor="let m of months" [value]="m.id">{{ m.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

        </div>
      </div>



      <div class="calendar-container" fxLayout="column" fxFlex>
        <!-- Weekdays header -->
        <div fxLayout="row" *ngIf="['month','week'].includes(viewMode)" fxLayoutAlign="space-between center" class="weekdays-container" style="height: 30px;">
          <div *ngIf="viewMode === 'week'" fxFlex="30px" class="weekdaysTitle" ></div>
          <div class="weekdaysTitle" *ngFor="let day of weekdays" fxLayout="row" fxLayoutAlign="center center">
            <span *ngIf="!isSmall">
              {{day.name}}
            </span>
            <span *ngIf="isSmall">
              {{day.shortName}}
            </span>
          </div>
        </div>

        <!-- Days body -->
        <!-- Months -->
        <div *ngIf="viewMode === 'month'" fxFlex fxLayout="column" class="cell-container-main" [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 240px)'}">
          <div fxFlex class="cell-container" fxLayout="row" *ngFor="let item of arrCalendar">
            <div fxFlex class="cell-item" fxLayoutAlign="space-between stretch" (click)="selectCellItem(cellItem)"
              [ngStyle]="{
              backgroundColor:
              cellItem.day == actDay &&
                cellItem.month == actMonth - 1 &&
                cellItem.year == actYear
                  ? '#7b80a536'
                  : 'white'}" *ngFor="let cellItem of item" style="height: 100%; width: 100%;">
              <div fxFlex fxLayout="column" style="height: 100%; width: 100%; ">
                <div>
                  <b class="calendar-days" [ngStyle]="{
                              color: cellItem.month == currentMonth ? 'black' : 'lightgray'
                            }">{{ cellItem.day }}
                  </b>
                  <span *ngIf="cellItem.events && cellItem.events.length > 0 && isSmall" class="dot"></span>
                </div>
                <div fxFlex *ngIf="!isSmall" style="height: 100%; overflow-x: hidden;">
                  <div class="row-items" *ngFor="let event of cellItem.events; let i = index"
                    [ngStyle]="{ backgroundColor: event.color? event.color : '#242852' }" (click)="selectEventItem($event, cellItem, event)" customToolTip [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="event">
                    <mat-icon class="eventIcon" *ngIf="event.isRecurrence" title="{{'Recurrence Available'}}">
                      cached
                    </mat-icon>
                    <span style="font-weight: bold;">
                      {{event.name}} 
                    </span>
                    <span *ngIf="cellItem.day == (event.startdate | date: 'dd') && event.description !== ''"> - {{event.description}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Weeks -->
        <div *ngIf="viewMode === 'week'" fxFlex fxLayout="column" class="cell-container-main" [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 240px)'}">
          <div fxFlex class="cell-container" fxLayout="row">
            <div fxFlex="31px" fxLayout="column" class="weekdays" >
              <ng-container *ngFor="let hr of hours">
                <div class="hours">
                  {{hr}}
                </div>
                <div class="half-hours">
                  
                </div>
              </ng-container>
            </div>
            <div fxFlex class="cell-day-item" fxLayoutAlign="space-between stretch" 
              [ngStyle]="{
              backgroundColor:
              cellItem.day == actDay &&
                cellItem.month == actMonth - 1 &&
                cellItem.year == actYear
                  ? '#7b80a536'
                  : 'white'}" *ngFor="let cellItem of currentWeekData" style="position: relative;height: 100%; width: 100%;">
              <div fxFlex fxLayout="column" class="main-events">
                <div fxFlex *ngIf="!isSmall" fxLayout="row" style="height: 100%; overflow-x: hidden;">

                  <div class="main-days-bg">
                    <div fxLayout="column" class="day-bg" >
                      <ng-container *ngFor="let hr of hours">
                        <div class="hours" (click)="selectCellItemHours(cellItem, hr, false)">
                          
                        </div>
                        <div class="half-hours" (click)="selectCellItemHours(cellItem, hr, true)">
                          
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row-items row-week-items" *ngFor="let event of cellItem.events; let i = index"
                    [ngStyle]="{ backgroundColor: event.color? event.color : '#242852', marginTop: event.topMargin, height: event.height }" customToolTip [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="event" (click)="selectEventItem($event, cellItem, event)">
                    <mat-icon class="eventIcon" *ngIf="event.isRecurrence" title="{{'Recurrence Available'}}">
                      cached
                    </mat-icon>
                    <span style="font-weight: bold;">
                      {{event.name}}
                    </span>
                    <span *ngIf="cellItem.day == (event.startdate | date: 'dd') && event.description !== ''"> - {{event.description}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Day -->
        <div *ngIf="viewMode === 'day'" fxFlex fxLayout="column" class="cell-container-day-main" [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 200px)'}">
          <div fxFlex class="cell-container" fxLayout="row">
            <div fxFlex="31px" fxLayout="column" class="weekdays" >
              <ng-container *ngFor="let hr of hours">
                <div class="hours">
                  {{hr}}
                </div>
                <div class="half-hours">
                  
                </div>
              </ng-container>
            </div>
            <div fxFlex class="cell-selected-day-item" fxLayoutAlign="space-between stretch" 
              [ngStyle]="{
              backgroundColor:
              currentDay.day == actDay &&
              currentDay.month == actMonth - 1 &&
              currentDay.year == actYear
                  ? '#7b80a536'
                  : 'white'}" style="height: 100%; width: 100%;">
              <div fxFlex fxLayout="column" class="main-events">
                <div fxFlex *ngIf="!isSmall" fxLayout="row" style="height: 100%; overflow-x: hidden;">

                  <div class="main-day-bg">
                    <div fxLayout="column" class="day-bg" >
                      <ng-container *ngFor="let hr of hours">
                        <div class="hours" (click)="selectCellItemHours(currentDay, hr, false)">
                          
                        </div>
                        <div class="half-hours" (click)="selectCellItemHours(currentDay, hr, true)">
                          
                        </div>
                      </ng-container>
                    </div>
                  </div>  
                  <div class="row-items row-week-items" *ngFor="let event of currentDay.events; let i = index"
                    [ngStyle]="{ backgroundColor: event.color? event.color : '#242852', marginTop: event.topMargin, height: event.height }" customToolTip [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="event" (click)="selectEventItem($event, currentDay, event)">
                    <mat-icon class="eventIcon" *ngIf="event.isRecurrence" title="{{'Recurrence Available'}}">
                      cached
                    </mat-icon>
                    <span style="font-weight: bold;">
                      {{event.name}}
                    </span>
                    <span *ngIf="currentDay.day == (event.startdate | date: 'dd') && event.description !== ''"> - {{event.description}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #eventTemplate let-contentTemplateData="contentTemplateData">
  <div class="eventOverlay" *ngIf="contentTemplateData">
    <div class="eventOverlayTitle" *ngIf="contentTemplateData.name">
      <div >{{contentTemplateData.name}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.description">
      <div>Description: </div>
      <div>{{contentTemplateData.description}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate">
      <div>Start: </div>
      <div>{{contentTemplateData.startdate | date: 'dd-MM-yyyy hh:mm aa'}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.enddate">
      <div>End: </div>
      <div>{{contentTemplateData.enddate | date: 'dd-MM-yyyy hh:mm aa'}}</div>
    </div>
  </div>
</ng-template>
<!--[ngClass]="(event.startdate | date: 'dd') == (event.enddate | date: 'dd') ? 'row-items' 
                : ((event.startdate | date: 'dd') == test.day ? 'row-items-multi-first' 
                : ((event.enddate | date: 'dd') == test.day ? 'row-items-multi-last' : 'row-items-multi') )" 
              
                Multi day event:
              <span *ngIf="test.day == (event.startdate | date: 'dd')" >{{event.name}}</span> &nbsp;
                <span *ngIf="test.day == (event.startdate | date: 'dd')"> - {{event.description}}</span> </div>
              -->