<!-- <app-session-entry (enterSession)="join()" [sessionName]="roomName" *ngIf="!joined && roomName">
</app-session-entry> -->

<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject">
  <div fxFlex cdkDropListGroup>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <div class="overlayLayout example-boundary" #mainBoundary (window:resize)="onResize($event)">
          <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
            <div fxFlex *ngFor="let col of settingObject['columns'];let index = index" class="example-list">
              <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                <div fxFlex id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row">
                  <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag
                    cdkDragBoundary=".example-boundary"
                    [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize, cmp.name), 'width' : cmp.w+'vw', 'backgroundColor': cmp.bgcolor, 'transform': 'translate3d('+ getX(cmp.x, mainBoundary, subIndex) + 'px, ' + getY(cmp.y, mainBoundary, subIndex) + 'px, 0px)'}"
                    [class.cameraComponent]="cameraComponents.includes(cmp.name)"
                    [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" [cdkDragDisabled]="true">
                    <div class="example-box-handle"
                      [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" *ngIf="cmp.active">
                      <div class="contentRealData"
                        [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'">
                        <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                          <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)"
                            [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                            [forEdit]="false"></app-iframe-url>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                          <app-slider [forEdit]="false" [bannerId]="cmp.metadata.id" [sessionData]="sessionData"
                            [roomId]="roomId" [track]="true">
                          </app-slider>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'picture'">
                          <img *ngIf="cmp.image" src="{{cmp.image}}" style="cursor: pointer;width: 100%;height: 100%;"
                            (click)="selectPicture($event, cmp, cmp.metadata.type, cmp.metadata.link)"
                            title="{{cmp.title}}">
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'empty-click'">
                          <div class="empty-click empty-click-hover" *ngIf="cmp.shape !== 'poly'"
                            [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                            (click)="selectPicture($event, cmp, cmp.metadata.type, cmp.metadata.link)"
                            title="{{cmp.title}}">
                          </div>
                          <div style="height:100%;width:100%">
                            <svg height="100%" width="100%" *ngIf="cmp.hasOwnProperty('svg') && cmp.shape === 'poly'">
                              <polygon [attr.points]="getShapePoint(cmp.svg, mainBoundary, index, subIndex)"
                                style="stroke-width:1;opacity: 0; cursor: pointer;"
                                (click)="selectPicture($event, cmp, cmp.metadata.type, cmp.metadata.link)"
                                [attr.title]="cmp.title" class="empty-click-poly empty-click-poly-hover">
                                <title>{{cmp.title}}</title>
                              </polygon>
                            </svg>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'editor'">
                          <div [innerHTML]="cmp.data | safeHtml"></div>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'imagegallery' && cmp.metadata.id">
                          <div [id]="cmp['uid']" style="height: 100%;">
                            <app-image-gallery [galleryId]="cmp.metadata.id" [sessionData]="sessionData">
                            </app-image-gallery>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'videolibrary' && cmp.metadata.id">
                          <div [id]="cmp['uid']" style="height: 100%;">
                            <app-media-library [id]="cmp.metadata.id" [width]="cmp.w" [libraryId]="cmp.uid"
                              [loop]="cmp.metadata.loop" [volume]="cmp.metadata.volume"
                              [autoplay]="cmp.metadata.autoPlay" [menuState]="cmp.metadata.expanded ? 'in': 'out'">
                            </app-media-library>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'audio' && cmp.metadata.src">
                          <div [id]="'audio-' + cmp.uid">
                            <app-audio [isAutoPlay]="cmp.metadata.autoPlay" [isOnLoop]="cmp.metadata.loop"
                              [width]="cmp.w" [height]="cmp.h" [id]="cmp.uid" [volume]="cmp.metadata.volume"
                              [src]="cmp.metadata.src">
                            </app-audio>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="cmp['name'] === 'defaultvideo'">
                          <div [id]="'video-container-' + cmp.uid" style="height:100%;">
                            <app-youtube-media *ngIf="cmp.type === 'youtube'" [uuid]="cmp.uid" [zIndex]="cmp.z">
                            </app-youtube-media>
                            <app-vimeo-media *ngIf="cmp.type === 'vimeo'" [uuid]="cmp.uid" [isHost]="false"
                              [zIndex]="cmp.z" [isProducer]="false"
                              (pauseDialog)="showPauseDialog($event.uid, $event.isHost)">
                            </app-vimeo-media>
                            <app-hls-media *ngIf="cmp.type === 'hls'" [uuid]="cmp.uid" [zIndex]="cmp.z"
                              [showError]="true" (pauseDialog)="showPauseDialog($event.uid, $event.isHost)">
                            </app-hls-media>
                            <app-facebook-media *ngIf="cmp.type === 'facebook'" [uuid]="cmp.uid" [zIndex]="cmp.z">
                            </app-facebook-media>
                          </div>
                        </ng-container>
                        <div [id]="cmp['uid']" style="height: 100%;"
                          *ngIf="cmp['name'] === 'quuNowPlaying' && cmp.metadata.stationId">
                          <app-quu-now-playing [stationId]="cmp.metadata.stationId">
                          </app-quu-now-playing>
                        </div>
                        <div [id]="cmp['uid']" style="height: 100%;"
                          *ngIf="cmp['name'] === 'quuSongs' && cmp.metadata.stationId">
                          <app-quu-playlist [stationId]="cmp.metadata.stationId" [numberOfSongs]="cmp.metadata.songsNo"
                            [uuid]="cmp.uid">
                          </app-quu-playlist>
                        </div>
                        <ng-container *ngIf="cmp['name'] === 'iframe'">
                          <app-iframe-url [iframeLink]="cmp.metadata.link"
                            [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)"
                            [forEdit]="false" [iframePadding]="cmp.frame">
                          </app-iframe-url>
                        </ng-container>
                        <!-- <ng-container *ngIf="cmp['name'] === 'picturewall' ">
                              <app-picture-wall [gridSizeX]="cmp['gridSizeX']"
                                [gridSizeY]="cmp['gridSizeY']" [timer]="cmp['timer']"
                                [moderated]="cmp['moderated']"
                                [spacing]="cmp['spacing']"
                                [serviceId]="sessionData._id"></app-picture-wall>
                            </ng-container> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>