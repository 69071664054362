import { Component, OnInit, Input, Output, EventEmitter, HostListener, TemplateRef } from '@angular/core';
import { EventData } from './interface/event-data';
import { yearsLabel } from './locale/years';
import { formatDate } from '@angular/common';
import { mounthsHU } from './locale/monthsHU';
import { weekdayHU } from './locale/weekdaysHU';
import { mounthsEN } from './locale/monthsEN';
import { weekdayEN } from './locale/weekdaysEN';
import { hoursLabel } from './locale/hours';
import { Day } from './interface/day';
import { trigger, transition, style, animate } from '@angular/animations';
import * as moment from 'moment';
import {utc} from 'moment';

declare var rrule: any;

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    animations: [
      trigger(
        'enterAnimation', [
          transition(':enter', [
            style({transform: 'translateX(100%)', opacity: 0}),
            animate('250ms', style({transform: 'translateX(0)', opacity: 1}))
          ]),
          transition(':leave', [
            style({transform: 'translateX(0)', opacity: 1}),
            animate('250ms', style({transform: 'translateX(100%)', opacity: 0}))
          ])
        ]
      )
    
    ]
})
export class CalendarComponent implements OnInit {
  
  isSmall = false;

  today = new Date();;
  currentMonth;
  currentYear;
  currentWeek;
  currentDay: Day;
  currentWeekData: Day[];
  firstDay;
  daysInMonth;
  daysInLastMonth;
  actDay;
  lastMonth;
  actMonth;
  hours: any[] = hoursLabel;
  months: any;
  weekdays: any;
  years = [];
  actFullDate;
  actDate: any;
  arrTest = [];
  arrCalendar = [];
  eventsData: any = [];
  eventsSkelletonData: any = [];
  actYear;
  showChangeDate = false;
  btnAddShow:boolean;
  private viewModes: any = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
  }
  utc = utc;
  @Input() anchorDate = new Date();
  @Input() viewMode = 'month';
  @Input() viewModesArray: any[] = Object.keys(this.viewModes);
  @Input() canAddEvent: boolean = false;
  @Input() eventsActions: any[] = []; // {value: 'occurrence', displayName: 'Event Occurrence'}
  @Input() dataSource: any = {events: <EventData[]>[], skelleton: <EventData[]>[]};
  @Input() showAddButton: boolean;
  @Input() language: string = 'en';
  @Input() bodyHeight: string = undefined;
  @Input() contentTemplate: TemplateRef<any>;
  @Output() dayEvents = new EventEmitter();
  @Output() dayEvent = new EventEmitter();
  @Output() newEvent = new EventEmitter();
  @Output() dayShift = new EventEmitter();

  constructor() {
    this.currentMonth = this.anchorDate.getMonth();
    this.currentYear = this.anchorDate.getFullYear();
    this.currentDay = this.getDay(Number(formatDate(this.anchorDate, 'dd', 'en')), this.currentMonth, this.currentYear);

    this.dayShift.emit({currentDay: {month:this.currentMonth,year:this.currentYear, day: this.currentDay.day}, status: 'init'});
    this.years = yearsLabel;
  }

  ngOnInit() {
    this.actFullDate = formatDate(this.anchorDate, 'yyyy. MMMM dd - EEEE', 'en');
    this.actDate = formatDate(this.anchorDate, 'yyyy. MMMM', 'en');
    this.actDay = formatDate(this.anchorDate, 'dd', 'en');
    this.actMonth = formatDate(this.anchorDate, 'MM', 'en');
    this.actYear = formatDate(this.anchorDate, 'yyyy', 'en');
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.btnAddShow = this.showAddButton;
  }

  ngAfterViewInit(): void {
    const height = document.getElementById('cont').offsetHeight;
    const width = document.getElementById('cont').offsetWidth;

    // // TODO: if small only show badges not all the events
    // if (height <= 500 || width <= 769) {
    //   this.isSmall = true;
    // } else {
    //   this.isSmall = false;
    // }

  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   const height = document.getElementById('cont').offsetHeight;
  //   const width = document.getElementById('cont').offsetWidth;
  //   if (height <= 500 || width <= 600) {
  //     this.isSmall = true;
  //   } else {
  //     this.isSmall = false;
  //   }
  // }

  ngOnChanges() {
    // console.log('this.dataSource', this.dataSource);
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.changeLanguage();
    this.createCalendar();
  }
  buildSkelletonEvents(skelletonData) {
    let skelletonDataTemp = JSON.parse(JSON.stringify(skelletonData));
    for(let itm of skelletonData){
      if(itm.isRecurrence && itm.recurrence){
        try {
          let ruleTextAll = this.getRecurrenceDatas(itm.recurrence).all;
          let startdate = moment(itm["startdate"]).utc();
          let endEventdate = moment(itm["enddate"]).utc();
          let minutes = endEventdate.diff(startdate, 'minutes');
          if(ruleTextAll.length > 1){
            ruleTextAll.shift();
            for (let date of ruleTextAll) {
              let endEventdate = moment(date).utc();
              endEventdate.add(minutes, 'minutes');
              let eventObj = Object.assign({}, itm);
              eventObj["startdate"] = moment(date).utc().format('YYYY-MM-DDTHH:mm');
              eventObj["enddate"] = endEventdate.format('YYYY-MM-DDTHH:mm');
              skelletonDataTemp.push(eventObj);
            }
          }

        } catch (e) {
          console.log('setRuleText', e);
        }
      }
      
    }
    // console.log('skelletonDataTemp', skelletonDataTemp);
    return skelletonDataTemp;
  }
  getDay(day, month, year, event = []){
    return {
      day: day,
      month: month,
      year: year,
      events:event
    }
  }

  goToToday(){
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), this.currentMonth, this.currentYear);
    this.createCalendar();
  }

  createCalendar(getWeekByCurrentDate = true) {
    this.arrTest = [];
    this.arrCalendar = [];
    this.firstDay = new Date(this.currentYear, this.currentMonth).getUTCDay();
    this.daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
    this.daysInLastMonth = this.getDaysInMonth(
      this.currentMonth - 1,
      this.currentYear
    );
    const lmd = this.daysInLastMonth - (this.firstDay - 1);

    let eventsData = this.prepareViewEvent(this.eventsData);
    let eventsSkelletonData = this.prepareViewEvent(this.eventsSkelletonData);

    // Last month days
    for (let index = lmd; index <= this.daysInLastMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth - 1, this.currentYear);
    }
    // Actual month
    for (let index = 1; index <= this.daysInMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth, this.currentYear);
    }

    for (let i = this.arrTest.length, j = 1; i < 42; i++ , j++) {
      this.updateEventArray(eventsData, eventsSkelletonData, j, this.currentMonth + 1, this.currentYear);
    }

    for (let i = 0; i < 6; i++) {
      const arrWeek = this.arrTest.splice(0, 7);
      this.arrCalendar.push(arrWeek);
    }
    // console.log('Data 1', {currentDay:this.currentDay, currentMonth: this.currentMonth, currentYear:this.currentYear});
    if(getWeekByCurrentDate){
      this.currentWeek = this.getWeekIndex(this.currentDay);
    }
    this.currentWeekData = this.arrCalendar[this.currentWeek];
    this.actDate = this.creatActMonthYear();
    this.actFullDate = this.creatActFullDay();
    // console.log('Data 2', {arrCalendar:this.arrCalendar, currentWeek: this.currentWeek, daysInMonth:this.daysInMonth, daysInLastMonth: this.daysInLastMonth});
  }
  updateEventArray(dataList, skelletonDataList, index, month, year){
    const filterDataList = dataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <=
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >=
        new Date(year, month, index).getTime()
      );
    });
    const filterSkelletonDataList = skelletonDataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <=
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >=
        new Date(year, month, index).getTime()
      );
    });
    const filterDataListAll = filterDataList.concat(filterSkelletonDataList);
    //Sorted events by date
    const arrSortedDataByDate = filterDataListAll.sort((a:any, b:any) => {
      return a.startdate - b.startdate
    });

    this.arrTest.push({
      day: index,
      month: month,
      year: year,
      events: arrSortedDataByDate
    });
    if(index === this.currentDay.day && month === this.currentDay.month && year === this.currentDay.year){
      this.currentDay['events'] = arrSortedDataByDate;
    }
  }
  prepareViewEvent(eventsData:any[]){
    let eventNewData =  JSON.parse(JSON.stringify(eventsData));
    eventNewData = eventNewData.map(event => {
      event.startdate = moment.utc(event.startdate).local().format('YYYY-MM-DDTHH:mm');
      event.enddate = moment.utc(event.enddate).local().format('YYYY-MM-DDTHH:mm');
      let startHour = Number(formatDate(event.startdate, 'HH', 'en'));
      let startMinute = Number(formatDate(event.startdate, 'mm', 'en'));
      let topMargin = (startHour * 60) + (startHour * 2);
      if(startMinute >= 15){
        topMargin = topMargin + startMinute;
      }
      event['topMargin'] = (topMargin + 2) + 'px';
      let endHour = Number(formatDate(event.enddate, 'HH', 'en'));
      let height = (endHour * 60) + (endHour * 2) - 4;
      let endMinute = Number(formatDate(event.enddate, 'mm', 'en'));
      if(endMinute >= 15){
        height = height + endMinute;
      }
      height = height - topMargin;
      if(height == 0){
        height = 15;
      }
      event['height'] = height + 'px';
      return event;
    });
    return eventNewData;
  }
  getWeekData(inputDay: Day){
    for(let week of this.arrCalendar){
      for(let day of week){
        if(day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year){
          return week;
        }
      }
    }
    return [];
  }
  getWeekIndex(inputDay: Day){
    let index = 0;
    for(let week of this.arrCalendar){
      for(let day of week){
        if(day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year){
          return index;
        }
      }
      index = index + 1;
    }
    return index - 1;
  }

  getDaysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  previousButtonClick() {
    if(this.viewMode === 'month'){
      if (this.currentMonth === 0) {
        this.currentYear -= 1;
        this.currentMonth = 11;
      } else {
        this.currentMonth -= 1;
      }
      this.createCalendar();
    }else if (this.viewMode === 'week'){
      if (this.currentWeek === 0) {
        this.currentMonth -= 1;
        if(this.currentMonth <= -1){
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        this.currentWeek = 3;
      } else {
        this.currentWeek -= 1;
      }
      this.createCalendar(false);
    }else{
      if (this.currentDay.day === 1) {
        this.currentMonth -= 1;
        if(this.currentMonth <= -1){
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = this.daysInLastMonth;
      } else {
        this.currentDay.day -= 1;
      }
      this.createCalendar();
    }
    this.dayShift.emit({currentDay: {month:this.currentMonth,year:this.currentYear, day: this.currentDay.day}, status: 'change'});
  }
  nextButtonClick() {
    if(this.viewMode === 'month'){
      if (this.currentMonth >= 11) {
        this.currentYear += 1;
        this.currentMonth = 0;
      } else {
        this.currentMonth += 1;
      }
      this.createCalendar();
    }else if (this.viewMode === 'week'){
      if (this.currentWeek >= 3) {
        this.currentMonth += 1;
        if(this.currentMonth >= 12){
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        this.currentWeek = 0;
      } else {
        this.currentWeek += 1;
      }
      this.createCalendar(false);
    }else{
      if (this.currentDay.day >= this.daysInMonth) {
        this.currentMonth += 1;
        if(this.currentMonth >= 12){
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = 1;
      } else {
        this.currentDay.day += 1;
      }
      this.createCalendar();
    }
    this.dayShift.emit({currentDay: {month:this.currentMonth,year:this.currentYear, day: this.currentDay.day}, status: 'change'});
  }
  

  // Dialog test
  // TODO: return the selected value
  selectCellItem(day) {
    this.currentDay = day;
    this.dayEvents.emit(day);
  }
  selectCellItemHours(day, hr, half: boolean = false) {
    this.currentDay = day;
    if(half){
      hr = hr + ':30';
    }else{
      hr = hr + ':00';
    }
    let dayData = JSON.parse(JSON.stringify(day));
    dayData['hour'] = hr;
    this.dayEvents.emit(dayData);
  }
  selectEventItem(e, day, event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    let dayData = JSON.parse(JSON.stringify(day));
    dayData['event'] = event;
    this.dayEvent.emit(dayData);
  }

  changeLanguage() {
    if (!this.language) {
      this.language = 'en';
      this.months = mounthsEN;
      this.weekdays = weekdayEN;
    }
    if (this.language === 'hu') {
      this.months = mounthsHU;
      this.weekdays = weekdayHU;
    } else {
      this.months = mounthsEN;
      this.weekdays = weekdayEN;
    }
  }

  onYearChange(event) {
    this.currentYear = Number(event.value);
    this.createCalendar();
  }

  onMonthChange(event) {
    this.currentMonth = Number(event.value);
    this.createCalendar();
  }

  creatActMonthYear() {
    const actDate = formatDate(
      new Date(this.currentYear, this.currentMonth),
      'yyyy. MMMM',
      'en'
    );

    return actDate;
  }
  creatActFullDay() {
    const actDate = formatDate(
      this.getFullDay(),
      'yyyy. MMMM dd  - EEEE',
      'en'
    );

    return actDate;
  }
  getFullDay() {
    return new Date(this.currentYear, this.currentMonth, this.currentDay.day);
  }

  createEvent(action) {
    const testMessage = this.getFullDay();
    this.newEvent.emit({action: action, testMessage});
  }
  getRecurrenceDatas(recurObj) {
		let response = {humantext: '', all: undefined};  
		if(!recurObj){
			return response;
		}else{
			let pattern = "";
			let count = 0;
			switch (recurObj["repeats"]) {
				case "minutely":
					pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
					break;
				case "hourly":
					pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
					break;
				case "daily":
					pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
					break;
				case "weekly":
					pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
					break;
				case "monthly":
					pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
					if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
						pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						pattern += ";BYSETPOS=" + recurObj["bysetpos"] + ";BYDAY=" + recurObj["byday"].toString();
					}
					break;
				case "yearly":
					pattern = "FREQ=YEARLY;"
					if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
						pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						pattern += "BYDAY=" + recurObj["byday"] + ";BYSETPOS=" + recurObj["bysetpos"] + ";BYMONTH=" + recurObj["bymonth"].toString();
					}
					break;
				default:
					pattern = "";
			}
			if (recurObj["end"] && recurObj["end"] == "after") {
				pattern += ";COUNT=" + recurObj["count"];
				count = recurObj["count"];
			}
			if (recurObj["end"] && recurObj["end"] == "date") {
				pattern += ";UNTIL=" + moment(recurObj["until"]).utc().format('YYYYMMDDTHHmmss')
			}
			// console.log('pattern', pattern);
			let options = rrule.RRule.parseString(pattern);
			// options.dtstart = new Date(recurObj["startdate"]);
			options.dtstart = moment(recurObj['startdate']).utc().toDate();
			if (count && count != 0) {
				options.count = count;
			} else {
				// options.until = new Date(recurObj["until"]);
				options.until = moment(recurObj['until']).utc().toDate();
			}
			var rule = new rrule.RRule(options);
			// return rule.toText();
			var all = rule.all();
			response["humantext"] = rule.toText();
			response["all"] = all;
		}
		return response;
	}
}
