import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DefaultSettings } from '../layout-components/defaultSettings';

import * as moment from 'moment';

@Component({
  selector: 'app-event-involved-object',
  templateUrl: './event-involved-object.component.html',
  styleUrls: ['./event-involved-object.component.scss']
})
export class EventInvolvedObjectComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public errorMessage: string = '';
  public loading: boolean = false;
  /* pagination Info */
  maxSelect = 10;
  maxLengthSelect = 50;
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'email';

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  public originalTableSetting: any = undefined;
  public tableSetting: any = undefined;
  private organization: any = undefined;
  public isPasswordOrgRequired: boolean = false;
  public hasRolesOrgRequired: boolean = false;
  private _parentFormSubmitted = false;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  tagCtrl = new FormControl();
  filteredTags: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filteredRoleTags: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  allTags: any[] = [];
  public peopleType: any = DefaultSettings.peopleSingleType;
  public peopleTypeKeys: any = DefaultSettings.peopleTypeKeys;
  allRoleTags: any[] = [];
  @Input() canAdd: boolean = false;
  @Input() canSelectRole: boolean = false;
  @Input() required: boolean = false;
  @Input() itemName: string = '';
  @Input() placeholder: string = 'New...';
  @Input() value: any[] = [];
  _type: any[] = ['user'];
  dataType: string = 'resource/user';
  dataTypeDisplay: string = 'User';
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean){
      this._parentFormSubmitted = parentFormSubmitted;
      if(parentFormSubmitted){
        if(this.form)
          this.form.onSubmit(undefined);
      }
  }
  get parentFormSubmitted(): boolean{
    return this._parentFormSubmitted;
  }
  @Input()
  set type(type: any[]){
      this._type = type;
      this.filterData('');

  }
  get type(): any[]{
    return this._type;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          if(data.hasOwnProperty('stellarPasswordRequired')){
            this.isPasswordOrgRequired = data.stellarPasswordRequired;
          }
          this.hasRolesOrgRequired = (data.roleCount && data.roleCount > 0);
        }
      })
    );
    this.buildSetting();
    this.subscriptions.push(
        this.tagCtrl.valueChanges.subscribe((data) => {
          // console.log('data', data);
          if(data){
            if(data.hasOwnProperty('_id') ){
              if(!this.getTagItem(data._id)){
                this.filterData(data.name);
              }else{
                this.filterData('');
              }
            }else{
              this.filterData(data);
            }
          }else{
            this.filterData('');
          }
        })
    );
  }
  onSubmit() {
    // do nothing
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  private getSelectedItem(val){
    for(let itm of this.value){
      if(val === itm._id){
        return itm;
      }
    }
    return '';
  }
  private getSelectedItemByName(val){
    for(let itm of this.allRoleTags){
      if(val === itm.name){
        return itm;
      }
    }
    for(let itm of this.allTags){
      if(val === itm.name){
        return itm;
      }
    }
    return '';
  }
  private getTagItem(val){
    for(let itm of this.allRoleTags){
      if(val === itm._id){
        return itm;
      }
    }
    for(let itm of this.allTags){
      if(val === itm._id){
        return itm;
      }
    }
    return '';
  }
  public unsetAllOthers(id, val) {
    if(val){
      for(let itm of this.value){
        if(itm._id === id._id){
          itm.host = true;
        }else{
          itm.host = false;
        }
      }
    }
  } 
  public setSessionUserType(index, val) {
    this.value[index]['userType']=val;
    this.onSelectReturn.emit(this.value);
  }
  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    // console.log('Try pass', event.value);
    if (!this.getSelectedItemByName(event.value)) {
      if(event.value.length <= this.maxLengthSelect){
        // console.log('pass', event.value);
        const input = event.input;
        const value = event.value;
        // Add our tag
        if (this.canAdd && (value || '').trim()) {
          // this.value.push(value.trim());
          this.addData(value.trim());
        }

        // Reset the input value
        if (input) {
          input.value = '';
        }

        // this.tagCtrl.setValue('');
      }else{
        this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only add a tag of 50 characters'), this.translate.instant('Dismiss'));
      }
    }
  }

  remove(tag: any): void {
    const index = this.value.indexOf(tag);
    if (index >= 0) {
      this.value.splice(index, 1);
      this.onSelectReturn.emit(this.value);
    }
  }
  displayFn(data): string {
    return data && data.name ? data.name : '';
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    // console.log('selected', event);
    // if(this.value.length <= this.maxSelect){
      if(!this.getSelectedItem(event.option.value._id)){
        if(!this.value){
          this.value = [];
        }
        if(event.option.value.type === 'user'){
          this.value.push({_id: event.option.value._id, name: event.option.value.name, email: event.option.value.email, type: event.option.value.type, userType: event.option.value.userType, host: false});
        }else if (event.option.value.type === 'role'){
          this.value.push({_id: event.option.value._id, name: event.option.value.name, type: event.option.value.type});
        }
      
        this.onSelectReturn.emit(this.value);
      }
      this.tagInput.nativeElement.value = '';
      this.tagInput.nativeElement.blur();
      this.tagCtrl.setValue('');
    // }else{
    //   this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only select 10 tags'), this.translate.instant('Dismiss'));
    // }
  }
  public addData(term) {
    if(this.type.includes('user')){
      let newUser = this.getEmptyObject();
      newUser['name'] = term;
      newUser['firstName'] = term.split('@')[0];
      newUser['lastName'] = '.';
      newUser['organizationId'] = [{ _id: this.requestService.orgId, name: '' }];
      newUser['appId'] = [{ _id: this.requestService.appId, organizationId: this.requestService.orgId, name: '' }];
      newUser['locationId'] = [{ _id: this.requestService.locId, appId: this.requestService.appId, name: '' }];
      newUser['email'] = term;
      newUser['resources'] = [{ _id: environment.customKeys.roleView, organizationId: this.requestService.orgId, name: '' }];
      newUser['listedInTheDirectory'] = "no";
      // if(this.type){
      //   newTag['type'] = this.type;
      // }
      if (!this.loading) {
        this.loading = true;
        this.errorMessage = '';
        this.requestService.saveData(this.dataType, newUser, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            let returnResult = data.results;
            if(!this.value){
              this.value = [];
            }
            let dataTag = {_id: returnResult._id, name: returnResult.name, type: 'user', email: returnResult.email, userType: 'attendee', host: false};
            this.allTags = [dataTag];
            this.value.push(dataTag);
            this.onSelectReturn.emit(this.value);
          }else {
            this.allTags = [];
          }
          this.filteredTags.next(this.allTags);
          this.loading = false;
        });
      }
    }

  }
  public filterData(term) {
    if(this.type.includes('role') && this.type.includes('user')){
      this.getRoles(term, ()=>{
        this.getUsers(term);
      })
    }else if(this.type.includes('role')){
      this.getRoles(term, ()=>{})
    }else if(this.type.includes('user')){
      this.getUsers(term)
    }
  }
  public getRoles(term, callback) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filters = {'$and': []};
      filters['$and'].push(  { 'organizationId._id': { '$in': [this.requestService.orgId] } });
      // if(this.type){
      //   filters['$and'].push(  { 'type': { '$eq': this.type } });
      // }
      this.requestService.getDataList('role', {page: this.pageNumber , term: term, perpage: this.pageSize, orderDir: this.orderDir, orderBy: this.orderBy, fields: ['name'], filter: filters}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.allRoleTags = data.results;
        }else {
          this.allTags = [];
        }
        this.filteredRoleTags.next(this.allRoleTags);
        this.loading = false;
        callback();
      });
    }
  }
  public getUsers(term) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filters = {'$and': []};
      filters['$and'].push(  { 'organizationId._id': { '$in': [this.requestService.orgId] } });
      // if(this.type){
      //   filters['$and'].push(  { 'type': { '$eq': this.type } });
      // }
      this.requestService.getDataList(this.dataType, {page: this.pageNumber , term: term, perpage: this.pageSize, orderDir: this.orderDir, orderBy: this.orderBy, fields: ['name'], filter: filters}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.allTags = data.results.map((itm)=>{
            itm['userType'] = 'attendee';
            return itm;
          });
        }else {
          this.allTags = [];
        }
        this.filteredTags.next(this.allTags);
        this.loading = false;
      });
    }
  }
  public clearFromSelection(_id){
    let newValue = JSON.parse(JSON.stringify(this.value));
    let idx = 0;
    for(let vl of this.value){
      if(vl._id === _id){
        newValue.splice(idx, 1);
        this.onSelectReturn.emit(newValue);
        break;
      }
      idx++;
    }
  }
  private buildSetting() {
      this.errorMessage = '';
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = Object.assign({}, data.results);
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.originalTableSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (col.name === 'resources') {
              newObj[col.name] = [{ _id: '', name: '' }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: '', name: '' };
          }
        } else if (col.type === 'boolean') {
          if (col.name === 'isPasswordRequired') {
            newObj[col.name] = this.isPasswordOrgRequired;
          }else{
            newObj[col.name] = false;
          }
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else if (col.type === 'json') {
          newObj[col.name] = {};
        } else if (col.type === 'number') {
          newObj[col.name] = undefined;
        } else if (col.type === 'enum') {
          newObj[col.name] = '';
        } else {
          newObj[col.name] = '';
        }
      }
    }
    return newObj;
  }
}
