import { Component, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ViewDialogData {
	title: string;
	data: any;
}
@Component({
	selector: 'app-custom-scheduler-dialog-modal',
	templateUrl: './custom-scheduler-dialog.component.html',
  styleUrls: ['./custom-scheduler-dialog.component.scss']
})
export class ModalSchedulerDialogComponent {
	public errorMessage: string = '';
    public loading: boolean = false;
    public hasFormErrors: boolean = false;entRef;

	constructor(public dialogRef: MatDialogRef<ModalSchedulerDialogComponent>,
	@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalSchedulerDialogComponent', data);
	}
	ngOnInit() {
	}
	closeModal(data): void {
			this.dialogRef.close(data);
	}
}
