import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';
declare var rrule: any;

interface ViewDialogData {
	title: string;
	data: any;
	fields: any;
	overWrite: any;
	startdate: any;
	enddate: any;
	dataList: any[];
	confirmData: any;
}

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
	  const isSubmitted = form && form.submitted;
	  return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
  }

@Component({
	selector: 'app-recurrence-dialog-modal',
	templateUrl: './recurrence-dialog.component.html',
	styleUrls: ['./recurrence-dialog.component.scss']
})
export class RecurrenceDialogComponent implements OnInit {
	public errorMessage: string = '';
	// public ruleTextOriginal: string = '';
	public ruleText: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public byweekday: any[] = [];
	public esMatcher = new MyDialogErrorStateMatcher();
	public enddate = moment(new Date().toISOString()).utc();
	public metaFieldSetting = undefined;
	public returnData: any = {
        repeats: "daily",
        interval: 1,
        end: "after",
        count: 1
    };
	constructor(private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<RecurrenceDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('RecurrenceDialogComponent', data);
		// https://www.textmagic.com/free-tools/rrule-generator
	}

	ngOnInit() {
		if (this.data.fields) {
			this.metaFieldSetting = this.buildMetaSetting(this.data, parent = undefined);
		}
		if (this.data.enddate) {
			this.enddate = moment(this.data.enddate).utc();
		} 
		if (this.data.data.hasOwnProperty('_id')) {
			this.returnData = JSON.parse(JSON.stringify(this.data.data));
		}else{
			if(this.data.data.hasOwnProperty('startdate')){
				this.returnData = JSON.parse(JSON.stringify(this.data.data));
			}
			this.returnData['startdate'] = this.data.startdate;
		}
		console.log('metaFieldSetting', this.metaFieldSetting);
		this.setRuleText();
		// this.ruleTextOriginal = JSON.parse(JSON.stringify(this.ruleText));
	}

	closeModal(data): void {
		if(data){
			if (data.repeats !== 'yearly') {
				if (!data.interval || (data.interval && (data.interval === '' || data.interval === undefined || data.interval === null) )) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Every field'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.repeats === 'weekly' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0 ))) {
				this.layoutUtilsService.showNotification(this.translate.instant('Select a week day'), this.translate.instant('Dismiss'));
				return;
			}

			if (data.end === 'after' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0 ))) {
				if (data.repeats === 'weekly' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0 ))) {
					this.layoutUtilsService.showNotification(this.translate.instant('Select a week day'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.end === 'after') {
				if (!data.count || (data.count && (data.count === '' || data.count === undefined || data.count === null) )) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Count field'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.end === 'date') {
				if (!data.until || (data.until && (data.until === '' || data.until === undefined || data.until === null) )) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Until field'), this.translate.instant('Dismiss'));
					return;
				}
				let momentUntil = moment(data["until"]).utc();
				if (momentUntil.diff(this.enddate,'day') < 0) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Until field with a date larger than the event end date'), this.translate.instant('Dismiss'));
					return;
				}
			}
			this.dialogRef.close(data);
			// if(data.hasOwnProperty('_id') && this.ruleTextOriginal !== this.ruleText){
			// 	this.confirmChange(data);
			// }else{
			// 	this.dialogRef.close({data:data});
			// }
		}else{
			this.dialogRef.close(data);
		}
	}
	// confirmChange(data) {
	// 	const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
	// 		disableClose: true,
	// 		data: {
	// 		title: this.translate.instant('Attention'),
	// 		data: '',
	// 		description: this.translate.instant('This will will delete all future occurences of this event. Are you sure you want to proceed?'),
	// 		cancelbtn: this.translate.instant('Cancel'),
	// 		confirmbtn: this.translate.instant('Proceed'),
	// 		}
	// 	});
	// 	dialogRef.afterClosed().subscribe(result => {
	// 		if (result) {
	// 			this.dialogRef.close({data: data, overWrite: '1'});
	// 		} else {
	// 			// this.dialogRef.close(undefined);
	// 		}
	// 	});
	// }
	setRuleText(){
		// console.log('this.returnData', this.returnData);
		try {
			this.ruleText = this.getRecurrenceDatas(this.returnData).humantext;
		} catch (e) {
		  // error
		  console.log('setRuleText', e);
		}
	}
	public setUntilAttribute(val) {
		// console.log('setDateAttribute', id, val.toISOString());
		try {
			this.returnData['until'] = val.toISOString();
		} catch (e) {
			// error
			//console.log('setDateAttribute', e);
		}
	}
	onRepeatsChange(data): void {
		// console.log('onRepeatsChange', data);
		delete this.returnData['monthlyType'];
		delete this.returnData['bymonth'];
		delete this.returnData['bymonthday'];
		delete this.returnData['bysetpos'];
		delete this.returnData['byday'];
		delete this.returnData['byweekday'];
		delete this.returnData['yearlyType'];
		if(data === 'monthly'){
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			this.returnData['monthlyType'] = this.metaFieldSetting['monthlyType'].enum[0].value;
		}else if (data === 'yearly'){
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			this.returnData['yearlyType'] = this.metaFieldSetting['yearlyType'].enum[0].value;
		}else if (data === 'weekly'){
			this.returnData['byweekday'] = [this.metaFieldSetting['byweekday'].enum[0].value];
			this.byweekday = JSON.parse(JSON.stringify(this.returnData['byweekday']));;
		}
		this.setRuleText();
	}
	onMonthlyTypeChange(data): void {
		if(data === 'dayofmonth'){
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			delete this.returnData['bysetpos'];
			delete this.returnData['byday'];
		}else if(data === 'weekdayofmonth'){
			this.returnData['bysetpos'] = this.metaFieldSetting['bysetpos'].enum[0].value;
			this.returnData['byday'] = this.metaFieldSetting['byday'].enum[0].value;
			delete this.returnData['bymonthday'];
		}
		this.setRuleText();
	}
	onYearlyTypeChange(data): void {
		if(data === 'dayofmonth'){
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			delete this.returnData['bysetpos'];
			delete this.returnData['byday'];
		}else if(data === 'weekdayofmonth'){
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bysetpos'] = this.metaFieldSetting['bysetpos'].enum[0].value;
			this.returnData['byday'] = this.metaFieldSetting['byday'].enum[0].value;
			delete this.returnData['bymonthday'];
		}
		this.setRuleText();
	}
	onEndChange(data): void {
		if(data === 'after'){
			this.returnData['count'] = 1;
			delete this.returnData['until'];
		}else if(data === 'date'){
			this.returnData['until'] = moment(new Date().toISOString()).utc().format('YYYY-MM-DD');
			delete this.returnData['count'];
		}
		this.setRuleText();
	}
	onByweekdayChange(value): void {
		if(value.length === 0){
			this.returnData['byweekday'] = JSON.parse(JSON.stringify(this.byweekday));
		}else{
			this.byweekday = JSON.parse(JSON.stringify(value));
		}
		this.setRuleText();
	}
	onIntervalChange(value): void {
		this.setRuleText();
	}
	onCountChange(value): void {
		this.setRuleText();
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		// let tabObject = [];
		for (let col of data.fields) {
		  if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
			if (parent) {
			  col['inputName'] = parent + col['name'];
			}
			dataObject[col.name] = col;
		  } else if (col.type === 'object') {
			dataObject[col.name] = this.buildMetaSetting(col);
		  }
		  else if (col.type === 'table') {
			dataObject[col.name] = col;
		  }
		}
		return dataObject;
	  }
	  getRecurrenceDatas(recurObj) {
		let response = {humantext: '', all: undefined};  
		if(!recurObj){
			return response;
		}else{
			let pattern = "";
			let count = 0;
			switch (recurObj["repeats"]) {
				case "minutely":
					pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
					break;
				case "hourly":
					pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
					break;
				case "daily":
					pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
					break;
				case "weekly":
					pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
					break;
				case "monthly":
					pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
					if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
						pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						pattern += ";BYSETPOS=" + recurObj["bysetpos"] + ";BYDAY=" + recurObj["byday"].toString();
					}
					break;
				case "yearly":
					pattern = "FREQ=YEARLY;"
					if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
						pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						pattern += "BYDAY=" + recurObj["byday"] + ";BYSETPOS=" + recurObj["bysetpos"] + ";BYMONTH=" + recurObj["bymonth"].toString();
					}
					break;
				default:
					pattern = "";
			}
			if (recurObj["end"] && recurObj["end"] == "after") {
				pattern += ";COUNT=" + recurObj["count"];
				count = recurObj["count"];
			}
			if (recurObj["end"] && recurObj["end"] == "date") {
				pattern += ";UNTIL=" + moment(recurObj["until"]).utc().format('YYYYMMDDTHHmmss')
			}
			// console.log('pattern', pattern);
			let options = rrule.RRule.parseString(pattern);
			// options.dtstart = new Date(recurObj["startdate"]);
			options.dtstart = moment(recurObj['startdate']).utc().toDate();
			if (count && count != 0) {
				options.count = count;
			} else {
				// options.until = new Date(recurObj["until"]);
				options.until = moment(recurObj['until']).utc().toDate();
			}
			var rule = new rrule.RRule(options);
			// return rule.toText();
			var all = rule.all();
			response["humantext"] = rule.toText();
			response["all"] = all;
		}
		return response;
	}
}
