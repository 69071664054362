import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { RequestService } from "src/app/shared/services";
import Player from '@vimeo/player';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DefaultSettings } from "src/app/shared/components/layout-components/defaultSettings";
import { guid } from "../../helpers/guid";

@Component({
    selector: 'app-media-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.scss'],
    animations: [
        trigger('showMenu', [
            state('in', style({
                'left': '0',
            })),
            state('out', style({
                'left': '{{ collapse_to }}'
            }), { params: { collapse_to: '0' } }),
            transition('in => out', animate('1500ms ease-in-out')),
            transition('out => in', animate('1500ms ease-in-out'))
        ])
    ]
})

export class MediaLibraryComponent implements OnInit, OnDestroy {

    @Input() id: string = undefined;
    @Input() loop: boolean = false;
    @Input() autoplay: boolean = false;
    @Input() volume: number = 0.1;
    @Input() libraryId: string = undefined;
    @Input() width: number = undefined;
    @Input() menuState: string = 'out';
    @Input() fixedDimensions: boolean = false;
    @Input() disableExpand: boolean = false;

    @ViewChild('mediaLibraryPlayer') videoPlayer: ElementRef;

    media: any = [];
    mediaRaw: any = [];
    selectedMedia: any = {};
    player: any = undefined;
    filterByText: string = undefined;
    isShuffleOn: boolean = false;
    isPlayOn: boolean = false;
    mediaWidth: number = 0;
    mediaLeft: number = 0;
    menuWidth: number = 0;
    containerHeight: number = 0;
    overRideVolume: boolean = false;
    mediaPlayerUID: string = guid();

    private overRideAutoPlay: boolean = false;

    constructor(private requestService: RequestService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.volume > 1) {
            this.volume = 0.5;
        }

        if (this.id) {
            this.requestService.getDataById(this.id, 'video', (data, error) => {
                if (data && data.settings && data.settings.hasOwnProperty('videoList')) {
                    this.media = data.settings.videoList;
                    this.mediaRaw = data.settings.videoList;
                    this.selectVideo();
                }
            });

            if (!this.fixedDimensions) {
                let container = document.getElementById(this.libraryId).getBoundingClientRect();
                this.mediaWidth = (16 / 9) * container.height;
                this.mediaLeft = container.width - this.mediaWidth;
                this.menuWidth = this.mediaLeft;
                this.containerHeight = container.height;
            }
            // this.videoWidth = (16 / 9) * this.height;
        }
    }

    ngOnDestroy() { }

    selectVideo(index?: any) {
        if (this.player) {
            this.player = undefined;
        }

        this.isPlayOn = false;
        this.videoPlayer.nativeElement.innerHTML = '';

        if (this.media && this.media.length) {
            if (!index && this.media[0].url) {
                Object.assign(this.selectedMedia, this.media[0]);
                this.selectedMedia.index = 0;
                if (this.selectedMedia.type === 'youtubeVid' || this.selectedMedia.type === 'youtubePlst')
                    this.playYoutubeVideo(this.selectedMedia);
                else if (this.selectedMedia.type === 'vimeoVid' || this.selectedMedia.type === 'vimeoPlst')
                    this.playVimeoVideo(this.selectedMedia);
                else if (this.selectedMedia.type === 'audio' || this.selectedMedia.type === 'video') {
                    this.selectedMedia.url = undefined;
                    this.detectChanges();
                    this.selectedMedia.url = this.media[0].url;
                }
            }
            else {
                if (this.media[index]) {
                    Object.assign(this.selectedMedia, this.media[index]);
                    this.selectedMedia.index = index;
                    if (this.selectedMedia.type === 'youtubeVid' || this.selectedMedia.type === 'youtubePlst')
                        this.playYoutubeVideo(this.selectedMedia);
                    else if (this.selectedMedia.type === 'vimeoVid' || this.selectedMedia.type === 'vimeoPlst')
                        this.playVimeoVideo(this.selectedMedia);
                    else if (this.selectedMedia.type === 'audio' || this.selectedMedia.type === 'video') {
                        this.selectedMedia.url = undefined;
                        this.detectChanges();
                        this.selectedMedia.url = this.media[index].url;

                    }
                    // else if (this.selectedVideo.type === 'vimeoPlst')
                    //     this.playVimeoShowCase(this.selectedVideo);
                }
            }

            // if (this.selectedVideo) {
            //     let scrollToElement = document.getElementById(this.selectedVideo.id);
            //     if (scrollToElement) {
            //         scrollToElement.scrollIntoView({
            //             behavior: 'auto',
            //             block: 'center',
            //             inline: 'nearest'
            //         });
            //     }
            // }
        }
    }

    playYoutubeVideo(video: any) {
        if (video.url) {
            let element = document.createElement('div');
            this.videoPlayer.nativeElement.appendChild(element);

            let playerVars = {
                origin: this.requestService.serverHostUrl,
                enablejsapi: 1,
                // loop: this.loop,
                rel: 0,
                autoplay: this.autoplay || this.overRideAutoPlay
            };

            if (video.id && video.type === 'youtubePlst') {
                playerVars['list'] = video.id;
                playerVars['listType'] = 'playlist';
            }

            window['YT'].ready(() => {
                this.player = new window['YT'].Player(element, {
                    width: '100%',
                    height: '100%',
                    videoId: video.id,
                    playerVars: playerVars,
                    events: {
                        'onStateChange': this.onPlayerStateChange.bind(this),
                        'onReady': (e) => {
                            e.target.unMute();
                            if (this.overRideVolume)
                                e.target.setVolume(DefaultSettings.defaultRoomSetting["green-room"].defaultSessionAudio);
                            else
                                e.target.setVolume(this.volume * 100);
                        }
                    }
                });
            });
        }
    }

    playVimeoVideo(video: any) {
        if (video.url) {
            let element = document.createElement('div');
            this.videoPlayer.nativeElement.appendChild(element);

            let options = {
                id: video.url.replace('/embed', ''),
                // loop: this.loop,
                autoplay: this.autoplay || this.overRideAutoPlay,
                muted: false,
                responsive: false,
                width: this.mediaWidth - 5 + 'px',
                height: this.containerHeight + 'px'
            };

            this.player = new Player(element, options);

            this.player.on('ended', () => {
                if (this.selectedMedia) {
                    if (this.isShuffleOn) {
                        this.selectVideo(this.randomIntFromInterval(0, this.media.length, this.selectedMedia.index));
                    }
                    else {
                        if (this.media.length > (this.selectedMedia.index + 1)) {
                            this.selectVideo(this.selectedMedia.index + 1);
                        }
                        else if (this.loop && (this.selectedMedia.index + 1) === this.media.length) {
                            this.selectVideo(0);
                        }
                    }
                }
            });

            this.player.on('pause', () => {
                this.player.getEnded().then((ended) => {
                    if (!ended) {
                        this.isPlayOn = false;
                        if (!this.autoplay && this.isShuffleOn)
                            this.overRideAutoPlay = false;
                        this.detectChanges();
                    }
                });
            });

            this.player.on('playing', () => {
                this.isPlayOn = true;
                if (!this.autoplay && this.isShuffleOn)
                    this.overRideAutoPlay = true;
                this.detectChanges();
            });

            this.player.on('loaded', () => {
                if (this.autoplay || this.overRideAutoPlay) {
                    this.player.play().then(() => { }).catch((error) => {
                        this.player.setMuted(true).then(() => {
                            this.player.play();
                        });
                    });
                }
            });

            if (this.overRideVolume)
                this.player.setVolume(DefaultSettings.defaultRoomSetting["green-room"].defaultSessionAudio / 100);
            else
                this.player.setVolume(this.volume);
        }
    }

    // playVimeoShowCase(video: any) {
    //     if (video.url) {
    //         let element = document.createElement('iframe');
    //         element.src = 'https://vimeo.com/showcase/8291426/embed';
    //         this.videoPlayer.nativeElement.appendChild(element);
    //     }
    // }

    onPlayerStateChange(event) {
        switch (event.data) {
            case window['YT'].PlayerState.PLAYING:
                this.isPlayOn = true;
                if (!this.autoplay && this.isShuffleOn)
                    this.overRideAutoPlay = true;
                this.detectChanges();
                // if (this.player)
                //     this.player.unMute();
                break;
            case window['YT'].PlayerState.PAUSED:
                this.isPlayOn = false;
                if (!this.autoplay && this.isShuffleOn)
                    this.overRideAutoPlay = false;
                this.detectChanges();
                break;
            case window['YT'].PlayerState.ENDED:
                if (this.selectedMedia) {
                    if (this.isShuffleOn) {
                        this.selectVideo(this.randomIntFromInterval(0, this.media.length, this.selectedMedia.index));
                    }
                    else {
                        if (this.media.length > (this.selectedMedia.index + 1)) {
                            this.selectVideo(this.selectedMedia.index + 1);
                        }
                        else if (this.loop && (this.selectedMedia.index + 1) === this.media.length) {
                            this.selectVideo(0);
                        }
                    }
                }
                break;
        };
    }

    searchByText() {
        this.media = this.filter(this.filterByText, this.mediaRaw);
    }

    private filter(value: string, list: any): [] {
        const filterValue = value.toLowerCase();
        return list.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    toggleMenu() {
        this.menuState = this.menuState === 'in' ? 'out' : 'in';
    }

    toggleShuffle() {
        this.isShuffleOn = !this.isShuffleOn;
        if (!this.isShuffleOn)
            this.overRideAutoPlay = false;
    }

    togglePlay() {
        this.isPlayOn = !this.isPlayOn;

        if (!this.autoplay && this.isShuffleOn)
            this.overRideAutoPlay = this.isPlayOn;

        if (this.player) {
            if (this.selectedMedia.type === 'youtubeVid' || this.selectedMedia.type === 'youtubePlst') {
                if (this.isPlayOn)
                    this.player.playVideo();
                else
                    this.player.pauseVideo();
            }
            else if (this.selectedMedia.type === 'vimeoVid' || this.selectedMedia.type === 'vimeoPlst') {
                if (this.isPlayOn)
                    this.player.play().then(() => { }).catch((error) => {
                        this.player.setMuted(true).then(() => {
                            this.player.play();
                        });
                    });
                else
                    this.player.pause();
            }
        }
        else if (this.selectedMedia.type === 'audio' || this.selectedMedia.type === 'video') {
            let player: any = document.getElementById(this.mediaPlayerUID + 'player-' + this.selectedMedia.id);
            if (this.isPlayOn)
                player.play();
            else
                player.pause();
        }
        this.detectChanges();
    }

    private randomIntFromInterval(min, max, exclude) {
        let possibleIndices = [...Array(max).keys()];
        if (exclude)
            possibleIndices = possibleIndices.filter(i => i != exclude);

        // console.log(possibleIndices)
        let nextIndex = Math.floor(Math.random() * ((possibleIndices.length - 1) - min + 1) + min);
        // console.log(nextIndex, possibleIndices[nextIndex]);
        return possibleIndices[nextIndex];
    }

    skipVideo() {
        if (this.isShuffleOn) {
            this.selectVideo(this.randomIntFromInterval(0, this.media.length, this.selectedMedia.index));
        }
        else {
            if (this.media.length > (this.selectedMedia.index + 1)) {
                this.selectVideo(this.selectedMedia.index + 1);
            }
            else if (this.loop && (this.selectedMedia.index + 1) === this.media.length) {
                this.selectVideo(0);
            }
        }
    }

    isMediaPlaying(isPlaying: boolean) {
        this.isPlayOn = isPlaying;
        if (!this.autoplay && this.isShuffleOn)
            this.overRideAutoPlay = isPlaying;
        this.detectChanges();
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    onMediaEnd() {
        if (this.selectedMedia) {
            if (this.isShuffleOn) {
                this.selectVideo(this.randomIntFromInterval(0, this.media.length, this.selectedMedia.index));
            }
            else {
                if (this.media.length > (this.selectedMedia.index + 1)) {
                    this.selectVideo(this.selectedMedia.index + 1);
                }
                else if (this.loop && (this.selectedMedia.index + 1) === this.media.length) {
                    this.selectVideo(0);
                }
            }
        }
    }
}