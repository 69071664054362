<div class="col-xl-12" *ngIf="data">
	<div mat-dialog-content *ngIf="data.confirmData">
		<div *ngIf="fieldsDetails">
			<div class="mb-20 profile-page-header" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
					<div fxFlex="15">
						<figure class="card-profile-image">
								<img style="max-height: 120px;" [src]="pictureLink" class="circle z-depth-2 responsive-img activator">
						</figure>
					</div>
					<div fxFlex="85" class="topData topDataName">
							<div class="dataHead"><span *ngIf="fieldsDetails['title'] && fieldsDetails['title'].visible && data.confirmData.hasOwnProperty('title') && data.confirmData['title'] !== ''"><app-enum-view  [enumValue]="data.confirmData['title']" [enumList]="fieldsDetails['title'].enum"></app-enum-view>&nbsp;</span>
							<span *ngIf="fieldsDetails['firstName'] && fieldsDetails['firstName'].visible && data.confirmData.hasOwnProperty('firstName') && data.confirmData['firstName'] !== ''">{{data.confirmData['firstName']}} </span> <span *ngIf="fieldsDetails['middleName'] && fieldsDetails['middleName'].visible && data.confirmData.hasOwnProperty('middleName') && data.confirmData['middleName'] !== ''">{{data.confirmData['middleName']}}</span> <span *ngIf="fieldsDetails['lastName'] && fieldsDetails['lastName'].visible && data.confirmData.hasOwnProperty('lastName') && data.confirmData['lastName'] !== ''"> {{data.confirmData['lastName']}}</span></div>
							<div class="dataHead" *ngIf="fieldsDetails['nickName'] && fieldsDetails['nickName'].visible && data.confirmData.hasOwnProperty('nickName') && data.confirmData['nickName'] !== ''">{{fieldsDetails['nickName']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['nickName']}}</span></div>
							<div class="dataHead" *ngIf="fieldsDetails['email'] && fieldsDetails['email'].visible && data.confirmData.hasOwnProperty('email') && data.confirmData['email'] !== '' && data.confirmData.isEmailAddress">{{fieldsDetails['email']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['email']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['phone'] && fieldsDetails['phone'].visible && data.confirmData.hasOwnProperty('phone') && data.confirmData['phone'] !== '' && data.confirmData.isPhoneNumber">{{fieldsDetails['phone']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['phone']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['position'] && fieldsDetails['position'].visible && data.confirmData.hasOwnProperty('position') && data.confirmData['position'] !== ''">{{fieldsDetails['position']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['position']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['company'] && fieldsDetails['company'].visible && data.confirmData.hasOwnProperty('company') && data.confirmData['company'] !== ''">{{fieldsDetails['company']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['company']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['specialty'] && fieldsDetails['specialty'].visible && data.confirmData.hasOwnProperty('specialty') && data.confirmData['specialty'] !== ''">{{fieldsDetails['specialty']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['specialty']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['details'] && fieldsDetails['details'].visible && data.confirmData.hasOwnProperty('details') && data.confirmData['details'] !== ''">{{fieldsDetails['details']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['details']}}</span></div>

							<div class="dataHead" *ngIf="fieldsDetails['country'] && fieldsDetails['country'].visible && data.confirmData.hasOwnProperty('country') && data.confirmData['country'] !== ''">{{fieldsDetails['country']['displayName'] | translate}}: <span class="inlineText">
								<app-enum-view  [enumValue]="data.confirmData['country']" [enumList]="fieldsDetails['country'].enum"></app-enum-view>
							</span></div>


					</div>
			</div>
    </div>
	</div>
	<div mat-dialog-actions>
			<div class="btnList" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')">
		    <div fxFlex>
					<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
					<!-- <button *ngIf="data.profile" mat-raised-button [routerLinkActive]="'active'" [routerLink]="['/profile']" (click)="closeModal(undefined)">
			        {{'Edit' | translate}}
			    </button> -->
					<!-- <button *ngIf="!data.onlyView && data.profile" mat-raised-button [routerLinkActive]="'active'" [routerLink]="['/profile']" (click)="closeModal(undefined)">
			        {{'Edit and Preferences' | translate}}
			    </button> -->

					<!-- <button *ngIf="!data.profile" mat-raised-button [routerLinkActive]="'active'" [routerLink]="['/admin/user/edit/' + data.data._id]" (click)="closeModal(undefined)">
			        Edit
			    </button> -->
					<button *ngIf="!data.onlyView && data.confirmData && !data.profile && ((isSuperAdmin && data.confirmData.isSuperAdmin) || !data.confirmData.isSuperAdmin)" mat-raised-button (click)="closeModal('edit')">
			         {{'Edit' | translate}}
			    </button>
		    </div>
		    <!-- <div fxFlex style="text-align: right;" *ngIf="!data.onlyView && data.profile && data.confirmData && data.confirmData.isPasswordRequired && ((isSuperAdmin && data.confirmData.isSuperAdmin) || !data.confirmData.isSuperAdmin)" >
					<button mat-raised-button (click)="changePassword()">
			        {{'Change Password' | translate}}
			    </button>
		    </div> -->
				<div fxFlex style="text-align: right;" *ngIf="!data.onlyView && data.confirmData && !data.profile && ((isSuperAdmin && data.confirmData.isSuperAdmin) || !data.confirmData.isSuperAdmin)">
					<button mat-raised-button type="button" [disabled]="loading" (click)="resetPassword()">
	            {{'Reset Password' | translate}}
	        </button>
		    </div>
		  </div>
	</div>
</div>
