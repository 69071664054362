import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ModalViewImageDialogComponent } from "src/app/shared/components/custom-view-image-dialog/custom-view-image-dialog.component";
import { RequestService } from "src/app/shared/services/request.service";
import { TokBoxService } from "../../services";

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})

export class ImageGalleryComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('gallery') gallery: ElementRef;

    @Input() galleryId: string = undefined;
    @Input() sessionData: any = undefined;

    public view: string = undefined;
    public showIcon: boolean = false;
    public slideshowImageHeight: any = undefined;
    public thumbnailHeight: any = undefined;
    public images: any = [];
    public selectedImage: any = undefined;
    public direction: string = undefined;
    public showThumbnail: boolean = true;

    private gallerySettings: any = undefined;
    private timer: any = undefined;
    private subscriptions: Subscription[] = [];

    constructor(private dialog: MatDialog, private requestService: RequestService, private changeDetectorRef: ChangeDetectorRef, private tokboxService: TokBoxService) { }

    ngOnInit() {
        if (this.galleryId) {
            this.requestService.getDataById(this.galleryId, 'gallery', (data, error) => {
                if (data && data.settings && data.settings.hasOwnProperty('imageList')) {
                    this.images = data.settings.imageList;
                    this.selectedImage = this.images[0];
                    this.view = data.defaultlayout;
                    this.gallerySettings = data.settings;

                    if (data.settings.slideshowsettings) {
                        if (data.settings.slideshowsettings.thumbnailsStatus === 1)
                            this.showThumbnail = true;
                        else if (data.settings.slideshowsettings.thumbnailsStatus === 2 && !this.sessionData)
                            this.showThumbnail = false;
                        else if (data.settings.slideshowsettings.thumbnailsStatus === 2 && this.sessionData)
                            this.showThumbnail = true;
                        else if (data.settings.slideshowsettings.thumbnailsStatus === 3 && this.sessionData)
                            this.showThumbnail = false;
                        else if (data.settings.slideshowsettings.thumbnailsStatus === 3 && !this.sessionData)
                            this.showThumbnail = true;
                        else if (data.settings.slideshowsettings.thumbnailsStatus === 4)
                            this.showThumbnail = false;
                    }

                    if (!this.sessionData)
                        this.loadThumbnails();
                    // else
                    this.subscriptions.push(this.tokboxService.sessionConnected.subscribe((connected => {
                        if (connected) {
                            if (this.gallery?.nativeElement && this.gallerySettings?.slideshowsettings?.thumbnails) {
                                this.loadThumbnails();
                            }
                        }
                    })));
                }
            });
        }
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    private loadThumbnails() {
        setTimeout(() => {
            this.slideshowImageHeight = Math.round(this.gallery.nativeElement.getBoundingClientRect().height * ((100 - this.gallerySettings.slideshowsettings.thumbnails) / 100)) - 5;
            this.thumbnailHeight = Math.round(this.gallery.nativeElement.getBoundingClientRect().height * (this.gallerySettings.slideshowsettings.thumbnails / 100));
        }, 500);
    }

    moveNext() {
        this.direction = undefined;
        this.detectChanges();

        this.direction = 'next';
        let idx = this.images.findIndex(i => i.id === this.selectedImage.id);
        if (idx === this.images.length - 1)
            this.selectedImage = this.images[0];
        else
            this.selectedImage = this.images[idx + 1];

        if (document.getElementById(this.selectedImage.id + '-' + this.galleryId))
            document.getElementById('carousel-' + this.galleryId).scrollLeft = document.getElementById(this.selectedImage.id + '-' + this.galleryId).offsetLeft;
    }

    movePrevious() {
        this.direction = undefined;
        this.detectChanges();

        this.direction = 'previous';
        let idx = this.images.findIndex(i => i.id === this.selectedImage.id);
        if (idx === 0)
            this.selectedImage = this.images[this.images.length - 1];
        else
            this.selectedImage = this.images[idx - 1];

        if (document.getElementById(this.selectedImage.id + '-' + this.galleryId))
            document.getElementById('carousel-' + this.galleryId).scrollLeft = document.getElementById(this.selectedImage.id + '-' + this.galleryId).offsetLeft;
    }

    openImage(): void {
        const dialogRef = this.dialog.open(ModalViewImageDialogComponent, {
            width: 'auto',
            data: {
                imageUrl: this.selectedImage.url,
                selectedImageId: this.selectedImage.id,
                images: this.images,
                data: undefined
            }
        });
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    showToggleIcon(show) {
        if (show) {
            if (this.timer)
                clearTimeout(this.timer);
            this.showIcon = true;
        }
        else
            this.timer = setTimeout(() => this.showIcon = false, 2000);
    }
}