import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomSelectComponent } from '../../../../shared/components/custom-select/custom-select.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalVideoDialogComponent } from '../../../../shared/components/custom-video-dialog/custom-video-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface ViewDialogData {
	title: string;
	confirmData: any;
	data:any;
}

@Component({
	selector: 'app-video-playlist-dialog-modal',
	templateUrl: './video-playlist-dialog.component.html',
	styleUrls: ['./video-playlist-dialog.component.scss']
})
export class VideoPlaylistDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
  public dataType: string = 'video';
  public dataTypeDisplay: string = this.translate.instant('Media');
  public filterData: any = {'$and': [
		{'organizationId._id': { '$eq': this.requestService.orgId }}
	]};
	public returnData: any = { id: '', name: '', autoPlay: false, loop: false, volume: 0.5};

  @ViewChild('customselect') customselect: CustomSelectComponent;
	constructor(
		private requestService: RequestService, private router: Router,
	  private activatedRoute: ActivatedRoute, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<VideoPlaylistDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ViewDialogData', data);
	}

	ngOnInit() {
		this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
					if(this.data.data){
						this.returnData = this.data.data;
					}
        }
      })
    );
	}

	selectItemCheck(data) {
		if(data){
			this.returnData['id'] = data._id;
			this.returnData['name'] = data.text;
		}
		// this.dialogRef.close(data);
	}
	closeModal(data): void {
		if (data) {
			if (data.id && data.id === '') {
					this.layoutUtilsService.showNotification(this.translate.instant('Select Media Library'), this.translate.instant('Dismiss'));
					return;
			}
		}
		this.dialogRef.close(data);
	}
	goToVideoLibrary(): void {
		this.dialogRef.close();
		this.router.navigate(['/admin/mediaplaylists'], { relativeTo: this.activatedRoute });
	}
	createVideoLibrary(){
		const dialogRef = this.dialog.open(ModalVideoDialogComponent, {
				width: '1600px',
				data: {
					dataType: this.dataType,
					dataTypeTitle: this.dataTypeDisplay,
					title: this.translate.instant('Edit') + ' ' + this.dataTypeDisplay,
					data: {},
					modalSetting: this.getSetting({}, this.getCustomFilter())
				}
			});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				console.log('result', result);
				this.customselect.refresh();
			}
		});
	}
	editVideoLibrary(id){
		const dialogRef = this.dialog.open(ModalVideoDialogComponent, {
				width: '1600px',
				data: {
					dataType: this.dataType,
					dataTypeTitle: this.dataTypeDisplay,
					// title: this.translate.instant('Edit') + ' ' + this.dataTypeDisplay,
					title:  this.returnData['name'],
					data: {_id: id},
					modalSetting: this.getSetting({}, this.getCustomFilter())
				}
			});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('result', result);
				this.customselect.refresh();
			}
		});
	}
	private getSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      }
    };
    return tableSetting;
  }
  private getCustomFilter() {
      let filters = {};
      filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
      return filters;
  }
}
