<ng-container *ngIf="!forEdit">
	<mat-card class="example-card" [class.invisible]="iframeLink=== undefined || iframeLink === ''"
		style="overflow-y: auto; padding: 0; border-radius: 0;" [style.height]="_iframeParentHeight"
		[style.padding.vw]="iframePadding">
		<mat-card-content>
			<div class="fieldItem" [style.height]="iframeHeight" [style.min-height]="_iframeParentHeight">
				<span *ngIf="loading">{{'Loading...' | translate}}</span>
				<iframe [id]="iframeId" *ngIf="iframe_html" #iframeMain width="100%" frameBorder="0" [src]="iframe_html"
					(load)="onload($event)" [height]="_iframeParentHeight"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;camera;microphone;display-capture;"
					allowfullscreen [class.invisible]="loading" [class.disable-pointer]="disablePointer"></iframe>
			</div>
		</mat-card-content>
	</mat-card>
</ng-container>
<ng-container *ngIf="forEdit">
	<div class="fieldItem" [style.height]="iframeHeight" [style.min-height]="_iframeParentHeight"
		[ngStyle]="{'z-index': zIndex? zIndex : ''}">
		<span *ngIf="loading">{{'Loading...' | translate}}</span>
		<iframe id="iframeMain" *ngIf="iframe_html" #iframeMain width="100%" frameBorder="0" [src]="iframe_html"
			(load)="onload($event)" [height]="_iframeParentHeight"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;camera;microphone;display-capture;"
			allowfullscreen [class.invisible]="loading"></iframe>
	</div>
</ng-container>