<mat-toolbar color="primary" fxFlexLayout="column" class="fix-nav" [class.fix-nav-mobile]="isMobile"
    [class.fix-nav-mobile-open]="isTopOpen" *ngIf="selectedUser">
    <ng-template [ngxPermissionsOnly]="permission">
        <button type="button" mat-icon-button class="visible-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
        </button>
        <button type="button" mat-icon-button class="hidden-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>vertical_split</mat-icon>
        </button>
    </ng-template>
    <div class="nav-brand" title="{{subTitle}}" fxFlex fxGrow="0">
        <!-- {{title}} -->
        <!-- <a href="javascript:void(0)" (click)="goHome()">
            <img src="{{imageInputTopLeftImage}}" (error)="imageInputTopLeftImage = topLeftDefaultImage"
                class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}">
        </a> -->
        <a href="javascript:void(0)" (click)="goHome()">
            <img src="{{topLeftDefaultImage}}" class="circle z-depth-2 responsive-img activator" style="height: 60%;"
                alt="{{title | translate}}">
        </a>
        <a href="https://stellar.online" class="stellar-link" title="{{ 'Stellar Site' | translate}}"
            target="_blank">www.stellar.online</a>
        <!-- <span style="letter-spacing: 2px;" class="hidden-sm">{{title}}</span> -->
    </div>
    <!-- <form class="hidden-sm" style="margin-left: 20px; margin-top: 5px">
        <mat-form-field>
            <input matInput [placeholder]="'Search' | translate">
        </mat-form-field>
    </form> -->
    <!-- <span class="nav-spacer"></span> -->
    <!-- <a href="https://github.com/start-javascript/sb-admin-material/archive/master.zip" class="hidden-sm" mat-raised-button style="margin-right: 10px">
        <mat-icon>cloud_download</mat-icon> {{ 'Download Now' | translate }}
    </a> -->
    <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="language">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
        <button mat-menu-item (click)="changeLang('en')">
            <span>{{ 'English' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('fr')">
            <span>{{ 'French' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('ur')">
            <span>{{ 'Urdu' | translate }}s</span>
        </button>
    </mat-menu> -->
    <div class="lectureTitle" fxFlex [class.border-left]="sessionData && roomData">
        <div class="ellipsis production-label">
            <ng-container *ngIf="!isMobile && showTemplateActions && roomfilterData">
                <form class="hidden-sm" style="margin-left: 20px; margin-top: 10px">
                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
                        <div fxFlex="40">
                            <app-custom-select-create-autocomplete #roomList [filters]="roomfilterData"
                                [dataType]="roomDataType" [value]="roomId" [dataTypeDisplay]="'Room'"
                                [placeholder]="'Select Room'" [itemPlaceholder]="'List all Rooms'" [canDelete]="false"
                                [simple]="true" (onSelectReturn)="selectRoom($event._id)" [pageSize]="1000"
                                [hasAddBtn]="false" [hasEditBtn]="false"
                                (onReturnSignal)="setTemplateActionReturn('room-' + $event)" [canNewList]="canNewList">
                            </app-custom-select-create-autocomplete>
                            <!-- [bdcWalkTriggerFor]="sessionStep0" -->
                        </div>
                        <div fxFlex="40" class="sessionTitle">
                            <span *ngIf="layoutSessionData && roomId && sessionfilterData" [hidden]="backLayer">
                                <app-custom-select-create-autocomplete #sessionList [filters]="sessionfilterData"
                                    [dataType]="sessionDataType" [value]="layoutSessionData._id"
                                    [dataTypeDisplay]="'Session'" [placeholder]="'Select Session'" [canDelete]="false"
                                    [showActive]="true" [simple]="true" (onSelectReturn)="selectSession($event._id)"
                                    [pageSize]="1000" (onReturnSignal)="setTemplateActionReturn('session-' + $event)">
                                </app-custom-select-create-autocomplete>
                                <span class="activeSession" *ngIf="activeMode" title="{{'Selected session is active'}}">
                                    {{'Active' | translate}}
                                </span>
                            </span>
                        </div>
                    </div>
                </form>
            </ng-container>
            <span *ngIf="showProductionLabel">
                <mat-icon title="{{'Go to Room Settings' | translate}}" *ngIf="isShowSettings && !isMobile && !isTablet"
                    class="settings-room-icon" (click)="goToRoomSettings()">settings</mat-icon>
                {{'Production Room' | translate}} -
            </span><span *ngIf="roomData">
                <mat-icon title="{{'Go to Room Settings' | translate}}"
                    *ngIf="isShowSettings && !showProductionLabel && !isMobile && !isTablet" class="settings-room-icon"
                    (click)="goToRoomSettings()">settings</mat-icon>
                <span class="spacing">{{roomData.name}}</span>
            </span>
        </div>
        <div *ngIf="sessionData" title="{{sessionData.name}}" id="session-data-name">
            <div class="ellipsis session-name">
                <mat-icon *ngIf="isShowSettings && !isMobile && !isTablet" class="settings-icon"
                    (click)="goToSettings()" title="{{'Go to Session Settings' | translate}}">settings</mat-icon>
                <span class="spacing">{{sessionData.name}}</span>
                <span *ngIf="breakoutData && breakoutData.name">&nbsp;-&nbsp;{{breakoutData.name}}</span>
                <!-- <span class="countdown"
                    *ngIf="countDownTextDay >= 0 || countDownTextHour >= 0 || countDownTextMin >= 0 || countDownTextSec >= 0">
                    &nbsp;-&nbsp;<span *ngIf="countDownTextDay">{{countDownTextDay}}d:</span><span
                        *ngIf="countDownTextHour">{{countDownTextHour}}h:</span><span
                        *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length == 1">
                        0{{countDownTextMin}}m:</span><span
                        *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length != 1">{{countDownTextMin}}m:</span><span
                        *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length == 1">0{{countDownTextSec}}s</span><span
                        *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length != 1">{{countDownTextSec}}s</span>
                </span> -->
            </div>
        </div>
    </div>

    <div class="rightIcons" fxFlex fxLayoutAlign="end">
        <div *ngIf="!isMobile" class="desktop-icon">
            <ng-container [ngTemplateOutlet]="rightIcons"></ng-container>
        </div>
        <!-- <div *ngIf="isMobile" class="icon-container mobile-icon" exceptclick>
            <button mat-icon-button (click)="toggleRightSidebar()" exceptclick>
                <mat-icon exceptclick>more_vert</mat-icon>
            </button>
        </div> -->
        <div *ngIf="isMobile" class="icon-container mobile-icon">
            <button mat-icon-button (click)="toggleRightSidebar()" exceptclick>
                <mat-icon exceptclick>more_vert</mat-icon>
            </button>
        </div>
        <div *ngIf="isMobile" class="mobile-icon-container" clickOutside (clickOutside)="clickingOutside()">
            <div class="mobile-icons">
                <ng-container [ngTemplateOutlet]="rightMobileIcons"></ng-container>
            </div>
        </div>

        <div *ngIf="isMobile" class="icon-container home-main-icon ga-lobby" (click)="goHome()">
            <button mat-icon-button [disableRipple]="true" [routerLink] matTooltipClass="tooltip-menu"
                matTooltip="{{'hints.goToLobby' | translate}}">
                <mat-icon style="font-size: 26pt; color: #1d74ff; overflow: visible;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <div *ngIf="isMobile" class="icon-container ga-profile" style="margin: 0 1.4vw 0px 0.4vw;"
            [matMenuTriggerFor]="profileMenu" #profileMenuTrigger="matMenuTrigger"
            [class.active-icon]="profileMenuTrigger.menuOpen || route.snapshot['_routerState'].url === '/profile'">
            <button mat-icon-button [routerLink]
                [title]="!pageScope.isHintOn ? selectedUser.firstName + ' ' + selectedUser.lastName : ''">
                <span style="font-weight: bold">{{selectedUser.firstName | myfirstLetterPipe}}
                    {{selectedUser.lastName | myfirstLetterPipe}}</span>
            </button>
            <div class="icon-tooltip">
                {{'Menu' | translate}}
            </div>
            <mat-menu #profileMenu="matMenu" yPosition="below">
                <button mat-menu-item (click)="openProfile()" class="sub-menu">
                    <mat-icon>person</mat-icon>{{ 'My Profile' | translate }}
                </button>
                <button mat-menu-item [routerLink] (click)="goToHelpDesk()" class="ga-helpdesk sub-menu"
                    [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && settingOrgObject.web.topBar.disableHelpDesk">
                    <mat-icon>help_center
                    </mat-icon> {{'Help Desk' | translate}}
                </button>
                <button *ngIf="isBusy" mat-menu-item class="sub-menu">
                    <mat-icon>do_disturb_on</mat-icon>
                    <span style="color: red;"
                        [title]="'You are currently inside a session and cannot make/receive calls.' | translate">
                        {{'Busy' | translate}}
                    </span>
                </button>
                <button
                    [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && !(!settingOrgObject.web.topBar.disableCommunicate || !settingOrgObject.web.topBar.disableVideoCommunicate)"
                    *ngIf="!isBusy" mat-menu-item (click)="toggleDnD()" class="sub-menu">
                    <mat-icon *ngIf="selectedUser['chat_dnd']">do_disturb_on</mat-icon>
                    <mat-icon *ngIf="!selectedUser['chat_dnd']">do_disturb_off</mat-icon>
                    <span *ngIf="!selectedUser['chat_dnd']">
                        {{'Do not Disturb: Off' | translate}}
                    </span>
                    <span *ngIf="selectedUser['chat_dnd']">
                        {{"Do not Disturb: On" | translate}}
                    </span>
                </button>
                <button mat-menu-item (click)="onLoggedout()" class="sub-menu">
                    <mat-icon>exit_to_app</mat-icon>{{'Log Out' | translate}}
                </button>
            </mat-menu>
        </div>
        <div *ngIf="isMobile" class="topBarOpener" (click)="isTopOpen=!isTopOpen">
            <mat-icon style="font-size: 26pt;" *ngIf="!isTopOpen">keyboard_arrow_down</mat-icon>
            <mat-icon style="font-size: 26pt;" *ngIf="isTopOpen">keyboard_arrow_up</mat-icon>
        </div>
    </div>
    <ng-template #rightIcons>
        <ng-container *ngIf="showTemplateActions">
            <div style="margin-right: 1vw;">
                <div class="fieldInput actionCssTop">
                    <span
                        *ngIf="templateObject && templateObject.roomData._id && templateObject.roomData.is3rd && !backLayer">{{desktopNames[viewMode]}}</span>
                    <!-- <button mat-icon-button (click)="resetTutorial()" matTooltip="{{'Start Tutorial' | translate}}"
                        matTooltipClass="tooltip-red">
                        <mat-icon>help_center</mat-icon>
                    </button> -->
                    <ng-container *ngIf="roomId">
                        <mat-divider
                            *ngIf="templateObject && templateObject.roomData._id && templateObject.roomData.is3rd && !backLayer"
                            [vertical]="true"></mat-divider>
                        <!-- <button mat-icon-button (click)="setBackLayer(!backLayer)" matTooltip="Switch layers"
                            matTooltipClass="tooltip-red">
                            <mat-icon *ngIf="backLayer">flip_to_back</mat-icon>
                            <mat-icon *ngIf="!backLayer">flip_to_front</mat-icon>
                        </button>
                        <mat-divider [vertical]="true"></mat-divider> -->
                        <!-- <button *ngFor="let itm of viewPortModesKeys" mat-icon-button (click)="setViewPort(itm)"
                            matTooltip="Switch to {{viewPortModes[itm].name}} View Port" matTooltipClass="tooltip-red"
                            class="viewPorts" [class.viewPortSelected]="viewPortMode === itm">
                            <mat-icon>{{viewPortModes[itm].icon}}</mat-icon>
                        </button>
                        <mat-divider [vertical]="true"></mat-divider> -->
                        <button *ngIf="!backLayer" mat-icon-button (click)="goToSession(layoutSessionData._id, true)"
                            matTooltip="Go to Session" matTooltipClass="tooltip-red">
                            <!-- <mat-icon>preview</mat-icon> -->
                            {{'Preview' | translate}}
                        </button>
                        <button *ngIf="backLayer" mat-icon-button (click)="goToRoom(roomId)" matTooltip="Go to Room"
                            matTooltipClass="tooltip-red">
                            <!-- <mat-icon>preview</mat-icon> -->
                            {{'Preview' | translate}}
                        </button>
                        <!-- <mat-divider [vertical]="true"></mat-divider> -->
                    </ng-container>
                    <!-- <ng-container *ngIf="templateObject && templateObject.data._id"> -->
                    <ng-container *ngIf="roomId">
                        <button *ngIf="backLayer || !(templateObject && templateObject.data._id)" mat-icon-button
                            (click)="setTemplateActionReturn('edit')" [disabled]="loading">{{'Save' |
                            translate}}</button>
                        <button mat-icon-button *ngIf="!backLayer && templateObject && templateObject.data._id"
                            [disabled]="loading" [matMenuTriggerFor]="saveoption" matTooltip="{{'Save' | translate}}"
                            matTooltipClass="tooltip-red"
                            [hidden]="layoutSessionData && (layoutSessionData.active && !isAdmin)">
                            <!-- <mat-icon>save</mat-icon> -->
                            {{'Save' | translate}}<mat-icon style="width: 10px;">arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #saveoption="matMenu">
                            <button *ngIf="!(templateObject && templateObject.data._id)" mat-menu-item
                                (click)="setTemplateActionReturn('edit')"><span>
                                    {{'Save for this Room' | translate}}</span></button>
                            <button *ngIf="templateObject && templateObject.data._id" mat-menu-item
                                (click)="setTemplateActionReturn('edit')"><span>
                                    {{'Save for this Session' | translate}}</span></button>
                            <button *ngIf="templateObject && templateObject.data._id" mat-menu-item
                                (click)="setTemplateActionReturn('edit-sessions')"><span>
                                    {{'Save for all sessions in this room' | translate}}</span></button>
                            <button mat-menu-item (click)="setTemplateActionReturn('savetemplate')"
                                *ngIf="templateObject && templateObject.data._id"><span>Save as a
                                    Template</span></button>
                        </mat-menu>
                    </ng-container>
                    <ng-container
                        *ngIf="templateObject && ((templateObject.data._id && !backLayer) || (templateObject.roomData._id && backLayer))">
                        <!-- <mat-divider [vertical]="true"
                            [hidden]="layoutSessionData && (layoutSessionData.active && !isAdmin)"></mat-divider> -->
                        <button *ngIf="templateObject.data._id && !backLayer" mat-icon-button
                            (click)="setTemplateActionReturn('select')"
                            matTooltip="{{'Select Layout Template' | translate}}" matTooltipClass="tooltip-red"
                            [hidden]="layoutSessionData && (layoutSessionData.active && !isAdmin)">
                            <!-- <mat-icon>folder_special</mat-icon> -->
                            {{'Templates' | translate}}
                        </button>
                        <!-- <mat-divider [vertical]="true"
                            [hidden]="layoutSessionData && (layoutSessionData.active && !isAdmin)"></mat-divider> -->
                        <button mat-icon-button [matMenuTriggerFor]="resetoption" matTooltip="{{'Reset' | translate}}"
                            matTooltipClass="tooltip-red"
                            *ngIf="layoutSessionData && (!layoutSessionData.active || (layoutSessionData.active && isAdmin))">
                            <!-- <mat-icon>arrow_drop_down</mat-icon> -->
                            {{'Reset' | translate}}<mat-icon style="width: 10px;">arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #resetoption="matMenu">
                            <button [hidden]="!backLayer" mat-menu-item
                                (click)="setTemplateActionReturn('room-reset')"><span>Discard latest
                                    changes</span></button>
                            <button [hidden]="backLayer" mat-menu-item
                                (click)="setTemplateActionReturn('reset')"><span>Discard latest
                                    changes</span></button>
                            <button [hidden]="backLayer" mat-menu-item
                                (click)="setTemplateActionReturn('default')"><span>Reset to default
                                    settings</span></button>
                            <!-- <button mat-menu-item (click)="resetTutorial()"><span>Start Tutorial</span></button> -->
                        </mat-menu>
                        <!-- <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon title="More Actions">more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="setTemplateActionReturn('reset')" >{{'Reset' | translate}}</button>
                <button mat-menu-item (click)="setTemplateActionReturn('select')" >{{'Select Template' | translate}}</button>
            </mat-menu> -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="sessionData && showStartEndSession && !sessionData.isKeepActive && !viewAsAttendee">
            <button mat-raised-button class="start-end-session blue-shadow" [disableRipple]="true" [routerLink]
                style="background-color: red;" (click)="endSession()" *ngIf="sessionData.active">
                <span class="lectureendSession">
                    {{ 'End' | translate }}<br>{{ 'Session' | translate }}</span>
            </button>
            <button mat-raised-button class="start-end-session red-shadow" [disableRipple]="true" [routerLink]
                style="background-color: #1d74ff;" (click)="startSession()" *ngIf="!sessionData.active">
                <span class="lectureendSession">
                    {{ 'Start' | translate }}<br>{{ 'Session' | translate }}</span>
            </button>
        </ng-container>
        <!-- <div class="icon-container" *ngIf="!goodConnection && sessionData">
            <button mat-icon-button style="color: orange;cursor: default;">
                <mat-icon>warning</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{ 'Poor Connection' | translate }}
            </div>
        </div>
        <div class="icon-container blink_me" *ngIf="newAppVersion"
            title="{{'Update to the latest version' | translate}}">
            <button mat-icon-button (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Update' | translate}}
            </div>
        </div>
         <div class="icon-container"
            *ngIf="subscriptionOrgObject && subscriptionOrgObject.published === false"
            title="{{'Publish Event' | translate}}">
            <button mat-icon-button (click)="publishIt()">
                <mat-icon>credit_card</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Publish Event' | translate}}
            </div>
        </div> -->
        <ng-container *ngIf="!showTemplateActions">
            <div class="icon-container ga-donate" *ngIf="donateLink" (click)="openDonate()">
                <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.donate' | translate}}">
                    <!-- <mat-icon>group_work
                </mat-icon> -->
                    <img src="assets/vertical/{{orgType}}/images/donate.png"
                        class="circle z-depth-2 responsive-img activator" alt="Donate" style="height: 25px;">
                </button>
                <div class="icon-tooltip">
                    {{'Donate' | translate}}
                </div>
            </div>
            <div [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && settingOrgObject.web.topBar.disableAgenda"
                class="icon-container ga-myagenda" [class.active-icon]="isMyAgendaShowing"
                [matMenuTriggerFor]="menuTeleport">
                <div (click)="openTeleport()">
                    <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                        matTooltip="{{'hints.myAgenda' | translate}}">
                        <!-- <mat-icon>favorite_border</mat-icon> -->
                        <img src="assets/images/star-dark.png" class="circle z-depth-2 responsive-img activator"
                            alt="Favorites" style="height: 25px;">
                    </button>
                    <div class="icon-tooltip">
                        {{'My Agenda' | translate}}
                    </div>
                </div>
                <mat-menu #menuTeleport="matMenu" (closed)="closeMyAgenda()">
                    <div *ngIf="loadingSessions" class="loadingFaveLiveSession">
                        <mat-spinner color="primary" diameter="50"></mat-spinner>
                    </div>
                    <div *ngIf="!loadingSessions && sessionsFaveLive.length === 0" class="noFaveLiveSession">
                        {{'None of your favorite sessions is currently live.' | translate}}
                    </div>
                    <div *ngIf="!loadingSessions && sessionsFaveLive.length > 0" style="max-height: 50vh;">
                        <button *ngFor="let sn of sessionsFaveLive" mat-menu-item (click)="openSession(sn)"
                            title="{{sn.name}}">
                            <span class="liveLogo"><img [class.hideIt]="!sn.active" src="/assets/images/StellarLive.png"
                                    title="{{'Live Now' | translate}}" />
                            </span>
                            <span class="liveLogo"><img [class.hideIt]="!(!sn.active && sn.recordAvailable)"
                                    src="/assets/images/StellarReplay.png" title="{{'Watch Again' | translate}}" />
                            </span>
                            <span class="liveLogoText">
                                {{sn.name | truncate : ['20','...']}}
                            </span>
                        </button>
                    </div>
                </mat-menu>
            </div>
            <div *ngIf="!settingOrgObject?.web?.topBar?.disableSchedule || !settingOrgObject?.web?.topBar?.disableSpeakers || (organization && organization.resourcesCount > 0 && !settingOrgObject?.web?.topBar?.disableResource) || !settingOrgObject?.web?.topBar?.disablePoster || (hasListingDirectory && !lockSite && !settingOrgObject?.web?.topBar?.disableDirectory)"
                [matMenuTriggerFor]="programMenu" class="icon-container"
                [class.active-icon]="programMenuTrigger.menuOpen" #programMenuTrigger="matMenuTrigger">
                <!-- <div *ngIf="pageScope.isHintOn" class="hint" style="right: -5px;top: -7px;">
                        <span>?</span>
                    </div> -->
                <button mat-icon-button [title]="!pageScope.isHintOn ? ('Agenda' | translate) : ''"
                    matTooltipClass="tooltip-menu" matTooltip="{{'hints.agenda' | translate}}">
                    <mat-icon>view_list
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Program' | translate}}
                </div>
                <mat-menu #programMenu="matMenu">
                    <button mat-menu-item [routerLink] (click)="openAgenda()" class="ga-program sub-menu"
                        *ngIf="!settingOrgObject?.web?.topBar?.disableSchedule">
                        <mat-icon>calendar_today</mat-icon>{{'Schedule' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openAgenda('speaker')" class="ga-speakers sub-menu"
                        *ngIf="!settingOrgObject?.web?.topBar?.disableSpeakers">
                        <mat-icon>supervisor_account</mat-icon> {{'Leads' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openResources()"
                        *ngIf="organization && organization.resourcesCount > 0 && !settingOrgObject?.web?.topBar?.disableResource"
                        class="ga-resources sub-menu">
                        <mat-icon>library_books</mat-icon> {{'Resources' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openPosters()"
                        *ngIf="organization && organization.posterCount > 0 && !settingOrgObject?.web?.topBar?.disablePoster"
                        class="ga-posters sub-menu">
                        <mat-icon>perm_media</mat-icon> {{'Posters' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="gotoBook()"
                        *ngIf="hasListingDirectory && !lockSite && !settingOrgObject?.web?.topBar?.disableDirectory"
                        class="ga-stellarconnect sub-menu">
                        <mat-icon>recent_actors</mat-icon> {{'Directory' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="viewCalendar()"
                        *ngIf="settingOrgObject?.web?.page?.enableScheduler"
                        class="ga-stellarconnect">
                        <mat-icon>calendar_month</mat-icon> {{'Calender' | translate}}
                    </button>
                </mat-menu>
            </div>
            <div [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar  && settingOrgObject.web.topBar.disableExhibitor"
                class="icon-container ga-sponsors"
                [class.active-icon]="route.snapshot['_routerState'].url === '/sponsors'"
                *ngIf="organization && organization.exhibitorsCount > 0" (click)="openSponsors()">
                <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.exhibitors' | translate}}">
                    <mat-icon>group_work
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Exhibitors' | translate}}
                </div>
            </div>
            <div class="icon-container ga-stellarcomm" [class.active-icon]="chatService.isGlobalChatShowing"
                *ngIf="!lockSite && (selectedUser['isTextChat'] || selectedUser['isVideoChat']) && selectedUser['listedInTheDirectory'] !== 'no' && ablyService.ably && ablyService.ably.connection.state === 'connected'"
                [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && !(!settingOrgObject.web.topBar.disableCommunicate || !settingOrgObject.web.topBar.disableVideoCommunicate)">
                <div class="has-hint">
                    <!-- <div *ngIf="pageScope.isHintOn" class="hint" style="right:2px; top: -7px;">
                        <span>?</span>
                    </div> -->
                    <button mat-icon-button (click)="openGlobalChat()"
                        [title]="!pageScope.isHintOn ? ('Chat and Video with Attendees' | translate) : ''"
                        [class.clamp]="selectedUser['isTextChat'] && selectedUser['isVideoChat'] && !settingOrgObject?.web?.topBar?.disableCommunicate && !settingOrgObject?.web?.topBar?.disableVideoCommunicate"
                        matTooltipClass="tooltip-menu" matTooltip="{{'hints.stellarComm' | translate}}"
                        class="ga-stellarcomm">
                        <mat-icon
                            *ngIf="selectedUser['isTextChat'] && !settingOrgObject?.web?.topBar?.disableCommunicate"
                            [ngStyle]="{'color': highlightDirectory && !selectedUser['chat_dnd'] ? 'orange':''}">
                            chat</mat-icon>
                        <mat-icon
                            *ngIf="selectedUser['isVideoChat'] && !settingOrgObject?.web?.topBar?.disableVideoCommunicate"
                            [ngStyle]="{'color': highlightDirectory && !selectedUser['chat_dnd'] ? 'orange':''}">
                            voice_chat
                        </mat-icon>
                    </button>
                </div>
                <div class="icon-tooltip">
                    {{'Communicate' | translate}}
                </div>
            </div>
        </ng-container>
        <div class="icon-container" *ngIf="enableTranslation" [matMenuTriggerFor]="languageselect"
            [class.active-icon]="languageMenuTrigger.menuOpen" #languageMenuTrigger="matMenuTrigger">
            <button class="languageSelection" mat-icon-button [disableRipple]="true" title="{{'Language' | translate}}">
                <mat-icon>language</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Language' | translate}}
            </div>
            <mat-menu #languageselect="matMenu">
                <button mat-menu-item (click)="setLanguage('en')" [disabled]="language === 'en'"><span
                        class="primaryColor">
                        {{'English' | translate}}</span></button>
                <button mat-menu-item (click)="setLanguage('fr')" [disabled]="language === 'fr'"><span
                        class="primaryColor">{{'French' | translate}}</span></button>
            </mat-menu>
        </div>
        <div class="icon-container" [class.active-icon]="isRoomListShowing" *ngIf="!showTemplateActions"
            [matMenuTriggerFor]="menuRoomList">
            <div (click)="openRoomList()" class="ga-rooms">
                <button mat-icon-button [routerLink] [disableRipple]="true"
                    [title]="!pageScope.isHintOn ? ('rooms' | translate) : ''" matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.rooms' | translate}}">
                    <mat-icon>door_sliding</mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Rooms' | translate}}
                </div>
            </div>
            <mat-menu #menuRoomList="matMenu" (closed)="closeRoomList()" xPosition="before">
                <div *ngIf="loadingRoomsList" class="loadingFaveLiveSession">
                    <mat-spinner color="primary" diameter="50"></mat-spinner>
                </div>
                <div *ngIf="!loadingRoomsList && roomsList.length === 0" class="noFaveLiveSession">
                    {{'None of your rooms is currently listed.' | translate}}
                </div>
                <div *ngIf="!loadingRoomsList && roomsList.length > 0" style="max-height: 50vh;">
                    <button *ngFor="let sn of roomsList" mat-menu-item (click)="openRoomFromList(sn)"
                        title="{{sn.name}}">
                        <span class="liveLogoText">
                            {{sn.name | truncate : ['20','...']}}
                        </span>
                    </button>
                </div>
            </mat-menu>
        </div>
        <div class="icon-container home-main-icon ga-lobby" (click)="goHome()">
            <button mat-icon-button [disableRipple]="true" [routerLink] matTooltipClass="tooltip-menu"
                matTooltip="{{'hints.goToLobby' | translate}}">
                <mat-icon style="font-size: 26pt; color: #1d74ff; overflow: visible;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <div class="icon-container ga-profile" [matMenuTriggerFor]="profileMenu" #profileMenuTrigger="matMenuTrigger"
            [class.active-icon]="profileMenuTrigger.menuOpen || route.snapshot['_routerState'].url === '/profile'">
            <!-- <div *ngIf="pageScope.isHintOn" class="hint" style="right: -2px; top: -7px;">
                    <span>?</span>
                </div> -->
            <button mat-icon-button [routerLink]
                [title]="!pageScope.isHintOn ? selectedUser.firstName + ' ' + selectedUser.lastName : ''">
                <!-- <mat-icon>account_circle</mat-icon> -->
                <span style="font-weight: bold">{{selectedUser.firstName | myfirstLetterPipe}}
                    {{selectedUser.lastName | myfirstLetterPipe}}</span>
            </button>
            <div class="icon-tooltip">
                {{'Menu' | translate}}
            </div>
            <mat-menu #profileMenu="matMenu" yPosition="below">
                <!-- <button mat-menu-item (click)="viewProfile()">
                    <span>{{ 'Pop Profile' | translate }}</span>
                </button> -->
                <button mat-menu-item (click)="openProfile()" class="sub-menu">
                    <mat-icon>person</mat-icon>{{ 'My Profile' | translate }}
                </button>
                <button mat-menu-item [routerLink] (click)="goToHelpDesk()" class="ga-helpdesk sub-menu"
                    [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && settingOrgObject.web.topBar.disableHelpDesk">
                    <mat-icon>help_center
                    </mat-icon> {{'Help Desk' | translate}}
                </button>
                <button mat-menu-item (click)="forceRefresh()" *ngIf="isSuperAdmin" class="sub-menu">
                    <mat-icon>update</mat-icon> {{'Force Refresh' | translate}}
                </button>
                <ng-container
                    *ngIf="!showTemplateActions && !viewAsAttendee && (currentSessionUserRole === 'moderator' || currentSessionUserRole === 'lead' || currentSessionUserRole === 'guest' || advancedView || isAdmin) && sessionData">
                    <button mat-menu-item [matMenuTriggerFor]="menuLanguages" #menuTrigger="matMenuTrigger"
                        (menuOpened)="loadTranslationLanguages()" class="sub-menu">
                        <mat-icon>launch</mat-icon> {{'Recording Tab' | translate}}
                    </button>
                    <mat-menu #menuLanguages="matMenu">
                        <button mat-menu-item (click)="goToAttendeeView($event, translator, profileMenuTrigger)"
                            *ngFor="let translator of availableLanguages" class="sub-menu">
                            <span>{{translator.language | translate}}</span>
                        </button>
                    </mat-menu>
                </ng-container>
                <button *ngIf="isBusy" mat-menu-item class="sub-menu">
                    <mat-icon>do_disturb_on</mat-icon>
                    <span style="color: red;"
                        [title]="'You are currently inside a session and cannot make/receive calls.' | translate">
                        {{'Busy' | translate}}
                    </span>
                </button>
                <button *ngIf="!isBusy"
                    [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && !(!settingOrgObject.web.topBar.disableCommunicate || !settingOrgObject.web.topBar.disableVideoCommunicate)"
                    mat-menu-item (click)="toggleDnD()">
                    <mat-icon *ngIf="selectedUser['chat_dnd']">do_disturb_on</mat-icon>
                    <mat-icon *ngIf="!selectedUser['chat_dnd']">do_disturb_off</mat-icon>
                    <span *ngIf="!selectedUser['chat_dnd']">
                        {{'Do not Disturb: Off' | translate}}
                    </span>
                    <span *ngIf="selectedUser['chat_dnd']">
                        {{"Do not Disturb: On" | translate}}
                    </span>
                </button>
                <button mat-menu-item (click)="onLoggedout()" class="sub-menu">
                    <mat-icon>exit_to_app</mat-icon>{{'Log Out' | translate}}
                </button>
            </mat-menu>
        </div>
    </ng-template>
    <ng-template #rightMobileIcons>
        <ng-container *ngIf="sessionData && showStartEndSession && !sessionData.isKeepActive && !viewAsAttendee">
            <button mat-raised-button class="start-end-session blue-shadow" [disableRipple]="true" [routerLink]
                style="background-color: red;" (click)="endSession()" *ngIf="sessionData.active">
                <span class="lectureendSession">
                    {{ 'End' | translate }}<br>{{ 'Session' | translate }}</span>
            </button>
            <button mat-raised-button class="start-end-session red-shadow" [disableRipple]="true" [routerLink]
                style="background-color: #1d74ff;" (click)="startSession()" *ngIf="!sessionData.active">
                <span class="lectureendSession">
                    {{ 'Start' | translate }}<br>{{ 'Session' | translate }}</span>
            </button>
        </ng-container>
        <div class="icon-container" *ngIf="!goodConnection && sessionData">
            <button mat-icon-button style="color: orange;cursor: default;">
                <mat-icon>warning</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{ 'Poor Connection' | translate }}
            </div>
        </div>
        <div class="icon-container blink_me" *ngIf="newAppVersion"
            title="{{'Update to the latest version' | translate}}">
            <button mat-icon-button (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Update' | translate}}
            </div>
        </div>
        <!-- <div class="icon-container"
            *ngIf="subscriptionOrgObject && subscriptionOrgObject.published === false"
            title="{{'Publish Event' | translate}}">
            <button mat-icon-button (click)="publishIt()">
                <mat-icon>credit_card</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Publish Event' | translate}}
            </div>
        </div> -->
        <ng-container *ngIf="!showTemplateActions">
            <div class="icon-container ga-donate" *ngIf="donateLink" (click)="openDonate()">
                <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.donate' | translate}}">
                    <!-- <mat-icon>group_work
                </mat-icon> -->
                    <img src="assets/vertical/{{orgType}}/images/donate.png"
                        class="circle z-depth-2 responsive-img activator" alt="Donate" style="height: 25px;">
                </button>
                <div class="icon-tooltip">
                    {{'Donate' | translate}}
                </div>
            </div>
            <div [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && settingOrgObject.web.topBar.disableAgenda"
                class="icon-container ga-myagenda" [matMenuTriggerFor]="menuTeleport">
                <div (click)="openTeleport()">
                    <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                        matTooltip="{{'hints.myAgenda' | translate}}">
                        <!-- <mat-icon>favorite_border</mat-icon> -->
                        <img src="assets/images/star-dark.png" class="circle z-depth-2 responsive-img activator"
                            alt="Favorites" style="height: 25px;">
                    </button>
                    <div class="icon-tooltip">
                        {{'My Agenda' | translate}}
                    </div>
                </div>
                <mat-menu #menuTeleport="matMenu" (closed)="closeMyAgenda()">
                    <div *ngIf="loadingSessions" class="loadingFaveLiveSession">
                        <mat-spinner color="primary" diameter="50"></mat-spinner>
                    </div>
                    <div *ngIf="!loadingSessions && sessionsFaveLive.length === 0" class="noFaveLiveSession">
                        {{'None of your favorite sessions is currently live.' | translate}}
                    </div>
                    <div *ngIf="!loadingSessions && sessionsFaveLive.length > 0" style="max-height: 50vh;">
                        <button *ngFor="let sn of sessionsFaveLive" mat-menu-item (click)="openSession(sn)"
                            title="{{sn.name}}">
                            <span class="liveLogo"><img [class.hideIt]="!sn.active" src="/assets/images/StellarLive.png"
                                    title="{{'Live Now' | translate}}" />
                            </span>
                            <span class="liveLogo"><img [class.hideIt]="!(!sn.active && sn.recordAvailable)"
                                    src="/assets/images/StellarReplay.png" title="{{'Watch Again' | translate}}" />
                            </span>
                            <span class="liveLogoText">
                                {{sn.name | truncate : ['20','...']}}
                            </span>
                        </button>
                    </div>
                </mat-menu>
            </div>
            <div *ngIf="!settingOrgObject?.web?.topBar?.disableSchedule || !settingOrgObject?.web?.topBar?.disableSpeakers || (organization && organization.resourcesCount > 0 && !settingOrgObject?.web?.topBar?.disableResource) || !settingOrgObject?.web?.topBar?.disablePoster || (hasListingDirectory && !lockSite && !settingOrgObject?.web?.topBar?.disableDirectory)"
                [matMenuTriggerFor]="programMenu" class="icon-container" #programMenuTrigger="matMenuTrigger">
                <!-- <div *ngIf="pageScope.isHintOn" class="hint" style="right: -5px;top: -7px;">
                        <span>?</span>
                    </div> -->
                <button mat-icon-button [title]="!pageScope.isHintOn ? ('Agenda' | translate) : ''"
                    matTooltipClass="tooltip-menu" matTooltip="{{'hints.agenda' | translate}}">
                    <mat-icon>view_list
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Program' | translate}}
                </div>
                <mat-menu #programMenu="matMenu">
                    <button mat-menu-item [routerLink] (click)="openAgenda()" class="ga-program sub-menu"
                        *ngIf="!settingOrgObject?.web?.topBar?.disableSchedule">
                        <mat-icon>calendar_today</mat-icon>{{'Schedule' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openAgenda('speaker')" class="ga-speakers sub-menu"
                        *ngIf="!settingOrgObject?.web?.topBar?.disableSpeakers">
                        <mat-icon>supervisor_account</mat-icon> {{'Leads' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openResources()"
                        *ngIf="organization && organization.resourcesCount > 0 && !settingOrgObject?.web?.topBar?.disableResource"
                        class="ga-resources sub-menu">
                        <mat-icon>library_books</mat-icon> {{'Resources' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="openPosters()"
                        *ngIf="organization && organization.posterCount > 0 && !settingOrgObject?.web?.topBar?.disablePoster"
                        class="ga-posters sub-menu">
                        <mat-icon>perm_media</mat-icon> {{'Posters' | translate}}
                    </button>
                    <button mat-menu-item [routerLink] (click)="gotoBook()"
                        *ngIf="hasListingDirectory && !lockSite && !settingOrgObject?.web?.topBar?.disableDirectory"
                        class="ga-stellarconnect sub-menu">
                        <mat-icon>recent_actors</mat-icon> {{'Directory' | translate}}
                    </button>
                </mat-menu>
            </div>
            <div [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar  && settingOrgObject.web.topBar.disableExhibitor"
                class="icon-container ga-sponsors" *ngIf="organization && organization.exhibitorsCount > 0"
                (click)="openSponsors()">
                <button mat-icon-button [routerLink] matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.exhibitors' | translate}}">
                    <mat-icon>group_work
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Exhibitors' | translate}}
                </div>
            </div>
            <div class="icon-container ga-stellarcomm"
                *ngIf="!lockSite && (selectedUser['isTextChat'] || selectedUser['isVideoChat']) && selectedUser['listedInTheDirectory'] !== 'no' && ablyService.ably && ablyService.ably.connection.state === 'connected'"
                [hidden]="settingOrgObject && settingOrgObject.web && settingOrgObject.web.topBar && settingOrgObject.web.topBar.disableCommunicate"
                (click)="openGlobalChat()">
                <!-- <div *ngIf="pageScope.isHintOn" class="hint" style="right:2px; top: -7px;">
                        <span>?</span>
                    </div> -->
                <button mat-icon-button
                    [title]="!pageScope.isHintOn ? ('Chat and Video with Attendees' | translate) : ''"
                    [class.clamp]="selectedUser['isTextChat'] && selectedUser['isVideoChat']"
                    matTooltipClass="tooltip-menu" matTooltip="{{'hints.stellarComm' | translate}}">
                    <mat-icon *ngIf="selectedUser['isTextChat']"
                        [ngStyle]="{'color': highlightDirectory && !selectedUser['chat_dnd'] ? 'orange':''}">
                        chat</mat-icon>
                    <mat-icon *ngIf="selectedUser['isVideoChat']"
                        [ngStyle]="{'color': highlightDirectory && !selectedUser['chat_dnd'] ? 'orange':''}">
                        voice_chat
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Communicate' | translate}}
                </div>
            </div>
        </ng-container>
        <div class="icon-container" *ngIf="enableTranslation" [matMenuTriggerFor]="languageselect"
            #languageMenuTrigger="matMenuTrigger">
            <button class="languageSelection" mat-icon-button [disableRipple]="true" title="{{'Language' | translate}}">
                <mat-icon>language</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Language' | translate}}
            </div>
            <mat-menu #languageselect="matMenu">
                <button mat-menu-item (click)="setLanguage('en')" [disabled]="language === 'en'"><span
                        class="primaryColor">
                        {{'English' | translate}}</span></button>
                <button mat-menu-item (click)="setLanguage('fr')" [disabled]="language === 'fr'"><span
                        class="primaryColor">{{'French' | translate}}</span></button>
            </mat-menu>
        </div>
        <div class="icon-container" *ngIf="!showTemplateActions" [matMenuTriggerFor]="menuRoomList">
            <div (click)="openRoomList()" class="ga-rooms">
                <button mat-icon-button [routerLink] [disableRipple]="true"
                    [title]="!pageScope.isHintOn ? ('rooms' | translate) : ''" matTooltipClass="tooltip-menu"
                    matTooltip="{{'hints.rooms' | translate}}">
                    <mat-icon>door_sliding</mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Rooms' | translate}}
                </div>
            </div>
            <mat-menu #menuRoomList="matMenu" (closed)="closeRoomList()" xPosition="before">
                <div *ngIf="loadingRoomsList" class="loadingFaveLiveSession">
                    <mat-spinner color="primary" diameter="50"></mat-spinner>
                </div>
                <div *ngIf="!loadingRoomsList && roomsList.length === 0" class="noFaveLiveSession">
                    {{'None of your rooms is currently listed.' | translate}}
                </div>
                <div *ngIf="!loadingRoomsList && roomsList.length > 0" style="max-height: 50vh;">
                    <button *ngFor="let sn of roomsList" mat-menu-item (click)="openRoomFromList(sn)"
                        title="{{sn.name}}">
                        <span class="liveLogoText">
                            {{sn.name | truncate : ['20','...']}}
                        </span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-template>
</mat-toolbar>
<custom-dynamic-dialog #dialogSchedule name="dialogSchedule" [keyName]="'dialogSchedule'" width="100%"
    style="width: 100%;">
    <div class="smallbuttons">
        <button mat-icon-button (click)="dialogSchedule.close()" color="primary">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button (click)="openAgenda(tabSelected, true)" color="primary">
            <mat-icon>open_in_full</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="popupTitle">
            Schedule
        </div>
        <app-agenda-dialog-page [tabSelected]="tabSelected"></app-agenda-dialog-page>
    </mat-dialog-content>

    <!-- <div class="buttons">
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" style="width: 100%;">
            <div fxFlex>
                <button (click)="dialogSchedule.close()" mat-raised-button color="primary" style="float:right">{{'Close'
                    |
                    translate}}</button>
            </div>
        </div>
    </div> -->
</custom-dynamic-dialog>
<mat-toolbar *ngIf="selectedUser && is10hSubscription" color="warning" fxFlexLayout="column" class="is10hSubscription"
    [class.is10hSubscription-mobile]="isMobile" [class.is10hSubscription-mobile-open]="isTopOpen">
    <div>You are running out of streaming hours. Please purchase more StellarLive
        streaming hours to avoid service
        disruption.</div>
</mat-toolbar>