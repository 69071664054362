import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-image-gallery-container-dialog-modal',
	templateUrl: './image-gallery-container-dialog.component.html',
	styleUrls: ['./image-gallery-container-dialog.component.scss']
})
export class ImageGalleryContainerDialogComponent implements OnInit {
	public itemName = '';
	public isMobile: boolean = false;
	constructor(public dialogRef: MatDialogRef<ImageGalleryContainerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, public deviceService: DeviceDetectorService) {
		this.isMobile = this.deviceService.isMobile();
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	selectItemCheck(data) {
		this.dialogRef.close(data);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
