import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService, FeaturesPermissionService } from '../../../shared/services';
import { DefaultSettings } from '../../../shared/components/layout-components';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AblyService } from 'src/app/shared/services/ably.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  filters: any;
  useOrg: boolean;
  // type: string;
  columnData: string;
  confirmData: any;
}


@Component({
  selector: 'session-users-assign-dialog',
  templateUrl: './session-users-assign-dialog.component.html',
  styleUrls: ['./session-users-assign-dialog.component.scss']
})
export class SessionUsersAssignDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: Subscription = undefined;
  public errorMessage: string = '';
  public activeLink: string = 'lead';
  public loading: boolean = false;
  public haveChanges: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public canSearch: boolean = true;
  public itemIndex: number = 0;
  public itemPageNumber: number = 0;
  public itempageSize: number = 5;
  public optionData: any = [];
  public selectedDataObjectOriginal: any = undefined;
  public selectedDataObject: any = undefined;
  public selectedDataObjectFilter: any = undefined;
  public excludeList: any[];
  public searchVal: string = '';
  public searchSelectedVal: string = '';
  public tableDataTotal: number = 0;
  public peopleType: any = DefaultSettings.peopleType;
  public peopleTypeKeys: any[] = DefaultSettings.peopleTypeKeys;
  public interpreterType: any = DefaultSettings.interpreterType;
  public interpreterTypeKeys: any = DefaultSettings.interpreterTypeKeys;
  public subscriptionOrgObject = undefined;
  public subscriptionUpgradeStatus = DefaultSettings.SubscriptionUpgradeMessage;
  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchSelectedInput') searchSelectedInput: ElementRef;
  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  constructor(
    private requestService: RequestService, private router: Router,
    private activatedRoute: ActivatedRoute, private ablyService: AblyService,
    private layoutUtilsService: LayoutUtilsService, private featuresPermissionService: FeaturesPermissionService,
    public dialog: MatDialog, private loaderService: LoaderService,
    public dialogRef: MatDialogRef<SessionUsersAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService) {
    console.log('DialogData', data);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.featuresPermissionService.currentSubscriptionSubject.subscribe((data) => {
        if (data) {
          this.subscriptionOrgObject = data;

        }
        this.setupData();
      })
    );

  }
  setupData() {
    let activeLink = this.data.columnData;
    if (this.data.hasOwnProperty('columnData')) {
      if (this.data.columnData === 'host') {
        activeLink = 'lead';
      }
    }
    if (this.data.data.streamMode === 'meeting') {
      activeLink = 'attendee';
      this.data.data.users.map((usr) => {
        usr.type = 'attendee';
        return usr;
      });
    }
    this.activeLink = activeLink;
    this.setUpTabData();
  }
  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.loadDataSearch();
          }
        } catch (e) { console.log(e); }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSelectedSubscription = fromEvent(this.searchSelectedInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termSelectedConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator2.pageIndex = 0;
            this.setUpFilter(this.selectedDataObject);
          }
        } catch (e) { console.log(e); }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSelectedSubscription);

    const paginatorSelectedSubscriptions = merge(this.paginator2.page).pipe(
      tap(() => {
        this.itemPageNumber = this.paginator2.pageIndex;
        this.itempageSize = this.paginator2.pageSize;
        this.setUpFilter(this.selectedDataObject);
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  setActiveLink(ppl) {
    this.searchSelectedInput.nativeElement.value = '';
    this.searchSelectedVal = '';
    this.itemPageNumber = 0;
    this.setUpFilter(this.selectedDataObject);
    this.activeLink = ppl;
    // this.setUpTabData();
  }
  public setUpTabData() {
    let selectedDataObject: any = {};
    for (let ppl of this.peopleTypeKeys) {
      selectedDataObject[ppl] = [];
      let dataList: any[] = [];
      let dataListType = this.getUsersByType(ppl);
      for (let dt of dataListType) {
        let hst = false;
        if (dt.host) {
          hst = dt.host;
        }
        let showInsights = false;
        if (dt.showInsights) {
          showInsights = dt.showInsights;
        }
        let sessionAtAnyTime = false;
        if (dt.sessionAtAnyTime) {
          sessionAtAnyTime = dt.sessionAtAnyTime;
        }
        let viewPublicChat = false;
        if (dt.viewPublicChat) {
          viewPublicChat = dt.viewPublicChat;
        }
        let allowStarQuestions = false;
        if (dt.allowStarQuestions) {
          allowStarQuestions = dt.allowStarQuestions;
        }
        let allowPushQuestions = false;
        if (dt.allowPushQuestions) {
          allowPushQuestions = dt.allowPushQuestions;
        }
        let language = dt.language;
        if (!language || language === '') {
          language = 'en';
        }
        dataList.push({ _id: dt._id, text: dt.name, host: hst, viewPublicChat: viewPublicChat, allowStarQuestions: allowStarQuestions, allowPushQuestions: allowPushQuestions, language: language, email: dt.email, showInsights: showInsights, sessionAtAnyTime: sessionAtAnyTime });
      }
      selectedDataObject[ppl] = dataList;
    }
    this.selectedDataObject = selectedDataObject;
    this.setUpFilter(selectedDataObject);
    this.selectedDataObjectOriginal = JSON.parse(JSON.stringify(selectedDataObject));
    this.loadDataSearch();
  }
  setUpFilter(selectedDataObject) {
    let selectedDataObjectFilter = JSON.parse(JSON.stringify(selectedDataObject));
    let termConfiguration = this.termSelectedConfiguration();
    for (let ppl of this.peopleTypeKeys) {
      selectedDataObjectFilter[ppl] = selectedDataObjectFilter[ppl].sort((a, b) => { return a.text.localeCompare(b.text) });
      if (termConfiguration !== '') {
        selectedDataObjectFilter[ppl] = selectedDataObjectFilter[ppl].filter(el => (el.text && el.text.toLowerCase().indexOf(termConfiguration.toLowerCase()) !== -1) || (el.email && el.email.toLowerCase().indexOf(termConfiguration.toLowerCase()) !== -1));
      }
    }
    this.selectedDataObjectFilter = selectedDataObjectFilter;
  }
  getExcludeList() {
    let excludeList = [];
    for (let ppl of this.peopleTypeKeys) {
      for (let dt of this.selectedDataObject[ppl]) {
        excludeList.push(dt._id);
      }
    }
    return excludeList;
  }
  public setHost(id, index, val) {
    let firstObj = this.activeLink;
    let secondObj = this.activeLink;
    if (this.activeLink === 'lead') {
      secondObj = 'moderator';
    } else if (this.activeLink === 'moderator') {
      secondObj = 'lead';
    }
    let selectedData = JSON.parse(JSON.stringify(this.selectedDataObject[firstObj]));
    selectedData = selectedData.map((itm) => {
      if (val) {
        if (itm._id === id) {
          itm['host'] = true;
        } else {
          itm['host'] = false;
        }
      } else {
        if (itm._id === id) {
          itm['host'] = false;
        }
      }
      return itm;
    });

    this.selectedDataObject[firstObj] = selectedData;

    let selectedDataFilter = JSON.parse(JSON.stringify(this.selectedDataObjectFilter[firstObj]));
    selectedDataFilter = selectedDataFilter.map((itm) => {
      if (val) {
        if (itm._id === id) {
          itm['host'] = true;
        } else {
          itm['host'] = false;
        }
      } else {
        if (itm._id === id) {
          itm['host'] = false;
        }
      }
      return itm;
    });

    this.selectedDataObjectFilter[firstObj] = selectedDataFilter;
    if (this.data.data.streamMode !== 'meeting') {
      let selectedOtherData = JSON.parse(JSON.stringify(this.selectedDataObject[secondObj]));
      selectedOtherData = selectedOtherData.map((itm) => {
        itm['host'] = false;
        return itm;
      });
      this.selectedDataObject[secondObj] = selectedOtherData;
    }
  }
  public setLanguage(id, index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.selectedDataObject[this.activeLink]));
    selectedData = selectedData.map((itm) => {
      if (itm._id === id) {
        itm['language'] = val;
      }
      return itm;
    });
    this.selectedDataObject[this.activeLink] = selectedData;
  }
  public setBooleanAttribute(field, id, event) {
    let selectedData = JSON.parse(JSON.stringify(this.selectedDataObject[this.activeLink]));
    selectedData = selectedData.map((itm) => {
      if (itm._id === id) {
        itm[field] = event;
      }
      return itm;
    });
    this.selectedDataObject[this.activeLink] = selectedData;
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public loadDataSearch() {
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }

      if (this.data.useOrg) {// || this.data.type) {
        if (!filterConfiguration) {
          filterConfiguration = { '$and': [] };
        }
        if (this.data.useOrg)
          filterConfiguration['$and'].push({ 'organizationId': { '$eq': this.requestService.orgId } });
        // if (this.data.type)
        //   filterConfiguration['$and'].push({ 'type': { '$eq': this.data.type } });
      }
      let excludeList = JSON.parse(JSON.stringify(this.getExcludeList()));
      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiCallSubscription = this.requestService.getDataListSummary(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any[] = data.results;
          let idx = 0;
          for (let dt of dataReturned) {
            dataReturned[idx]['host'] = false;
            dataReturned[idx]['allowStarQuestions'] = false;
            dataReturned[idx]['viewPublicChat'] = false;
            dataReturned[idx]['allowPushQuestions'] = false;
            dataReturned[idx]['showInsights'] = false;
            dataReturned[idx]['sessionAtAnyTime'] = false;
            dataReturned[idx]['language'] = 'en';
            idx++;
          }
          //console.log('dataReturned', dataReturned);
          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            if (this.pageNumber > 1 && ((this.pageNumber - 1) * this.pageSize) >= data.pagination.total) {
              this.pageNumber = this.pageNumber - 1;
              this.loading = false;
              this.loadDataSearch();
              return;
            }
            this.paginatorTotal.next(data.pagination.total);
          }
          this.optionData = dataReturned;
          // .filter((item) => {
          // 	return !this.requestService.checkListContains(this.selectedData, item._id);
          // });
        } else {
          this.optionData = [];
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  validateData(): boolean {
    if (this.data.data.streamMode === 'meeting') {
      // for meeting host
      let selectedHosts = this.selectedDataObject['attendee'].filter((itm) => {
        if (itm.host) {
          return true;
        }
        return false;
      });
      if (selectedHosts.length === 0) {
        this.layoutUtilsService.showNotification(this.translate.instant('You need to assign a host'), 'Dismiss');
        return false;
      }
      if (!this.data.data.opentoall) {
        if (this.selectedDataObject['attendee'].length > this.data.data.maximumAttendees) {
          this.layoutUtilsService.showNotification(this.translate.instant('You can only add up to ') + this.data.data.maximumAttendees + this.translate.instant(' attendees to a meeting'), this.translate.instant('Dismiss'));
          return false;
        }
      }
    } else {

      // for interpreter
      // let selectedLang = [];
      if (this.selectedDataObject['interpreter'].length > 0 && this.data.data.streamMode === 'cloudlive') {
        this.layoutUtilsService.showNotification(this.translate.instant('Interpreters are not supported in this Stream Mode. Please remove the Interpreters and try again.'), 'Dismiss');
        this.activeLink = 'interpreter';
        return false;
      }
      if (this.selectedDataObject['interpreter'].length > 5) {
        this.layoutUtilsService.showNotification(this.translate.instant('You cannot assign more than five interpreters'), 'Dismiss');
        this.activeLink = 'interpreter';
        return false;
      }
      for (let dt of this.selectedDataObject['interpreter']) {
        if (dt.language === undefined || dt.language === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to set the language for all interpreters'), 'Dismiss');
          this.activeLink = 'interpreter';
          return false;
        }
        // else {
        //   if (selectedLang.includes(dt.language)) {
        //     this.layoutUtilsService.showNotification(this.translate.instant('You need to set each language for only one interpreter'), 'Dismiss');
        //     this.activeLink = 'interpreter';
        //     return false;
        //   } else {
        //     selectedLang.push(dt.language);
        //   }
        // }
      }
      let selectedLeadHosts = this.selectedDataObject['lead'].filter((itm) => {
        if (itm.host) {
          return true;
        }
        return false;
      });
      if (this.selectedDataObject['moderator'].length === 0) {
        if (selectedLeadHosts.length === 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to assign a host for a') + ' ' + this.translate.instant('lead'), 'Dismiss');
          this.activeLink = 'lead';
          return false;
        }
        if (this.selectedDataObject['lead'].length > 1) {
          this.layoutUtilsService.showNotification('You cannot assign more than one ' + this.translate.instant('lead') + ' if you have no ' + this.translate.instant('moderator') + ' assigned', 'Dismiss');
          this.activeLink = 'lead';
          return false;
          // } else if (this.selectedDataObject['lead'].length === 1) {
          //   if (this.data.data['streamMode'] !== 'cloudlive' && this.data.data['streamMode'] !== 'meeting') {
          //     let viewMode = 'desktop';
          //     let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][viewMode]));
          //     let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
          //     let componentsList = componentsCopy.filter((cmp) => cmp.name === 'presenter-camera');
          //     if (componentsList.length === 0) {
          //       this.layoutUtilsService.showNotification(this.translate.instant('A Main Camera should be added when there is only one ') + ' ' + this.translate.instant('lead') + ' ' + this.translate.instant('and no producers in the session'), 'Dismiss');
          //       return false;
          //     }
          //   }
        }
      }
      if (this.selectedDataObject['moderator'].length > 0 && selectedLeadHosts.length > 0) {
        this.layoutUtilsService.showNotification(this.translate.instant('You cannot assign a ') + this.translate.instant('lead') + ' host when you already have a' + ' ' + this.translate.instant('moderator'), 'Dismiss');
        this.activeLink = 'lead';
        return false;
      }

      // for Moderators
      if (this.selectedDataObject['moderator'].length > 0) {
        let selectedModeratorsHosts = this.selectedDataObject['moderator'].filter((itm) => {
          if (itm.host) {
            return true;
          }
          return false;
        });
        if (selectedModeratorsHosts.length === 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to assign a host for a') + ' ' + this.translate.instant('moderator'), 'Dismiss');
          this.activeLink = 'moderator';
          return false;
        }
      }
      if (this.selectedDataObject['moderator'].length === 0 && this.selectedDataObject['lead'].length > 1) {
        this.layoutUtilsService.showNotification('You should assign at least one ' + this.translate.instant('moderator') + ' when there are multiple ' + this.translate.instant('leads'), 'Dismiss');
        this.activeLink = 'moderator';
        return false;
      }
    }


    return true;
  }
  getUsersByType(type) {
    let dataList = [];
    if (this.data.data.hasOwnProperty('users') && this.data.data.users) {
      return this.data.data.users.filter((usr) => usr.type === type);
    }
    return dataList;
  }
  saveData(usr = undefined, type = undefined): void {
    if (!this.loading) {
      if (this.validateData()) {
        const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
          disableClose: true,
          data: {
            title: this.translate.instant('Assign people'),
            data: '',
            description: this.translate.instant('This will assign people and refresh the pages of everyone in the session. Are you sure you want to proceed?'),
            cancelbtn: this.translate.instant('Cancel'),
            confirmbtn: this.translate.instant('Proceed'),
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = true;
            this.loaderService.display(true);
            this.errorMessage = '';
            let dataList = [];
            for (let ppl of this.peopleTypeKeys) {
              for (let dt of this.selectedDataObject[ppl]) {
                dataList.push({ _id: dt._id, name: dt.text, type: ppl, host: dt.host, viewPublicChat: dt.viewPublicChat, allowStarQuestions: dt.allowStarQuestions, allowPushQuestions: dt.allowPushQuestions, language: dt.language, showInsights: dt.showInsights, sessionAtAnyTime: dt.sessionAtAnyTime });
              }
            }
            if (this.data.data.streamMode === 'meeting') {
              dataList.map((usr) => {
                if (usr.host) {
                  usr.type = 'lead';
                }
                return usr;
              });
            }
            let obj = { users: dataList };
            // console.log('dataList', dataList);
            // this.loading = false;
            // return;
            this.requestService.assignUsersToSession(this.data.data._id, 'users', obj, (data, error) => {
              if (error) {
                this.errorMessage = error;
                this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
              }
              this.loading = false;
              this.loaderService.display(false);
              if (data) {

                // let userIds = [{
                //   userId: this.requestService.getSessionHost(this.data.data),
                //   type: 'host'
                // }];
                // if(false){ // comment this
                //   this.createTokBoxSessions(userIds, (status)=>{
                //     this.ablyService.sendSessionSignal(this.data.data._id, 'forceRefresh', 'true');
                //     if (usr) {
                //       this.continueSetAllRoom(usr, type, data.results);
                //     } else {
                //       this.dialogRef.close({ users: data.results, affectedSessions: undefined, type: undefined, user: undefined });
                //     }
                //   });
                // }else{
                this.ablyService.sendSessionSignal(this.data.data._id, 'forceRefresh', 'true');
                if (usr) {
                  this.continueSetAllRoom(usr, type, data.results);
                } else {
                  this.dialogRef.close({ users: data.results, affectedSessions: undefined, type: undefined, user: undefined });
                }
                // }
                // setTimeout(() => {
                //   this.custom_metadata_table.refresh();
                // }, 300);
              } else {
                this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
              }
            });
          }
        });
      }
    }
  }
  private createTokBoxSessions(groupIds, callback: (success: boolean) => any) {
    this.requestService.createMultipleSessions(groupIds, this.data.data._id, (data, error) => {
      if (data && data.status) {
        callback(true);
      } else {
        callback(false);
      }
    });
  }
  setAllRoom(usr, type = 'moderator'): void {
    if (!this.checkIfChangesDone()) {
      this.continueSetAllRoom(usr, type);
    } else {
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        disableClose: true,
        data: {
          title: this.translate.instant('Set ') + this.translate.instant(type) + this.translate.instant(' to current room sessions'),
          data: '',
          description: this.translate.instant('You have other unsaved changes. Do you want to save them as well and continue?'),
          cancelbtn: this.translate.instant('Cancel'),
          confirmbtn: this.translate.instant('Save and Continue'),
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.saveData(usr, type);
          // this.continueSetAllRoom(usr, type);
        }
      });
    }
  }
  continueSetAllRoom(usr, type = 'moderator', currentSessionUsers = undefined): void {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let objUsr = { _id: usr._id, name: usr.text, type: type, host: usr.host, viewPublicChat: usr.viewPublicChat, allowStarQuestions: usr.allowStarQuestions, allowPushQuestions: usr.allowPushQuestions, language: usr.language, showInsights: usr.showInsights, sessionAtAnyTime: usr.sessionAtAnyTime };
      this.requestService.assignUsersToRoom(this.data.data.room._id, objUsr, 'producer', (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.dialogRef.close({ users: currentSessionUsers, affectedSessions: data.results, type: type, user: objUsr });
        } else {
          this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
        }
      });
    }
  }
  drop(event: CdkDragDrop<any[]>, selected = false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (selected) {
        let selectedDataObject = JSON.parse(JSON.stringify(this.selectedDataObject));
        let newItem = event.previousContainer.data[event.previousIndex];
        if (this.activeLink === 'lead' || this.activeLink === 'moderator') {
          let selectedLeadHosts = this.selectedDataObject['lead'].find((itm) => itm.host);
          let selectedModeratorsHosts = this.selectedDataObject['moderator'].find((itm) => itm.host);
          if (!selectedLeadHosts && !selectedModeratorsHosts) {
            newItem['host'] = true;
          }
        }
        selectedDataObject[this.activeLink].push(event.previousContainer.data[event.previousIndex]);
        this.selectedDataObject = JSON.parse(JSON.stringify(selectedDataObject));
        // this.setUpFilter(this.selectedDataObject);
      } else {
        let selectedDataObject = JSON.parse(JSON.stringify(this.selectedDataObject));
        selectedDataObject[this.activeLink] = selectedDataObject[this.activeLink].filter(itm =>
          itm._id !== event.previousContainer.data[event.previousIndex]._id
        );
        this.selectedDataObject = JSON.parse(JSON.stringify(selectedDataObject));
        this.setUpFilter(this.selectedDataObject);
      }
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  /** FILTRATION */
  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  /** FILTRATION */
  termSelectedConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchSelectedInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  goToUserPage(): void {
    let currentUserRole = this.requestService.getUserRole();
    if (currentUserRole === 'admin') {
      this.dialogRef.close();
      this.router.navigate(['/admin/users'], { relativeTo: this.activatedRoute });
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant('You do not have admin rights to manage users please contact the event admin'), 'Dismiss');
    }
  }
  checkIfChangesDone() {
    for (let ppl of this.peopleTypeKeys) {
      if (this.selectedDataObject[ppl].length !== this.selectedDataObjectOriginal[ppl].length) {
        return true;
      } else {
        let idx = 0;
        for (let dt of this.selectedDataObject[ppl]) {
          let dataTypeKeys: string[] = Object.keys(dt);
          for (let ky of dataTypeKeys) {
            if (this.selectedDataObjectOriginal[ppl][idx][ky] !== dt[ky]) {
              return true;
            }
          }
          idx++;
        }
      }
    }
    return false;
  }
}
