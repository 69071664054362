<div class="mb-20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex class="errorMessageParent">
        <div class="errorMessage">
            <h1>{{errorType}}</h1>
            <img src="./assets/images/logo-loader.png" />
            <h4 *ngIf="errorType !== '404'">You are unauthorized to view this page.<br>
                To access it, please login first.
            </h4>
            <h4 *ngIf="errorType === '404'">Page Not Found
            </h4>
            <button mat-raised-button color="danger" (click)="goToLogin()"><span>Login Page</span></button>
        </div>
    </div>
</div>