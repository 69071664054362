import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  selectedData: any;
  filters: any;
  excludeList: any[];
  type: string;
  limit: number;
  limitMessage: string;
  columnData: string;
  confirmData: any;
}


@Component({
  selector: 'resources-session-assign-dialog',
  templateUrl: './resources-session-assign-dialog.component.html',
  styleUrls: ['./resources-session-assign-dialog.component.scss']
})
export class ResourcesSessionAssignDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: Subscription = undefined;
  public selectedUser: any;
  public isAdmin: boolean = false;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public canSearch: boolean = true;
  public optionData: any = [];
  public selectedData: any = [];
  public searchVal: string = '';
  public tableDataTotal: number = 0;

  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private requestService: RequestService, private router: Router,
	  private activatedRoute: ActivatedRoute,
    private layoutUtilsService: LayoutUtilsService,
    public dialogRef: MatDialogRef<ResourcesSessionAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
    this.selectedData = this.data.selectedData;
  }

  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try{
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0){
            this.paginator.pageIndex = 0;
            this.loadDataSearch();
          }
        }catch(e){}
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.loadDataSearch();
        }
      })
    );
  }
  public setAvailability(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.selectedData));
    selectedData[index]['availability'] = val;
    this.selectedData = selectedData;
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, this.data.data['_id'], (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.data.data = data.results;
          if (data.results.hasOwnProperty(this.data.columnData)) {
            let dataReturned: any[] = data.results[this.data.columnData];
            // this.selectedData = dataReturned.filter((item) => {
            // 	return !this.requestService.checkListContains(this.optionData, item._id);
            // });
            this.selectedData = dataReturned;
          }
        }
        this.loading = false;
        this.loadDataSearch();
      });
    }
  }
  public loadDataSearch() {
    if(this.loading && this.apiCallSubscription){
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.data.hasOwnProperty('excludeList')) {
        excludeList = JSON.parse(JSON.stringify(this.data.excludeList));
      }
      for (let dt of this.selectedData) {
        excludeList.push(dt._id);
      }
      this.apiCallSubscription = this.requestService.getDataListSummary(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any[] = data.results;
          let idx = 0;
          for (let dt of dataReturned) {
            dataReturned[idx]['host'] = false;
            idx++;
          }
          //console.log('dataReturned', dataReturned);
          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            this.paginatorTotal.next(data.pagination.total);
          }
          this.optionData = dataReturned;
          // .filter((item) => {
          // 	return !this.requestService.checkListContains(this.selectedData, item._id);
          // });
        } else {
          this.optionData = [];
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  selectData(): void {
    if (this.data.limit) {
      if (this.selectedData.length > this.data.limit) {
        if(this.data.limitMessage){
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        }else{
          this.layoutUtilsService.showNotification('You cannot select more than ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
        }
      } else {
        this.dialogRef.close(this.selectedData);
      }
    } else {
      this.dialogRef.close(this.selectedData);
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  /** FILTRATION */
  termConfiguration(): any {
    if(this.searchInput){
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
	goToTitlePage(): void {
    this.dialogRef.close();
		this.router.navigate(['/tiles'], { relativeTo: this.activatedRoute });
	}
	goToResourcePage(): void {
    this.dialogRef.close();
		this.router.navigate(['/admin/resources'], { relativeTo: this.activatedRoute });
	}
}
