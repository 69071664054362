import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { urlSafeBase64Encoding } from '../../helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environment';
import { MultipleModalDialogComponent } from '../custom-multiple-upload-dialog/custom-multiple-upload-dialog.component';

interface ViewDialogData {
	title: string;
	data: any;
	galleryType: string;
	targetId: string;
	noSelection: boolean;
	multipleSelection: boolean;
	maxHeight: number;
	maxWidth: number;
}

@Component({
	selector: 'app-audio-gallery-dialog-modal',
	templateUrl: './custom-audio-gallery-dialog.component.html',
	styleUrls: ['./custom-audio-gallery-dialog.component.scss']
})
export class ModalAudioGalleryDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public passingThru: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public noSelection: boolean = false;
	public multipleSelection: boolean = false;
	public multipleSelected: any = {};
	public multipleSelectedObject: any = {};
	public newImage: boolean = false;
	public production: boolean = environment.production;
	public dataType: string = 'media';
	public dataTypeDisplay: string = this.translate.instant('Media');
	public dataList: any[] = [];
	public dataListFolder: any[] = [];
	public fileSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public folderText: string = '';
	public folderSelected: string = undefined;
	public tabSelected: number = 0;
	public galleryType: string = 'audiogallery';
	public targetId: string;
	public searchVal: string = '';
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public allowedExtensions: string[] = ['wav', 'mp3', 'm4a', 'aac'];
	public allowedAcceptExtensions: string = '.aac,audio/mp3,audio/m4a,audio/wav';

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	pageSize = 10;
	pageNumber = 1;

	orderDir = 'desc';
	orderBy = 'createdAt'; // uid

	// @ViewChild('searchInput') searchInput: ElementRef;

	@ViewChild('imageInput') private imageInput: ElementRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<ModalAudioGalleryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalAudioGalleryDialogComponent', data);
		this.noSelection = data.noSelection || false;
		this.multipleSelection = data.multipleSelection || false;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.targetId = data._id;
					if (this.data.hasOwnProperty('galleryType'))
						this.galleryType = this.data.galleryType;
					if (this.data.hasOwnProperty('targetId'))
						this.targetId = this.data.targetId;
					this.loadData();
				}
			})
		);
	}
	ngAfterViewInit() {
		// const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
		// 	debounceTime(150),
		// 	distinctUntilChanged(),
		// 	tap(() => {
		// 		this.paginatorTotal.next(0);
		// 		this.loadData();
		// 	})
		// )
		// .subscribe();
		// this.subscriptions.push(searchSubscription);
		const paginatorSubscriptions = merge(this.paginator.page).pipe(
			tap(() => {
				this.getTableVariables();
				this.loadData();
			})
		)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);
		// this.loadData();
	}

	public getTableVariables() {
		// this.orderBy = this.sort.active || 'uid';
		// this.orderDir = this.sort.direction || 'asc';
		this.pageNumber = this.paginator.pageIndex + 1;
		this.pageSize = this.paginator.pageSize;
	}
	public rationChanged(event) {
		this.selectedRatio = Number(event.value);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let termConfiguration = ''
			let filters: any = {
				"$and": [{ 'organizationId': { '$eq': this.requestService.orgId } },
				{ "type": { "$eq": this.galleryType } }] //, filter: filters
			};
			let filterObj = { perpage: this.pageSize, page: this.pageNumber, orderBy: this.orderBy, orderDir: this.orderDir, term: termConfiguration, filter: filters };
			//, fieldKeys:["title", "art", "isWeight", "Procedure", "hsrRuleEngine", "smart", "notification"] // try decrease fields names
			this.requestService.getDataList(this.dataType, filterObj, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					console.log('dataList', data);
					this.dataList = data.results;
				}
				this.paginatorTotal.next(data.pagination.total);
				this.loading = false;
			});
		}
	}
	public delete(e, id: any) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (!this.loading) {
			const _title: string = this.translate.instant('Deleting') + ' ' + this.dataTypeDisplay;
			const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
			const _waitDesciption: string = this.translate.instant('Deleting') + '...';

			const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.realDelete(id);
			});
		}
	}
	public realDelete(id: any) {
		const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.deleteSingleDataByApi(this.dataType, id, 'delete', (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
					this.fileSelected = undefined;
					this.imageUrlPath = undefined;
					// this.pageNumber = 1;
					this.loadData();
				}
			});
		}
	}
	public setFileSelected(val) {
		this.fileSelected = val;
	}
	// termConfiguration(): any {
	// 	const searchText: string = this.searchInput.nativeElement.value;
	// 	return searchText;
	// }
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}

	onBrowseFiles(target: any): void {
		// this.readFiles(target.files);
		// if (target.files.length > 1) {
		const dialogRef = this.dialog.open(MultipleModalDialogComponent, {
			width: '100vw',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Multiple') + ' ' + this.translate.instant('Audio') + ' ' + this.translate.instant('Upload'),
				path: 'media/upload/' + this.requestService.orgId,
				type: this.galleryType,
				target: target,
				isEncoded: 'false',
				folder: this.folderSelected,
				allowedExtensions: this.allowedExtensions,
				allowedAcceptExtensions: this.allowedAcceptExtensions,
				addName: false
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (this.imageInput) {
				this.imageInput.nativeElement.value = "";
			}
			if (result) {
				this.pageNumber = 1;
				this.tabSelected = 0;
				this.setFileSelected(result[result.length - 1].uploaded);
				this.loadData();
			}
		});
		// } else {
		// 	this.readFiles(target.files);
		// }
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, index = 0): void {
		let reader = new FileReader();
		if (index in files) {
			let currentFile = { error: false, text: files[index].name, id: files[index].id, originalFile: files[index], source_url: null, extension: '' };
			let fileExt = files[index].name.split('.').pop();
			currentFile.extension = fileExt.toLowerCase();
			const max_size = 20000000;
			if (files[index].size > max_size) {
				this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', 'Dismiss');
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				currentFile.error = true;
				this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), 'Dismiss');
			} else {
				this.readFile(files[index], reader, (event) => {
					this.loaderService.display(true);
					this.continueUpload(currentFile);
				});
			}
		} else {
			this.cdr.detectChanges();
		}
	}
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	continueUpload(currentFile, selectedImageId: string = undefined) {
		this.loaderService.display(true);
		this.requestService.onUploadAnyFile(this.targetId, currentFile, this.galleryType, 'false', selectedImageId)
			.subscribe(
				(results: any) => {
					console.log('results', results);
					this.loaderService.display(false);
					if (results['status']) {
						currentFile.source_url = results;
						this.pageNumber = 1;
						this.tabSelected = 0;
						this.setFileSelected(results.results);
						this.loadData();
						this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' ' + this.translate.instant('Successfully Uploaded'), this.translate.instant('Dismiss'));
					} else {
						currentFile.error = true;
						this.layoutUtilsService.showNotification(this.translate.instant('Error:') + results['message'], this.translate.instant('Dismiss'));
					}
					// this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					// this.currentFile = currentFile;
				},
				error => {
					//console.log('Error uploading file.', error);
					currentFile.error = true;
					// this.currentFile = currentFile;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Error uploading file.'), this.translate.instant('Dismiss'));
					// this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					this.loaderService.display(false);
				}
			);
	}
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	imageHasLoaded(e) {
		//console.log('imageHasLoaded', e);
	}
	imageLoaded() {
		// show cropper
	}
	loadImageFailed() {
		// show message
	}
	setMultipleSelected(event, id, itm) {
		if (event.checked) {
			this.multipleSelected[id] = true;
			this.multipleSelectedObject[id] = itm;
		} else {
			if (this.multipleSelected.hasOwnProperty(id)) {
				delete this.multipleSelected[id];
			}
			if (this.multipleSelectedObject.hasOwnProperty(id)) {
				delete this.multipleSelectedObject[id];
			}
		}
		// console.log('this.multipleSelected', this.multipleSelected);
		// console.log('this.multipleSelectedObject', this.multipleSelectedObject);
	}
	multiSelect() {
		let imagesList = [];
		let multipleSelectedObjectKey = Object.keys(this.multipleSelectedObject);
		for (let itmId of multipleSelectedObjectKey) {
			imagesList.push(this.multipleSelectedObject[itmId]);
		}
		this.closeModal(imagesList);

	}
	hasSelected() {
		let multipleSelectedObjectKey = Object.keys(this.multipleSelectedObject);
		return multipleSelectedObjectKey.length > 0 ? true : false;

	}
}
