<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
      <mat-card style="height: 100%;padding: 16px 10px 3px;min-height: 300px">
				<div fxLayout="row" fxLayout.lt-md="column" fxFlex  >
	        <div fxFlex>
						<app-custom-select #customselect [value]="returnData.id" [itemName]="'metadata'" [dataType]="'video'" [apiTarget]="'searchObject'" [filters]="filterData" [placeholder]="'Media Library'" (onSelectReturn)="selectItemCheck($event)" [termfields]="['name']"></app-custom-select>
					</div>
	        <div fxFlex="5" *ngIf="isAdmin">
						<button mat-icon-button (click)="editVideoLibrary(returnData.id)" *ngIf="returnData.id && returnData.id !== ''" style="margin:20% auto"><mat-icon>edit</mat-icon></button>
					</div>
				</div>
				<div class="example-full-width">
					<mat-label>{{'Volume' | translate}}</mat-label>
					<mat-slider min="0" max="1" step="0.1" [thumbLabel]="true" [(ngModel)]="returnData['volume']"></mat-slider>
				</div>
				<div class="example-full-width">
					<mat-icon *ngIf="!returnData['autoPlay']" (click)="returnData['autoPlay']=true" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['autoPlay']" (click)="returnData['autoPlay']=false" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="autoPlay"> {{'Auto Play' | translate}}</label>
					<mat-icon class="infoCss" title="{{'Only one video can be auto-played at a time. If you set more than one video to auto-play you might experience browser glitches' | translate}}">info</mat-icon>
				</div>
				<div class="example-full-width">
					<mat-icon *ngIf="!returnData['loop']" (click)="returnData['loop']=true" title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['loop']" (click)="returnData['loop']=false" title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="loop"> {{'Loop' | translate}}</label>
				</div>
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
			<button mat-raised-button (click)="goToVideoLibrary()" *ngIf="isAdmin">{{'Go to Media Library' | translate}}</button>
			<!-- <button mat-raised-button (click)="createVideoLibrary()" *ngIf="isAdmin">{{'Create' | translate}}</button> -->
	</div>
	<br/>
</div>
