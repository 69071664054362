import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService, SubheaderService } from '../../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { EventData } from '../../../shared/modules/calendar/interface/event-data';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from 'src/app/shared/components/custom-dialog/custom-dialog.component';
import { ModalViewEventDialogComponent } from '../../../shared/modules/calendar/view-event-dialog/view-event-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { utc } from 'moment';

@Component({
  selector: 'app-custom-scheduler',
  templateUrl: './custom-scheduler.component.html',
  styleUrls: ['./custom-scheduler.component.scss']
})
export class CustomSchedulerComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public organization = undefined;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public originalTableSetting: any = undefined;
  public metaFieldSetting = undefined;
  public tableSetting: any = undefined;
  public settingOrgObject = undefined;
  public loading: boolean = false;
  public permissions = {
    canViewEvent: false,
    canAddEvent: false,
    canEditEvent: false,
    canDeleteEvent: false,
    canCreateEvent: false
  }

  public hasRolesOrgRequired: boolean = false;
  public vertical = environment.orgType;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public dataType: string = 'schedule/event';
  public dataTypeDisplay: string = this.translate.instant('Event');
  public eventsActionsType: any = {
    'blueprint-occurrence': { displayName: 'Event From Blueprint', color: '#008000' },
    'occurrence': { displayName: 'Event Occurrence', color: '#242852' },
    'blueprint': { displayName: 'Event Blueprint', color: '#ff0000' },
  }
  public eventsActions: any[] = [];
  public currentDay: any = undefined;
  public dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[] };
  public dataArray: EventData[] = [];
  public dataSkelletonArray: EventData[] = [];

  public dataListAll: any[] = [];
  public dataListBlueprintAll: any[] = [];
  public errorMessage: string = '';
  public filterData = this.getBlueprintCustomFilter();
  public selectedFilter = undefined;
  @Input() personal: boolean = false;
  utc = utc;
  @ViewChild('customViewEventTemplate') public customViewEventTemplate: TemplateRef<any>;
  @ViewChild('blueprintList') public blueprintList: CustomSelectCreateAutocompleteComponent;

  constructor(private router: Router, private loaderService: LoaderService,
    private requestService: RequestService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar, private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRefs: ChangeDetectorRef, private activatedRoute: ActivatedRoute,
    private subheaderService: SubheaderService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          // this.loadDBData();
          this.buildSetting();
        }
      })
    );

    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          this.hasRolesOrgRequired = (data.roleCount && data.roleCount > 0);
          if (data.hasOwnProperty('settings')) {
            // console.log(data.settings);
            this.settingOrgObject = data.settings;
            if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.page && this.settingOrgObject['web']['page']['enableScheduler']) {
              // can access
            } else {
              this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
            }
          }
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  goTo(path) {
    this.router.navigate([path]);
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = Object.assign({}, data.results);
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          this.eventsActions = this.metaFieldSetting['eventType'].enum;
          // this.eventsActions.push({displayName: 'Event From Blueprint', value: 'blueprint-occurrence'});
          const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
          this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
          console.log('this.metaFieldSetting', this.metaFieldSetting);
          if (this.isAdmin) {
            this.permissions.canAddEvent = true;
            this.permissions.canEditEvent = true;
            this.permissions.canDeleteEvent = true;
          }
          this.permissions.canViewEvent = true;
          this.loadDBData();
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  selectDay(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }
    this.addEvent({ action: 'occurrence', day: day });
  }
  canCreateFromBluePrint(event) {
    let isCurrentUser = false;
    if (event.eventCreator && event.eventCreator.length > 0) {
      isCurrentUser = event.eventCreator.filter((itm) => {
        if (itm.type === 'user' && this.selectedUser._id === itm._id) {
          return true;
        } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
          return true;
        }
        return false;
      }
      ).length > 0;
    } else {
      isCurrentUser = true;
    }
    return isCurrentUser;
  }
  isAnAttendee(event) {
    let isCurrentUser = false;
    if (event.opentoall) {
      isCurrentUser = true;
    } else {
      if (event.attendees && event.attendees.length > 0) {
        isCurrentUser = event.attendees.filter((itm) => {
          if (itm.type === 'user' && this.selectedUser._id === itm._id) {
            return true;
          } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
            return true;
          }
          return false;
        }
        ).length > 0;
      }
    }
    return isCurrentUser;
  }
  selectEvent(event) {
    console.log('selectEvent', event);
    if (this.isAdmin || (!this.isAdmin && event.event.eventType !== 'blueprint')) {
      if (!this.permissions.canViewEvent) {
        return;
      }
      let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
      let canEditEvent = this.permissions.canEditEvent;
      let canDeleteEvent = this.permissions.canDeleteEvent;
      let canCreateEvent = false;
      let canOpen = (event.event.eventType !== 'blueprint');
      let momentUntil = moment().utc();
      let enddate = moment(event.event.enddate).utc();

      if (event.event.eventType === 'blueprint') {
        canCreateEvent = this.canCreateFromBluePrint(event.event);
      }
      if (event.event.createdBy === this.selectedUser._id) {
        canDeleteEvent = true;
      }
      if (momentUntil.diff(enddate, 'minutes') > 0) {
        canEditEvent = false;
        canDeleteEvent = false;
        canCreateEvent = false;
        canOpen = false;
      }
      if (event.meetingType === '2') {
        canOpen = false;
      }
      const dialogRef = this.dialog.open(ModalViewEventDialogComponent, {
        width: '800px',
        data: {
          title: this.translate.instant('Event'),
          data: event.event,
          fields: tableSetting.fields,
          canEdit: canEditEvent,
          canDelete: canDeleteEvent,
          canCreate: canCreateEvent,
          canOpen: canOpen,
          contentTemplate: this.customViewEventTemplate
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 'edit') {
            this.editEvent(event.event);
          } else if (result === 'delete') {
            this.deleteEvent(event.event);
          } else if (result === 'open') {
            this.openRoomEvent(event.event);
          } else if (result === 'create') {
            this.addEvent({ action: 'blueprint-occurrence', blueprint: event.event, event: event });
          }
        }
      });
    } else {
      let momentUntil = moment().utc();
      let enddate = moment(event.event.enddate).utc();
      if (momentUntil.diff(enddate, 'minutes') < 0) {
        this.addEvent({ action: 'blueprint-occurrence', blueprint: event.event, event: event });
      }
    }
  }
  openRoomEvent(event) {
    if (event.place === '1') {
      this.router.navigate(['/rooms/' + event.room._id + '/event/' + event._id], { relativeTo: this.activatedRoute });
    } else if (event.place === '2' && event.event.meetingurl !== '') {
      window.open(event.event.meetingurl, "_blank");
    } else {
      // do nothing
    }
  }
  deleteEvent(event) {
    if (!this.permissions.canDeleteEvent) {
      return;
    }
    this.confirmDelete(event);
  }
  editEvent(event) {
    if (!this.permissions.canEditEvent) {
      return;
    }
    // console.log('editEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    // console.log('tableSetting', tableSetting);
    if (event.eventType === 'blueprint') {
      tableSetting['customSettings']['attendees'] = {
        nullable: true,
        visible: false,
      };
      tableSetting['customSettings']['eventCreatorType'] = {
        visible: true
      };
      tableSetting['customSettings']['eventCreator'] = {
        visible: true
      };
      tableSetting['customSettings']['color'] = {
        visible: true
      };
    } else if (event.eventType === 'occurrence') {
      tableSetting['customSettings']['capacity'] = {
        visible: false
      }
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: false
      };
      tableSetting['customSettings']['attendees'] = {
        nullable: false
      };
      tableSetting['customSettings']['color'] = {
        visible: true
      };
    }
    tableSetting['customSettings']['isMht'] = {
      visible: true
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Event'),
        data: event,
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmEdit(result.data);
      }
    });
  }
  dayShift(event) {
    if (!this.currentDay || (this.currentDay && (this.currentDay.month != event.currentDay.month || this.currentDay.year != event.currentDay.year))) {
      this.currentDay = event.currentDay;
      if (event.status === 'change') {
        this.loadDBData();
      }
    }
  }
  addEvent(event) {
    // console.log('addEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    let action = event.action;
    if (action === 'blueprint-occurrence') {
      action = 'occurrence';
      let filters = {};
      filters['$and'] = [
        { "foreign_key": { "$eq": this.requestService.orgId } },
        { 'project': { '$eq': 'stellar-' + this.vertical } },
        { 'eventType': { '$eq': 'blueprint' } },
      ];
      let blueprint = { _id: event.blueprint._id, name: event.blueprint.name };
      tableSetting['customSettings']['blueprint'] = {
        visible: true,
        disabled: true,
        nullable: false,
        control: true,
        filters: filters,
        blueprint: event.blueprint,
        value: blueprint,
      };
      tableSetting['customSettings']['name'] = {
        visible: true,
        controller: 'blueprint',
        value: event.blueprint.name
      };
      tableSetting['customSettings']['description'] = {
        visible: true,
        controller: 'blueprint',
        value: event.blueprint.description
      };
      tableSetting['customSettings']['capacity'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.capacity
      };
      tableSetting['customSettings']['opentoall'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.opentoall
      };
      tableSetting['customSettings']['attendees'] = {
        visible: true,
        disabled: false,
        nullable: false,
        controller: 'blueprint',
        value: this.getUserAsAttendee()
        // value: event.blueprint.attendees || []
      };
      tableSetting['customSettings']['settingType'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.settingType
      };
      tableSetting['customSettings']['meetingType'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.meetingType
      };
      tableSetting['customSettings']['place'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.place
      };
      tableSetting['customSettings']['layout'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.layout
      };
      tableSetting['customSettings']['room'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.room
      };
      tableSetting['customSettings']['meetingurl'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.meetingurl
      };
      tableSetting['customSettings']['location'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.location
      };
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.maxNumberofHourAllowed
      };
      tableSetting['customSettings']['startdate'] = {
        visible: true,
        disabled: false,
        controller: 'blueprint',
        value: event.blueprint.startdate
      };
      tableSetting['customSettings']['enddate'] = {
        visible: true,
        disabled: false,
        controller: 'blueprint',
        value: event.blueprint.enddate
      };
      tableSetting['customSettings']['isRecurrence'] = {
        visible: false,
        disabled: true,
        value: false,
        // controller: 'blueprint' always value false
      };
      tableSetting['customSettings']['enableChat'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.enableChat
      }

      tableSetting['customSettings']['color'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.color
      };
    } else if (action === 'blueprint') {
      // tableSetting['customSettings']['isRecurrence'] =  {
      //   visible: false,
      //   disabled: true,
      //   value: false
      // };
      tableSetting['customSettings']['attendees'] = {
        nullable: true,
        visible: false,
      };
      tableSetting['customSettings']['eventCreatorType'] = {
        visible: true
      };
      tableSetting['customSettings']['eventCreator'] = {
        visible: true
      };
      tableSetting['customSettings']['color'] = {
        visible: true,
        value: '#242852'
      };
    } else if (action === 'occurrence') {
      tableSetting['customSettings']['capacity'] = {
        visible: false
      }
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: false
      };
      tableSetting['customSettings']['attendees'] = {
        nullable: false,
        value: this.getUserAsAttendee()
      };
      if (event.hasOwnProperty('day')) {
        let val = new Date(event.day.year, event.day.month, event.day.day);
        if (event.day.hasOwnProperty('hour')) {
          val = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
        }
        tableSetting['customSettings']['startdate'] = {
          visible: true,
          value: val
        };
        tableSetting['customSettings']['enddate'] = {
          visible: true,
          value: val
        };
      }
      tableSetting['customSettings']['color'] = {
        visible: true,
        value: '#242852'
      };
    }
    tableSetting['customSettings']['eventType'] = {
      visible: false,
      value: action
    };
    // console.log('tableSetting', tableSetting);
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Event'),
        data: {},
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCreate(result.data);
      }
    });
  }

  private getSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      foreign_key: {
        visible: false,
        value: this.requestService.orgId
      },
      createdBy: {
        visible: false,
        value: this.selectedUser._id
      },
      project: {
        visible: false,
        value: 'stellar-' + this.vertical
      }
    };
    return tableSetting;
  }
  getUserAsAttendee() {
    return [{ _id: this.selectedUser._id, name: this.selectedUser.name, email: this.selectedUser.email, type: 'user', userType: 'attendee', host: true }];
  }
  private getCustomFilter() {
    let filters = {};
    filters['$and'] = [
      { "foreign_key": { "$eq": this.requestService.orgId } },
      { 'project': { '$eq': 'stellar-' + this.vertical } },
      { 'eventType': { '$ne': 'blueprint' } }
    ];
    return filters;
  }
  private getBlueprintCustomFilter() {
    let filters = {};
    filters['$and'] = [
      { "foreign_key": { "$eq": this.requestService.orgId } },
      { 'project': { '$eq': 'stellar-' + this.vertical } },
      { 'eventType': { '$eq': 'blueprint' } }
    ];
    if (this.selectedFilter && this.selectedFilter !== '') {
      filters['$and'].push({ '_id': { '$eq': this.selectedFilter } });
    }
    return filters;
  }
  selectBlueprint(e) {
    this.selectedFilter = e;
    this.loadDBData();
  }
  getFullDay(currentDay) {
    return new Date(this.currentDay.year, this.currentDay.month, 1);
  }
  loadDBData() {
    if (this.selectedFilter && this.selectedFilter !== '') {
      this.loadBlueprintDBData([]);
    } else {
      if (!this.loading) {
        this.loading = true;
        this.loaderService.display(true);

        // let mom = moment;
        let currentStartDate = moment().utc();
        let currentEndDate = moment().utc();
        if (this.currentDay) {
          let fullDate = this.getFullDay(this.currentDay);
          currentStartDate = moment(fullDate).utc();
          currentEndDate = moment(fullDate).utc();
        }
        // let termConfiguration = this.termConfiguration();
        let startFilter = currentStartDate.subtract(1, 'months');
        let endFilter = currentEndDate.add(1, 'months');
        let filters = {
          'foreign_key': { '$eq': this.requestService.orgId },
          'project': { '$eq': 'stellar-' + this.vertical },
          'eventType': { '$ne': 'blueprint' },
          'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
          'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') },
          // "$or": [
          //    {
          //        "opentoall": "true" 
          //    }        ,
          //    {
          //        "$or": [
          //            {
          //               "$and": [
          //                    {
          //                        "attendees.type": "user" 
          //                    }
          //                    ,
          //                    {
          //                        "attendees._id":  this.selectedUser._id
          //                    }
          //                ]}
          //                ,{
          //                 "$and": [
          //                    {
          //                        "attendees.type": "role" 
          //                    }
          //                    ,
          //                    {
          //                        "attendees._id": this.selectedUser.role[0]?._id
          //                    }
          //                ]
          //            }
          //        ]
          //    }
          //   ]
        }
        // let filters = {
        //   "$and": [
        //     { 'foreign_key': { '$eq': this.requestService.orgId } },
        //     { 'project': { '$eq': 'stellar-' + this.vertical } },
        //     { 'eventType': { '$ne': 'blueprint' } },
        //     { 'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm')}},
        //     // { 'currentuser-attendees': { '$eq': true } },
        //     // { 'currentuser-eventCreator': { '$eq': true } }
        //     // {"startdate": { "$gte": "2021-11-01T00:00:00"}},
        //     {"enddate": {"$lt": endFilter.format('YYYY-MM-DDTHH:mm')}}
        //   ]
        // };

        let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
        this.requestService.getDataList(this.dataType,
          filterObj
          , (data, error) => {
            //console.log(data);
            if (error) {
              this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            this.loaderService.display(false);
            this.loading = false;
            if (data) {
              let dataList = data.results || [];
              if (this.personal) {
                dataList = dataList.filter((itm) => {
                  if (this.isAnAttendee(itm)) {
                    return true;
                  }
                  return false;
                })
              }
              this.dataListAll = dataList;
              this.loadBlueprintDBData(dataList);
            }
          });
      }
    }
  }
  loadBlueprintDBData(events) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      // let termConfiguration = this.termConfiguration();
      let filters = this.getBlueprintCustomFilter();

      let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            let dataList = data.results || [];
            // console.log('dataList', dataList);
            if (this.personal) {
              dataList = dataList.filter((itm) => {
                if (this.canCreateFromBluePrint(itm)) {
                  return true;
                }
                return false;
              })
            }
            this.dataListBlueprintAll = dataList;
            this.setUpData(events, dataList);
          }
          this.loaderService.display(false);
          this.loading = false;
        });
    }
  }
  setUpData(events, skelleton) {
    this.dataArray = events.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '#242852';
        }
      }
      return itm;
    });
    this.dataSkelletonArray = skelleton.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '#242852';
        }
      }
      return itm;
    });
    this.dataSource = { events: this.dataArray, skelleton: this.dataSkelletonArray }
  }
  public confirmCreate(dataToCreate) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToCreate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          if (dataToCreate.eventType === 'blueprint') {
            this.blueprintList.loadData();
          }
          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public confirmEdit(dataToUpdate) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToUpdate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          if (dataToUpdate.eventType === 'blueprint') {
            this.blueprintList.loadData();
          }
          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  confirmDelete(event) {
    if (event.isRecurrence) {
      let mainMsg = 'You are deleting an event with recurrence. How do you want to proceed?';
      let confirmBtn = 'Keeping past events and deleting the current and future';
      let declineBtn = 'Deleting all events existing and current';
      this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
        if (callbackStatus !== undefined) {
          let data = { _id: event._id }
          if (callbackStatus === true) {
            data['overWrite'] = '4';
          } else if (callbackStatus === false) {
            data['overWrite'] = '5';
          }
          this.confirmRealDelete(data);
        }
      });
    } else {
      this.delete(event);
    }
  }
  public delete(event: any) {
    let id = event._id;
    if (!this.loading) {
      const _title: string = this.dataTypeDisplay + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(id);
      });
    }
  }
  public confirmRealDelete(data) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.loadDBData();
        }
      });
    }
  }
  private handleConfirmChangeModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = true;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Confirm Changes'), this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(undefined);
      }
    });
  }
}
