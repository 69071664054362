import { Component, HostListener, Inject, NgZone, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, interval, fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, FeaturesPermissionService, MenuConfigService, LayoutUtilsService, UserActivityService } from './shared/services';
import { environment } from '../environments/environment';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { MenuConfig } from './menu.config';
import { ErrorEntityDialogComponent } from './shared/components/modals';
import { guid, detectMob } from './shared/helpers';
import { ShortcutInput } from "ng-keyboard-shortcuts";
import { ConfirmEntityDialogComponent } from 'src/app/shared/components/modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import * as _ from 'lodash';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public subscriptions: any[] = <any>[];
  private selectedUser: any = undefined;
  public loading: boolean = false;
  public aliveAppSubscriptions: any = undefined;
  public aliveCheckAppSubscriptions: any = undefined;
  public uniqueIDIdentifier: string = guid();
  public uniqueID: string = JSON.parse(JSON.stringify(this.uniqueIDIdentifier));
  public enableTranslation = environment.enableTranslation;
  public isProduction = environment.production;
  private aliveAppIntervalMinutes: number = environment.aliveAppIntervalMinutes;
  private aliveCheckAppIntervalMinutes: number = environment.aliveCheckAppIntervalMinutes;
  public showLoader: boolean = false;
  public showError: boolean = true;
  private networkDialog: any = undefined;
  private repeatIntervalSeconds: number = 2;
  private dataSeenOnce: boolean = false;
  private idleState = 'Not started.';
  private lastPing?: Date = null;
  private activityDialog: any = undefined;

  public shortcuts: ShortcutInput[] = [];
  constructor(private menuConfigService: MenuConfigService, private meta: Meta, private title: Title, private loaderService: LoaderService, public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService, private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private featuresPermissionService: FeaturesPermissionService, private userActivityService: UserActivityService, private idle: Idle, private swUpdate: SwUpdate) {
    //, @Inject(ApmService) service: ApmService
    // if (environment.environment !== 'local') {
    //   let version = localStorage.getItem('v');
    //   if (version) {
    //     this.logger.apm = service.init({
    //       serviceName: environment.apm.serviceName,
    //       serverUrl: environment.apm.server,
    //       environment: environment.environment,
    //       serviceVersion: JSON.parse(version)
    //     });
    //   }
    // }

    let lang = 'en';
    if (this.enableTranslation) {
      if (localStorage.getItem('lang')) {
        lang = JSON.parse(localStorage.getItem('lang'));
      } else {
        lang = this.getBrowserLanguage();
        if (lang !== 'en' && lang !== 'fr') {
          lang = 'en';
        }
      }
    }
    translate.setDefaultLang(lang);
    this.setLanguage(lang);
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();

    // if (isMobile || isTablet) {
    //   this.router.navigate(['/mobile']);
    // }
    // else {

    this.setIdleIntervals();

    // this.getMe();


    // if (+localStorage.tabCount > 0) {
    //   this.userActivityService.insertLog('Window', 'Duplicate tabs', 'Redirected to empty page');
    //   this.router.navigate(['/empty/close'], { skipLocationChange: false }).then((e) => window.location.reload());
    // } else {
    //   localStorage.tabCount = 0;
    this.getMe();
    // localStorage.tabCount = +localStorage.tabCount + 1;
    // }
    // }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event) {
  //   localStorage.tabCount = +localStorage.tabCount - 1;
  // };

  ngOnInit() {
    // if (detectMob()) {
    //   // this.layoutUtilsService.showNotification(this.translate.instant('For the best experience please open Stellar on a desktop/laptop.') , this.translate.instant('Dismiss'), {duration: 10000, verticalPosition: 'top'});
    //   alert(this.translate.instant('For the best experience please open Stellar on a desktop/laptop.'));
    // }
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        // if (confirm("You're using an old version of the control panel. Want to update?")) {
        //   window.location.reload();
        // }
        this.requestService.newAppVersion.next(true);
      });
    }

    if (!navigator?.onLine) {
      this.loaderService.showNetworkDisconnectedDialog();
    }

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          if (this.logger.apm) {
            this.logger.apm.setUserContext({
              'username': data.name,
              'id': data._id,
              'email': data.email
            });
            this.logger.apm.setCustomContext({
              'org': this.requestService.orgId
            });
          }
        }
      }));
    // const iliorgid = this.meta.getTag('name=ili-org-id');
    // this.title.setTitle('new title'); we can set title
    // console.log(iliorgid.content);
    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event) {
          let rememberMe = false;
          if (localStorage.getItem('rememberMe')) {
            rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
          }
          if (!rememberMe) {
            this.resetActivity();
          } else {
            this.idle.stop();
            console.log('Idle Activity Cancelled');
          }
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          if (!this.dataSeenOnce) {
            this.getMe();
          }
        } else {
          this.idle.stop();
        }
      }
      ));
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        this.selectedUser = data;
      }));

    this.subscriptions.push(interval(1000 * 5).subscribe(() => {
      if (this.requestService.authenticatedUser.getValue() && !this.requestService.newAppVersion.getValue()) {
        this.validateVersion();
      }
    }));
    this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe(() => {
      if (this.requestService.authenticatedUser.getValue()) {
        if (!this.validateMe()) {
          window.location.reload();
        }
      }
    }));

    this.subscribeActivity();

    // const clicks$ = fromEvent(document, 'click');
    // this.subscriptions.push(
    //   clicks$.subscribe((x) => {
    //     //console.log('Calling my service here');
    //     this.subscribeActivity();
    //   })
    // );

    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          // Show loading indicator
          this.loaderService.display(false);
          let url = event?.url;
          this.userActivityService.insertLog('router', 'NavigationStart', 'User opened page', { url });
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          // window.location.reload(); // uncomment later
          //console.log('NavigationError:', event.error);
        }
      })
    );
    this.subscriptions.push(
      this.logger.errorObject.subscribe((error) => {
        if (error) {
          // console.log('Global Error: ', error);
          // this.loaderService.display(false);
          this.userActivityService.insertLog('logger', 'errorObject', 'Global Error', error);
          if (this.showError) {
            this.showError = false;
            console.log(this.translate.instant('An Error occured:') + error);
            this.openAlert(this.translate.instant('You are experiencing a glitch'));
          }
        }
      })
    );
    this.menuConfigService.loadConfigs(new MenuConfig().configs);

  }
  // public updateGuid(){
  //   let guid = JSON.parse(localStorage.getItem('guid'));
  //   if (guid !== undefined && guid !== null) {
  //     this.uniqueID = guid;
  //     console.log('updateGuid this.uniqueID', this.uniqueID);
  //     console.log('updateGuid this.uniqueIDIdentifier', this.uniqueIDIdentifier);
  //   } else {
  //     console.log('change cashed id');
  //     localStorage.setItem('guid', JSON.stringify(this.uniqueID));
  //     console.log('updateGuid this.uniqueID', this.uniqueID);
  //     console.log('updateGuid this.uniqueIDIdentifier', this.uniqueIDIdentifier);
  //   }
  // }
  // public updateGuidFromIdentifier(){
  //   this.uniqueID = this.uniqueIDIdentifier;
  //   localStorage.setItem('guid', JSON.stringify(this.uniqueID));
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngAfterViewInit() {
    this.initKeyboardShortcut();
  }
  initKeyboardShortcut() {
    this.shortcuts.push(
      {
        key: ["shift + a"],
        command: (output: any) => this.requestActivity(output),
        preventDefault: true,
      },
      // {
      //     key: ["shift + l"],
      //     command: (output: any) => this.requestActivity(output),
      //     preventDefault: true,
      // }
    );
  }
  requestActivity(output) {
    // if (output.hasOwnProperty('key') && output.key.length > 0 && output.key[0] === 'shift + l'){
    //   window.location.href = '/#/login';
    //   window.location.reload();
    // }else{
    if (this.requestService.authenticatedUser.getValue()) {
      if (output.hasOwnProperty('key')) {
        if (output.key.length > 0) {
          if (output.key[0] === 'shift + a') {
            this.sendActivity();
          }
        }
      }
    }
    // }
  }
  setLanguage(lang) {
    this.translate.use(lang)
    this.requestService.lang = lang;
    localStorage.setItem('lang', JSON.stringify(lang));
  }
  setIdleIntervals() {
    if (localStorage.getItem('idleInterval') && localStorage.getItem('timeoutInterval')) {
      this.aliveCheckAppIntervalMinutes = JSON.parse(localStorage.getItem('idleInterval'));
      this.aliveAppIntervalMinutes = JSON.parse(localStorage.getItem('timeoutInterval'));
    } else {
      localStorage.setItem('idleInterval', JSON.stringify(this.aliveCheckAppIntervalMinutes));
      localStorage.setItem('timeoutInterval', JSON.stringify(this.aliveAppIntervalMinutes))
    }
  }
  getBrowserLanguage() {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1)
      shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1)
      shortLang = shortLang.split('_')[0];

    console.log(lang, shortLang);
    return shortLang;
  }
  resetActivity() {
    this.idle.watch();
    this.idleState = 'Running.';
    console.log('Idle Activity', this.idleState);
  }
  subscribeActivity() {
    console.log('Idle Activity initialized');
    this.idle.setIdle(this.aliveCheckAppIntervalMinutes * 60);
    this.idle.setTimeout(this.aliveAppIntervalMinutes * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.resetActivity();
      })
    );
    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        console.log(this.idleState);
        this.requestService.logOutApi('User ' + this.idleState);
        // this.router.navigate(['/login']);

      })
    );
    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        this.noActivity(this.translate.instant('No Activity'), this.translate.instant('There was no activity for over') + ' ' + this.aliveCheckAppIntervalMinutes + ' ' + this.translate.instant('minutes. Would you like to keep the session active?'));
      })
    );
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.log(this.idleState);
      })
    );
    // sets the ping interval to 15 seconds
    // this.keepalive.interval(15);
    // this.subscriptions.push(
    //   this.keepalive.onPing.subscribe(() => this.lastPing = new Date())
    // );


    // if (this.aliveAppSubscriptions) {
    //   this.aliveAppSubscriptions.unsubscribe();
    // }
    // if (this.aliveCheckAppSubscriptions) {
    //   this.aliveCheckAppSubscriptions.unsubscribe();
    // }
    // //console.log('subscribed', this.aliveCheckAppIntervalMinutes)
    // this.aliveCheckAppSubscriptions = interval(1000 * 60 * this.aliveCheckAppIntervalMinutes).subscribe(() => {
    //   if (this.aliveAppSubscriptions) {
    //     this.aliveAppSubscriptions.unsubscribe();
    //   }
    //   if (this.aliveCheckAppSubscriptions) {
    //     this.aliveCheckAppSubscriptions.unsubscribe();
    //   }
    //   if (this.requestService.authenticatedUser.getValue()) {
    //     this.noActivity(this.translate.instant('No Activity'), this.translate.instant('There was no activity for over') + ' ' + this.aliveCheckAppIntervalMinutes + ' ' + this.translate.instant('minutes. Would you like to keep the session active?'));
    //   }
    // });
  }
  noActivity(title: string, msg: string) {
    if (!this.activityDialog) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCloseButton'] = false;
      alertSetting['confirmText'] = this.translate.instant('Yes');
      alertSetting['declineText'] = this.translate.instant('No');
      // alertSetting['timerEvent'] = 120;

      this.activityDialog = this.layoutUtilsService.alertActionElement(title, msg, alertSetting);
      this.activityDialog.afterClosed().subscribe(res => {
        if (res) {
          // if(res.action === 'declineText'){
          //   // this.subscribeActivity(); // do nothing
          // }else
          if (res.action === 'confirmText') {
            this.activityDialog.close();
            this.resetActivity();
          } else {
            this.requestService.logOutApi('User clicked logout on timeout');
          }
          this.activityDialog = undefined;
        }
      });
    }
  };
  public getMe() {
    if (!localStorage.getItem('uid')) {
      localStorage.setItem('uid', JSON.stringify(Date.now()));
    }
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let appId = JSON.parse(localStorage.getItem('a'));
      let locId = JSON.parse(localStorage.getItem('l'));
      let orgData = JSON.parse(localStorage.getItem('org'));
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.locId = locId;
      if (orgData.hasOwnProperty('name')) {
        this.title.setTitle('Stellar - ' + orgData.name);
      }
      this.requestService.pageOrganization.next(orgData);
      this.refreshOrg(orgId);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.requestService.updatePermissions(resource);
        this.dataSeenOnce = true;
        this.validateMeByApi();
      }
    } else {
      this.dataSeenOnce = false;
      // this.requestService.logout();
    }
  }
  public validateVersion() {
    if (localStorage.getItem('v')) {
      let versionData = JSON.parse(localStorage.getItem('v'));
      if (this.requestService.version !== versionData) {
        this.requestService.newAppVersion.next(true);
      }
    }
  }
  public validateMeByApi() {
    this.requestService.getMe((data, error) => {
      if (error) {
        // this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        sessionStorage.setItem('live', JSON.stringify(true));
        this.requestService.authenticatedUser.next(true);
        if (localStorage.getItem('v')) {
          let versionData = JSON.parse(localStorage.getItem('v'));
          if (this.requestService.version !== versionData) {
            this.requestService.newAppVersion.next(true);
          }
        }
        // valid
      } else {
        this.dataSeenOnce = false;
        // this.requestService.logout();
      }
    });
  }
  public validateMe() {
    if (localStorage.getItem('currentUser') && localStorage.getItem('o')) {
      let currentStorageUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let isDummyUser = this.requestService.currentUser._id.indexOf('-dummy');
      if (isDummyUser > -1) {
        let currentUserId = this.requestService.currentUser._id.slice(0, isDummyUser);
        if (currentStorageUser._id !== currentUserId) {
          return false;
        }
      } else {
        if (currentStorageUser._id !== this.requestService.currentUser._id) {
          return false;
        }
      }
      if (orgId !== this.requestService.orgId) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }
  public refreshOrg(orgId) {
    this.requestService.getSingleData('organization', this.requestService.orgId, (data, error) => {
      if (error) {
        // do nothing
      }
      if (data && data.results) {
        let orgData = data.results;
        if (orgData.hasOwnProperty('name')) {
          this.title.setTitle('Stellar - ' + orgData.name);
        }
        localStorage.setItem('org', JSON.stringify(orgData));
        this.requestService.pageOrganization.next(orgData);
        this.featuresPermissionService.setUpOrg(orgData);
      }
    });
  }
  public openAlert(message, title = 'Message') {
    const _title: string = title;
    const _description: string = message;

    const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      window.location.reload();
    });
  }

  @HostListener('window:offline', ['$event'])
  OfflineEvent(event: Event) {
    setTimeout(() => {
      if (!navigator?.onLine)
        this.networkDialog = this.loaderService.showNetworkDisconnectedDialog();
      else
        this.networkDialog = undefined;
    }, 5000);
  }

  @HostListener('window:online', ['$event'])
  OnlineEvent(event: Event) {
    if (this.networkDialog) {
      window.location.reload();
      // this.loaderService.closeNetworkDialog();
      // clearTimeout(this.networkDialog);
    }
  }
  sendActivity() {
    if (!this.loading) {
      let logs = this.userActivityService.getLogs();
      if (logs.length > 0) {
        this.loading = true;
        const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
          disableClose: true,
          data: {
            title: this.translate.instant('Stellar Activity'),
            data: '',
            description: this.translate.instant('This will send your activity to stellar support. Are you sure you want to proceed?'),
            cancelbtn: this.translate.instant('Cancel'),
            confirmbtn: this.translate.instant('Proceed'),
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = true;
            this.loaderService.display(true);
            this.requestService.sendActivity(this.selectedUser.name + ' Stellar Activity', logs, (data, error) => {
              if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
              }
              if (data && data.hasOwnProperty('results')) {
                this.layoutUtilsService.showNotification(this.translate.instant('You current stellar activity is sent'), this.translate.instant('Dismiss'));
              }
              this.loading = false;
              this.loaderService.display(false);
            });
          } else {
            this.loading = false;
          }
        });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('Nothing to send'), this.translate.instant('Dismiss'));
      }
    }
  }
}
