import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RequestService, LayoutUtilsService, RoomSessionService } from '../../../shared/services';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../../../shared/components/iframe-dialog/iframe-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { EventType } from '../../services/enums/eventType';
import { PageType } from '../../services/enums/pageType';
import { EventTrack } from '../../services/models/event-tracking.model';
import { ClickType } from '../../services/enums/clickType';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  private subscriptions: Subscription;
  public errorMessage: string = '';
  sliderArray: any[] = [];
  bannerName: string = '';
  loading: boolean = false;
  dataType: string = 'banner';
  transitionTime: number = undefined;
  selectedIndex = 0;
  bannerHeight: string = undefined;
  bannerWidth: string = '100%';
  public _bannerId: string = undefined;
  @Input() track: boolean = false;
  @Input() forEdit: boolean = false;
  @Input()
  set bannerId(bannerId: any) {
    this._bannerId = bannerId;
    if (bannerId) {
      this.loadData(bannerId);
    } else {
      this.sliderArray = [];
    }
  }
  get bannerId(): any {
    return this._bannerId;
  }

  public currentRoomUserRole = undefined;

  public currentSessionUserRole = undefined;
  masterModeratorId: string = undefined;
  leads: any = [];
  @Input() roomId: string = undefined;
  @Input() sessionData: any = undefined;
  constructor(
    protected layoutUtilsService: LayoutUtilsService, private requestService: RequestService, protected translate: TranslateService, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private roomSessionService: RoomSessionService) {
    //remove below when finished
    // this.sliderArray = [
    //  {"img": "/assets/temp/1.jpg", "alt": "", link: 'https://www.google.com'},
    //  {"img": "/assets/temp/2.jpg", "alt": "", link: 'https://www.google.com'},
    //  {"img": "/assets/temp/3.jpg", "alt": "", link: 'https://www.google.com'},
    // ];
    this.sliderArray = [];
    // this.bannerHeight = '15vh';
  }

  ngOnInit() {

  }
  initiateTransition(transitionTime) {
    this.transitionTime = transitionTime;
    // this.loadData(this.bannerId);
    this.subscriptions =
      interval(this.transitionTime * 1000)
        .subscribe((val) => {
          let selectedIndex = this.selectedIndex;
          selectedIndex++;
          if (selectedIndex < this.sliderArray.length) {
            this.selectedIndex = selectedIndex;
          } else {
            this.selectedIndex = 0;
          }
          // console.log('called', this.selectedIndex);
        });
  }
  protected loadData(id) {
    if (!this.loading) {
      this.loading = true;
      this.requestService.getSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          if (data.results && data.results.hasOwnProperty('pictures')) {
            let transitionTime = 7;
            if (data.results.transitionTime && data.results.transitionTime > 0) {
              transitionTime = data.results.transitionTime;
            }
            this.initiateTransition(transitionTime);
            this.sliderArray = data.results.pictures;
            this.bannerName = data.results.name;
            // if (!this.bannerHeight || this.bannerHeight === '') {
            //   this.bannerHeight = data.results.height;
            // }
            // if(!this.bannerWidth || this.bannerWidth === ''){
            //   this.bannerWidth = data.results.width;
            // }
          }
          if (!this.bannerHeight || this.bannerHeight === '') {
            this.bannerHeight = '100%';
          }
          // if(!this.bannerWidth || this.bannerWidth === ''){
          //   this.bannerWidth = '100%';
          // }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
        this.loading = false;
      });
    }
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  selectBanner(e, type, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.track) {
      if (this.sessionData?._id)
        this.requestService.logTrackEvent(new EventTrack(EventType.CLICK, ClickType.BILLBOARD, this.roomId, this.sessionData._id, undefined, this._bannerId), (res, err) => { });
      else
        this.requestService.logTrackEvent(new EventTrack(EventType.CLICK, ClickType.BILLBOARD, this.roomId, undefined, undefined, this._bannerId), (res, err) => { });
    }
    if (link && link !== '') {
      if (type === 'room') {
        this.viewData(link);
      } else if (type === 'tile') {
        this.openIframe(link);
      } else if (type === 'page') {
        this.router.navigate(['/' + link], { relativeTo: this.activatedRoute });
      } else {
        window.open(link, '_blank');
      }
    }
  }
  viewDataNew(roomData) {
    this.roomSessionService.openRoom(roomData, (sessionsList) => {
      if (sessionsList) {
        //do nothing
      }
    });
  }
  public viewData(roomId) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData('room', roomId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let roomData = data.results;
          this.roomSessionService.openRoom(roomData, (sessionsList) => {
            if (sessionsList) {
              //do nothing
            }
          });
        }
      });
    }
  }
  openIframe(tileId) {
    let sessionId = undefined;
    let readOnly = true;
    if (this.sessionData?._id) {
      sessionId = this.sessionData._id;
      readOnly = false;
    }
    const dialogRef = this.dialog.open(ModalIframeDialogComponent, {
      width: '1600px',
      data: {
        //dataType: this.dataType,
        //title: this.translate.instant('Details'),
        data: this.requestService.getTileLink(sessionId, tileId, readOnly),
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
      }
    });
  }
}
