<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<mat-card>
			<div class="fieldInput">
				<mat-form-field style="width: 100%;" [class.isVimeo]="returnData['type'] === 'vimeo'">
					<mat-label>Type</mat-label>
					<mat-select (selectionChange)="setType($event.value)" [value]="returnData['type']" style="color: rgba(0,0,0,.54);
						margin-top: 8px;" [disabled]="returnData['isPlaying'] && data['isShortcut']">
						<mat-option value="" disabled>Select Type</mat-option>
						<mat-option *ngFor="let vidType of videoComponentTypes" [value]="vidType.value">
							{{vidType.displayName | translate}}</mat-option>
					</mat-select>
					<!-- <mat-hint align="start" *ngIf="returnData['type'] === 'vimeo'">
						The video displayed for viewers will be synchronized with the video controls applied by the
						session host (i.e.: Play, Pause, and Seek options)
					</mat-hint> -->
				</mat-form-field>
			</div>
			<div class="fieldInput">
				<mat-form-field style="width: 100%;">
					<mat-label>{{ 'Video URL' | translate }}</mat-label>
					<input matInput [disabled]="returnData['isPlaying'] && data['isShortcut']"
						[(ngModel)]="returnData['url']" (change)="editAttribute($event, 'url', $event.target.value)">
					<mat-icon *ngIf="returnData['type'] !== 'hls' && returnData['type'] !== ''" matSuffix
						style="cursor: default;" title="{{shareVideoPlaceHolder}}">info</mat-icon>
				</mat-form-field>
			</div>
			<div class="fieldInput">
				<mat-form-field style="width: 100%;">
					<input matInput placeholder="{{ 'Click-through URL' | translate }}"
						[value]="returnData['clickThruUrl']" [disabled]="returnData['isPlaying'] && data['isShortcut']"
						(change)="editAttribute($event, 'clickThruUrl', $event.target.value)">
				</mat-form-field>
			</div>
			<!-- <div class="fieldInput">
					<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" max="100" placeholder="{{ 'Border Thickness' | translate }}" [(ngModel)]="returnData['frame']" name="frame" (change)="editNumericAttribute($event, 'frame', $event.target.value, 0, 100)">
					</mat-form-field>
				</div> -->

			<div class="fieldInput" *ngIf="!data['isShortcut']">
				<mat-icon *ngIf="!returnData['autoStart'] && returnData['url'] !== ''"
					(click)="editAttributeBoolean($event,'autoStart', true)" title="{{'Auto Play' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['autoStart'] && returnData['url'] !== ''"
					(click)="editAttributeBoolean($event, 'autoStart', false)" title="{{'Auto Play' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box</mat-icon>
				<mat-icon *ngIf="returnData['url'] === ''" title="{{'Auto Play' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
					check_box_outline_blank</mat-icon>
				<label for="autoStart{{subIndex}}" [class.checkbocdisabled]="returnData['url'] === ''"> {{'Auto Play' |
					translate}}</label>
				<mat-icon class="infoCss" *ngIf="!data['source'] || (data['source'] && data['source'] !== 'tasks')"
					title="{{'If Auto Play is checked, the video will automatically play when the attendee enters the session and will not be synchronized with the video controls applied by the session host (i.e.: Play, Pause, and Seek options). Note that only one video can be auto-played at a time. If you set more than one video to auto-play you might experience browser glitches' | translate}}">
					info</mat-icon>
				<mat-icon class="infoCss" *ngIf="data['source'] && data['source'] === 'tasks'"
					title="{{'Only one video can be auto-played at a time. If you set more than one video to auto-play you might experience browser glitches' | translate}}">
					info</mat-icon>
			</div>
			<div class="fieldInput" *ngIf="!data['isShortcut']">
				<mat-icon *ngIf="!returnData['synchost'] && returnData['url'] !== '' && !data['isDesignerOffline']"
					(click)="editAttributeBoolean($event,'synchost', true)" title="{{'Sync with Host' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['synchost'] && returnData['url'] !== '' && !data['isDesignerOffline']"
					(click)="editAttributeBoolean($event, 'synchost', false)" title="{{'Sync with Host' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box</mat-icon>
				<mat-icon *ngIf="returnData['url'] === '' || data['isDesignerOffline']"
					title="{{'Sync with Host' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
					check_box_outline_blank</mat-icon>
				<label for="synchost{{subIndex}}"
					[class.checkbocdisabled]="returnData['url'] === '' || data['isDesignerOffline']"> {{'Sync with
					Host' |
					translate}}</label>
				<mat-icon class="infoCss" *ngIf="data['isDesignerOffline']"
					title="{{'This session mode is offline. You can only sync with the host in Live sessions.' | translate}}">
					info</mat-icon>
			</div>
			<!-- <div class="fieldInput">
				<mat-icon *ngIf="!returnData['autoRepeat']" (click)="editAttribute($event,'autoRepeat', true)"
					title="{{'Auto Repeat' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['autoRepeat']" (click)="editAttribute($event, 'autoRepeat', false)"
					title="{{'Auto Repeat' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box</mat-icon>
				<label for="autoRepeat{{subIndex}}"> {{'Auto Repeat' | translate}}</label>
			</div> -->
			<div class="fieldInput">
				<ng-container *ngIf="(!data['isShortcut'] || (!returnData['isPlaying'] && data['isShortcut'])) ">
					<mat-icon *ngIf="!returnData['loop'] && returnData['type'] !== 'hls' && returnData['url'] !== ''"
						(click)="editAttributeBoolean($event,'loop', true)" title="{{'Loop' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:pointer">
						check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['loop'] && returnData['type'] !== 'hls' && returnData['url'] !== ''"
						(click)="editAttributeBoolean($event, 'loop', false)" title="{{'Loop' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:pointer">
						check_box</mat-icon>
					<mat-icon *ngIf="returnData['type'] === 'hls' || returnData['url'] === ''"
						title="{{'Loop' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box_outline_blank</mat-icon>
				</ng-container>
				<ng-container *ngIf="returnData['isPlaying'] && data['isShortcut']">
					<mat-icon *ngIf="!returnData['loop'] && returnData['type'] !== 'hls' && returnData['url'] !== ''"
						title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
						check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['loop'] && returnData['type'] !== 'hls' && returnData['url'] !== ''"
						title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
						check_box</mat-icon>
					<mat-icon *ngIf="returnData['type'] === 'hls' || returnData['url'] === ''"
						title="{{'Loop' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box_outline_blank</mat-icon>
				</ng-container>
				<label for="loop{{subIndex}}"
					[class.checkbocdisabled]="(returnData['isPlaying'] && data['isShortcut']) || returnData['type'] === 'hls' || returnData['url'] === ''">
					{{'Loop' | translate}}</label>
			</div>
			<div class="fieldInput">
				<ng-container *ngIf="!data['isShortcut'] || (!returnData['isPlaying'] && data['isShortcut'])">
					<mat-icon
						*ngIf="!returnData['controls'] && returnData['type'] === 'vimeo' && returnData['url'] !== ''"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed">check_box_outline_blank
					</mat-icon>
					<mat-icon
						*ngIf="returnData['controls'] && returnData['type'] === 'vimeo' && returnData['url'] !== ''"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed">check_box</mat-icon>
					<mat-icon
						*ngIf="!returnData['controls'] && (returnData['type'] !== 'vimeo' || returnData['url'] === '')"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box_outline_blank</mat-icon>
					<mat-icon
						*ngIf="returnData['controls'] && (returnData['type'] !== 'vimeo' || returnData['url'] === '')"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box</mat-icon>
				</ng-container>
				<ng-container *ngIf="returnData['isPlaying'] && data['isShortcut']">
					<mat-icon
						*ngIf="!returnData['controls'] && returnData['type'] === 'vimeo' && returnData['url'] !== ''"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon
						*ngIf="returnData['controls'] && returnData['type'] === 'vimeo' && returnData['url'] !== ''"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<mat-icon
						*ngIf="!returnData['controls'] && (returnData['type'] !== 'vimeo' || returnData['url'] === '')"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box_outline_blank</mat-icon>
					<mat-icon
						*ngIf="returnData['controls'] && (returnData['type'] !== 'vimeo' || returnData['url'] === '')"
						title="{{'If Hide video controls is checked, the Play, Pause, and Seek options will be removed from the video for all users except the host.' | translate}}"
						style="height: 30px;vertical-align: middle;cursor:not-allowed;color:#9e9e9e">
						check_box</mat-icon>
				</ng-container>
				<label for="controls{{subIndex}}"
					[class.checkbocdisabled]="(returnData['isPlaying'] && data['isShortcut']) || returnData['type'] !== 'vimeo' || returnData['url'] === ''">
					{{'Hide video controls' |
					translate}}</label>
			</div>
			<div class="fieldInput">
				<label style="margin-right: 10px;"
					[class.checkbocdisabled]="returnData['type'] === 'hls' || (returnData['isPlaying'] && data['isShortcut']) || returnData['url'] === ''">Start
					at</label>
				<mat-form-field class="example-full-width" style="margin-right: 10px;">
					<mat-label>{{'mm' | translate}}</mat-label>
					<input matInput type="number" min="0" [(ngModel)]="returnData['startMin']"
						[disabled]="returnData['type'] === 'hls' || (returnData['isPlaying'] && data['isShortcut']) || returnData['url'] === ''"
						(change)="editNumericAttribute($event, 'startMin', $event.target.value, 0, 60)" />
				</mat-form-field>
				<mat-form-field class="example-full-width">
					<mat-label>{{'ss' | translate}}</mat-label>
					<input matInput type="number" min="0" [(ngModel)]="returnData['startSec']"
						[disabled]="returnData['type'] === 'hls' || (returnData['isPlaying'] && data['isShortcut']) || returnData['url'] === ''"
						(change)="editNumericAttribute($event, 'startSec', $event.target.value, 0, 60)" />
				</mat-form-field>

			</div>
			<div class="fieldInput" *ngIf="data['isShortcut'] && data['availableMediaComponents']">
				<mat-form-field style="width: 100%;">
					<mat-label>{{'Media Streaming' | translate}}</mat-label>
					<mat-select (selectionChange)="editSelectAttribute('uid', $event.value)"
						[(ngModel)]="returnData['uid']"
						[disabled]="(returnData['isPlaying'] && data['isShortcut']) || returnData['url'] === ''"
						title="{{'Select' | translate}} {{'Media Streaming' | translate}}">
						<mat-option *ngFor="let itm of data.availableMediaComponents;let idx = index"
							[value]="itm['uid']">{{itm['name']}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="fieldInput noteMedia" *ngIf="!data['isDesigner']">
				* Please play one video at a time to avoid glitches.
			</div>
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button *ngIf="!data['isShortcut']" mat-raised-button (click)="closeModal(returnData)">{{'Confirm' |
			translate}}</button>
		<button *ngIf="data['isShortcut'] && !returnData['isPlaying']" mat-raised-button
			(click)="closeModal(returnData, 'save')">{{'Save' | translate}}</button>
		<button *ngIf="data['isShortcut'] && !returnData['isPlaying']" mat-raised-button
			(click)="closeModal(returnData, 'play')">{{'Play' | translate}}</button>
		<button *ngIf="data['isShortcut'] && returnData['isPlaying']" mat-raised-button
			(click)="closeModal(returnData, 'stop')">{{'Stop' | translate}}</button>
	</div>
	<br />
</div>