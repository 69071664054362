<div #audioPlayer style="position: relative;">
    <audio [id]="mediaPlayerUID + 'player-' + id" controls="controls"
        [style.height.vw]="!isSafari ? height : (isMobile && isIOS) ? '4' : '30px'" [style.width.vw]="width"
        [volume]="volume" controlsList="nodownload" [autoplay]="isAutoPlay" [loop]="isOnLoop"
        [class.audio-safari]="isSafari || (isIOS && isMobile)">
        <source [src]="src" [type]="type" />
    </audio>
    <div *ngIf="isWebkit && !(isIOS && isMobile)" class="overlay-3-dots" [style.height.vw]="height"></div>
    <div *ngIf="isSafari || (isIOS && isMobile)"
        [ngStyle]="{'height': isIOS && isMobile ? '3vw' : 'inherit', 'bottom': isIOS && isMobile ? '0' : 'inherit'}"
        class="overlay-3-dots-safari"></div>
</div>