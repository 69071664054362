import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ToolTipRendererDirective, CustomToolTipComponent } from './custom-tool-tip';

@NgModule({
    imports: [
        CommonModule, 
        MatCardModule, 
        MatGridListModule, 
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        MatMenuModule,
        FlexLayoutModule.withConfig({addFlexToParent: false})
    ],
    declarations: [CalendarComponent, ToolTipRendererDirective, CustomToolTipComponent],
    exports: [CalendarComponent, ToolTipRendererDirective, CustomToolTipComponent]
})
export class CalendarModule {}
