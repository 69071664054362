<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
		<div class="userInfo select-page-body" *ngIf="metaFieldSetting">
      <mat-card>
				<mat-card-content>
					<div fxLayout="row" fxLaout.lt-sm="column" fxFlex >
						<div fxFlex>
							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex >
								<div fxFlex="50">
									<mat-form-field style="width:100%">
										<input matInput [required]="!metaFieldSetting['name'].nullable" name="{{metaFieldSetting['name'].name}}" ng-model="metaFieldSetting['name'].name" [(ngModel)]="data.data['name']" [required]="!metaFieldSetting['name'].nullable"  placeholder="{{metaFieldSetting['name'].displayName | translate}}"  [errorStateMatcher]="esMatcher">
										<mat-error *ngIf="!metaFieldSetting['name'].nullable && (data.data[metaFieldSetting['name'].name] === undefined || data.data[metaFieldSetting['name'].name] === '')">
											{{metaFieldSetting['name'].displayName | translate}} is required.
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex >
								<div fxFlex>
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex  style="margin-bottom:5px;">
										<div fxFlex >
											<h3 style="color: #a0a0a0;font-size: 13px;">Media List</h3>
										</div>
										<div fxFlex align="right" fxLayoutAlign="end center">
										<button (click)="insertVimeoPlaylist()" mat-button color="primary" title="{{'Import Vimeo Playlist' | translate}}" style="margin-right:10px;">
											{{'Import Vimeo Playlist' | translate}}
											</button>
												<!-- <button (click)="importVimeoVideo('253989945')" mat-button color="primary" title="{{'Import Vimeo Playlist' | translate}}" style="margin-right:10px;">
											{{'Import Vimeo Video' | translate}}
											</button> -->
											<button (click)="addVideo()" mat-button color="primary" title="{{'Add Media' | translate}}"  style="margin-right:10px;">
											{{'Add Media' | translate}}
											</button>
											<button (click)="addAudioFromGallery()" mat-button color="primary" title="{{'Add Audio' | translate}}">
											{{'Add Audio' | translate}}
											</button>
										</div>
									</div>
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex >
										<div fxFlex class="theDataSelected">
											<div fxLayout="row" fxLaout.lt-sm="column" fxFlex >
												<div fxFlex >
													<div
														cdkDropList
														#doneList="cdkDropList"
														[cdkDropListData]="videoList"
														class="example-list"
														(cdkDropListDropped)="drop($event)">
															<div class="example-box-selected" style="border-bottom: solid 1px #ccc;cursor: default;" *ngFor="let vid of videoList;let index = index" cdkDrag>
																<div fxLayout="row" fxLaout.lt-sm="column" fxFlex fxLayoutGap="10px">
																	<div fxFlex="3">
																		<div class="example-handle" cdkDragHandle style="cursor: move; margin: 50% auto;color:#242852">
																	    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
																	      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
																	      <path d="M0 0h24v24H0z" fill="none"></path>
																	    </svg>
																	  </div>
																	</div>
																	<!-- <div fxFlex="10">
																		<img *ngIf="vid.thumbnail !== ''" src="{{vid.thumbnail}}" style="max-width:80px;max-height:80px;">
																	</div> -->
																	<div fxFlex>
																		<!-- <input type="text" [(ngModel)]="vid.name" class="form-control input-sm btn_before_text" placeholder="{{'Title' | translate}}" /> -->
																		<mat-form-field style="width:100%">
																	    <input matInput [required]="true" name="name" ng-model="name" [(ngModel)]="vid.name"  placeholder="{{'Title' | translate}}" [errorStateMatcher]="esMatcher"
																			maxlength="100">
																			<mat-hint align="end">{{vid.name.length || 0}}/100</mat-hint>
																			<mat-error *ngIf="vid.name === undefined || vid.name === ''">
																				{{'Title' | translate}} is required.
																			</mat-error>
																		</mat-form-field>
																	</div>
																	<div fxFlex>
																		<mat-form-field style="width:100%">
															        <mat-label>{{'Type' | translate}}</mat-label>
															        <mat-select name="type" [required]="true" (selectionChange)="setType(index, $event.value)" [(ngModel)]="vid.type">
															          <mat-option value="" >Select {{'Type' | translate}}</mat-option>
															          <mat-option *ngFor="let vtype of videosTypeKeys" [value]="vtype">{{videosType[vtype] | translate}}</mat-option>
															        </mat-select>
															        <mat-error *ngIf="vid.type === undefined || vid.type === ''">
															          {{'Type' | translate}} is required.
															        </mat-error>
															      </mat-form-field>
																	</div>
																	<div fxFlex>
																		<mat-form-field style="width:100%">
																	    <input matInput type="text" [required]="true" name="url" ng-model="url" [(ngModel)]="vid.url" placeholder="{{'URL' | translate}}"  [errorStateMatcher]="esMatcher" pattern="^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$">
																			<mat-hint align="end">{{'URL format' | translate}}</mat-hint>
																			<mat-error *ngIf="vid.url === undefined ||vid.url === ''" >
																				{{'URL' | translate}} is required.
																			</mat-error>
																		</mat-form-field>
																	</div>
																	<div fxFlex="5" fxLayoutAlign="end center" style="text-align: right;">
																		<button (click)="removeVideo(index)" mat-icon-button color="primary" title="{{'Remove Video' | translate}}">
																		<mat-icon>delete</mat-icon>
																	</button>
																	</div>
																</div>
															</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
      </mat-card>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')" [disabled]="loading" >{{'Create' | translate}}</button>
			<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading" >{{'Save' | translate}}</button>
	</div>
	<br/>
</div>
