<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
  <div fxFlex="25">
      <app-custom-select-create-autocomplete #blueprintList [filters]="filterData"
          [dataType]="dataType" [value]="selectedFilter" [dataTypeDisplay]="dataTypeDisplay"
          [placeholder]="'Available Events'" [canDelete]="permissions.canDeleteEvent" [simple]="true"
          (onSelectReturn)="selectBlueprint($event._id)" [pageSize]="1000" [hasAddBtn]="false"
          [hasEditBtn]="false"
          [hasClearBtn]="true"
          [canNewList]="undefined">
      </app-custom-select-create-autocomplete>
  </div>
  <div fxFlex="40">
      
  </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
  <div fxFlex>
    <div style="height: 100%; width: 100%;border: 1px solid gray;">
      <app-calendar *ngIf="dataArray" [showAddButton]="true" 
      [language]="'en'"
      [dataSource]="dataSource"
      [eventsActions]="eventsActions"
      [canAddEvent]="permissions.canAddEvent"
      [bodyHeight]="'calc(100vh - 300px)'"
      [contentTemplate]="customEventTemplate"
      (dayEvents)="selectDay($event)"
      (dayEvent)="selectEvent($event)"
      (newEvent)="addEvent($event)"
      (dayShift)="dayShift($event)"
      ></app-calendar>
    </div>
  </div>
</div>
<ng-template #customEventTemplate let-contentTemplateData="contentTemplateData">
  <div class="eventOverlay" *ngIf="contentTemplateData">
    <div class="eventOverlayTitle" *ngIf="contentTemplateData.name">
      <div >{{contentTemplateData.name}}</div>
    </div>
    <!-- <div class="eventOverlayContent" *ngIf="contentTemplateData.description">
      <div>Description: </div>
      <div>{{contentTemplateData.description}}</div>
    </div> -->
    <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate && contentTemplateData.enddate">
      <div>From {{contentTemplateData.startdate | date: 'hh:mm aa'}} to {{contentTemplateData.enddate | date: 'hh:mm aa'}}</div>
    </div>
    <!-- <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate">
      <div>Start: </div>
      <div>{{contentTemplateData.startdate | date: 'dd-MM-yyyy hh:mm aa'}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.enddate">
      <div>End: </div>
      <div>{{contentTemplateData.enddate | date: 'dd-MM-yyyy hh:mm aa'}}</div>
    </div> -->
  </div>
</ng-template>
<ng-template #customViewEventTemplate let-contentTemplateData="contentTemplateData">
  <mat-list class="eventClass">
    <mat-list-item class="eventType">
      <app-enum-view [enumValue]="contentTemplateData.eventType" [enumList]="metaFieldSetting['eventType'].enum"></app-enum-view>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
      <div class="eventDescription">
        {{contentTemplateData.description}}
      </div>
    </mat-list-item>
    <mat-list-item>
        <span>{{metaFieldSetting['startdate'].displayName | translate}}:</span> {{contentTemplateData.startdate | date: 'dd-MM-yyyy hh:mm aa'}}
    </mat-list-item>
    <mat-list-item>
        <span>{{metaFieldSetting['enddate'].displayName | translate}}:</span> {{contentTemplateData.enddate | date: 'dd-MM-yyyy hh:mm aa'}}
    </mat-list-item>
    <mat-list-item>
        <span>{{metaFieldSetting['settingType'].displayName | translate}}:</span> 
        <app-enum-view [enumValue]="contentTemplateData.settingType" [enumList]="metaFieldSetting['settingType'].enum"></app-enum-view>
    </mat-list-item>
    <mat-list-item>
        <mat-slide-toggle
          class="example-margin"
          [color]="'primary'"
          [checked]="contentTemplateData.opentoall"
          [disabled]="true">
          {{metaFieldSetting['opentoall'].displayName | translate}}
      </mat-slide-toggle>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.attendees && contentTemplateData.attendees.length > 0" class="attendeeList">
      <div>
        <div><span style="font-weight: bold;">{{metaFieldSetting['attendees'].displayName | translate}}:</span></div>
        <div class="attendeeListItem" *ngFor="let ett of contentTemplateData.attendees">
          {{ett.name}}
        </div>
      </div>
    </mat-list-item>
    <mat-list-item>
      <mat-slide-toggle
          class="example-margin"
          [color]="'primary'"
          [checked]="contentTemplateData.enableChat"
          [disabled]="true">
          {{metaFieldSetting['enableChat'].displayName | translate}}
      </mat-slide-toggle>
      <!-- <mat-checkbox class="example-margin"
                    [checked]="contentTemplateData.enableChat" [disabled]="true"
                    [color]="'primary'">
                    {{metaFieldSetting['enableChat'].displayName | translate}}
      </mat-checkbox> -->
    </mat-list-item>
  </mat-list>
</ng-template>