import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { LayoutUtilsService, TokBoxService } from "src/app/shared";
import videojs from 'video.js';

@Component({
    selector: 'app-hls-media',
    templateUrl: './hls-media.component.html',
    styleUrls: ['./hls-media.component.scss']
})

export class HlsComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private renderer: Renderer2, private deviceService: DeviceDetectorService, private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService) { }

    private subscriptions: Subscription[] = [];
    private dialog: any = undefined;

    public video: any = undefined;

    @Input() uuid: string = undefined;
    @Input() zIndex: string = undefined;
    @Input() showError: boolean = false;

    @Output() pauseDialog = new EventEmitter<any>();

    ngOnInit() {
        this.subscriptions.push(this.opentokService.startHls.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.startHLS(video);
            }
        }));

        this.subscriptions.push(this.opentokService.stopHls.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.stopHls(video);
            }
        }));

        this.subscriptions.push(this.opentokService.playHls.subscribe(video => {
            if (video !== undefined && video.uuid === this.uuid) {
                this.video.player.play().catch((e) => {
                    this.handleHlsError(e);
                });
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.opentokService.hlsLoaded.next(true);
    }

    startHLS(video) {
        if (document.getElementById('video-hls-' + video.uid) && !(this.video?.isPlaying && video.url === this.video.url)) {
            let element = document.createElement('video');
            this.renderer.addClass(element, 'video-js');
            this.renderer.addClass(element, 'vjs-default-skin');
            this.renderer.setProperty(element, 'controls', 'true');
            this.renderer.appendChild(document.getElementById('video-hls-' + video.uid), element);

            let player = videojs(element, {
                loadingSpinner: false,
                errorDisplay: false,
                controls: false,
                controlBar: false
            });
            player.currentTime(video.startAt);
            player.src({
                src: video.url,
                type: 'application/x-mpegURL',
                enableLowInitialPlaylist: true
            });
            player.volume(this.opentokService.preferredVolume / 100);
            video.player = player;
            video.isPlaying = true;
            player.on('error', (e) => this.handleHlsError(e.target.player.error_));
            player.play().catch((e) => {
                this.handleHlsError(e);
            });

            this.video = video;
            this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
        }
    }

    private handleHlsError(error) {
        // console.log('wefe', error);
        // let error = player.error();
        if (error && (error.code == '4' || error.code == '2' || error.code == '9')) {
            if (this.showError || true)
                if (!this.dialog) {
                    this.dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('The media could not be loaded. Please make sure the format is supported and the stream is live. This might also be a server or network problem.'), {
                        overlayClickToClose: false,
                        showCancelButton: false,
                        declineText: this.translate.instant('OK')
                    }, 'fit-content');
                    this.dialog.afterClosed().subscribe(res => {
                        if (res) {
                            this.dialog = undefined;
                        }
                    });
                }
        }
        else
            if (this.video.isPlaying)
                if (this.deviceService.browser.toLowerCase().indexOf('safari') === -1)
                    this.pauseDialog.emit({ uid: this.video.uid });
                else {
                    if (document.querySelector('#video-hls-' + this.video.uid + ' video'))
                        document.querySelector('#video-hls-' + this.video.uid + ' video').setAttribute('controls', 'controls');
                }
    }

    stopHls(video: any) {
        if (this.video?.player) {
            video.isPlaying = false;
            if (document.getElementById('video-hls-' + video.uid))
                document.getElementById('video-hls-' + video.uid).innerHTML = '';

            this.video = video;
            this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
        }
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}