import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, ChangeDetectorRef, AfterContentChecked, AfterContentInit, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ModalViewImageDialogComponent } from "src/app/shared/components/custom-view-image-dialog/custom-view-image-dialog.component";
import { RequestService } from "src/app/shared/services/request.service";
import { getOtherImage } from "../../helpers";

@Component({
    selector: 'app-rooms-gallery',
    templateUrl: './rooms-gallery.component.html',
    styleUrls: ['./rooms-gallery.component.scss']
})

export class RoomsGalleryComponent implements OnInit, OnDestroy {
    public apiCallSubscription: Subscription = undefined;
    public errorMessage: string = '';
    public loading: boolean = false;
    public dataType: any = 'room';
    public images: any = [];
    selectData: Array<any> = [];
    selectDataMatrix: Array<any> = [];
    pageSize: number = 10000;
    pageNumber = 1;
    orderDir = 'asc';
    orderBy = 'name';
    searchText = '';
    @Output() onSelectReturn = new EventEmitter<any>();
    constructor(private dialog: MatDialog, private requestService: RequestService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.loadDataSearch();
    }

    ngOnDestroy() { }

    openRoom(image): void {
        this.onSelectReturn.emit(image._id)
    }
    public loadDataSearch(setName: boolean = false) {
        if (this.loading && this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
            this.loading = false;
        }
        if (!this.loading) {
            this.loading = true;
            this.errorMessage = '';
            let filterConfiguration = { '$and': [] };
            filterConfiguration['$and'].push({ 'organizationId._id': { '$eq': this.requestService.orgId } });

            let include = undefined;
            let fieldKeys = ['name', 'pictureLink', 'updatedAt'];
            this.apiCallSubscription = this.requestService.getDataList(this.dataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: this.searchText, perpage: this.pageSize, filter: filterConfiguration, termfields: [], fieldKeys: fieldKeys, include: include }, (data, error) => {
                if (error) {
                    this.errorMessage = error;
                    //   this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
                }
                if (data) {
                    let selectData = data.results.map((itm) => {
                        itm['title'] = itm.name.substring(0, 100);
                        if (itm.name.length > 100) {
                            itm['title'] = itm['title'] + '...'
                        }
                        let tempImg = itm.pictureLink;
                        itm['pictureLink'] = this.getPictureLink(itm.pictureLink);
                        itm['thumbnail'] = getOtherImage('', tempImg, '_thumbnail');
                        itm['isWide'] = Math.random() > 0.5 ? true : false;
                        itm['isTall'] = Math.random() > 0.5 ? true : false;
                        return itm;
                    });
                    this.selectData = selectData;
                } else {
                    this.selectData = [];
                }
                this.selectDataMatrix = this.getMatrix(JSON.parse(JSON.stringify(this.selectData)));
                console.log(this.selectDataMatrix);
                this.loading = false;
            }, '');
        }
    }
    getMatrix(dataList) {
        dataList.unshift({ _id: 'create', title: 'New Room', name: 'New Room' });
        let dataListLength = dataList.length;
        let dataListColumnLength = Math.floor(dataListLength / 4);
        let dataListColumnRemainder = dataListLength % 4;
        let urlNewDataList = this.splitArray(dataList, dataListColumnLength, dataListColumnRemainder);

        return urlNewDataList;
    }
    splitArray(array, part, remainder) {
        var tmp = [];
        let addUp = 0;
        if (remainder > 0) {
            addUp = 1;
        }
        for (var i = 0; i < array.length; i = i + part + addUp) {
            if (remainder === 0) {
                addUp = 0;
            }
            tmp.push(array.slice(i, i + part + addUp));
            remainder = remainder - 1;
        }
        return tmp;
    }
    getPictureLink(pictureLink) {
        let urlNew = pictureLink;
        if (!urlNew || urlNew === '') {
            return 'assets/images/default-room.jpg';
        }
        return urlNew;
    }
    getThumbnail(pictureLink) {
        let urlNew = '';
        if (pictureLink && pictureLink !== '') {
            let pictureLinkList = pictureLink.split("/");
            let idx = 1;
            for (let itm of pictureLinkList) {
                if (idx === pictureLinkList.length) {
                    urlNew = urlNew + 'thumbnail_';
                }
                urlNew = urlNew + itm + '/';
                idx++;
            }
        }
        return urlNew;
    }
}