import { Component, OnInit, Input, Renderer2, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import { RequestService, LayoutUtilsService, RoomSessionService, PageScopeService, TokBoxService } from '../../../../shared/services';
import { LayoutComponents } from 'src/app/shared/components/layout-components/components';
import { DeviceDetectorService } from "ngx-device-detector";
import { DefaultSettings } from "src/app/shared/components/layout-components/defaultSettings";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { roundDecimal } from '../../../../shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../../../../shared/components/iframe-dialog/iframe-dialog.component';
import { MediaLibraryContainerDialogComponent } from '../../../../shared/components/media-library-container-dialog/media-library-container-dialog.component';
import { ImageGalleryContainerDialogComponent } from '../../../../shared/components/image-gallery-container-dialog/image-gallery-container-dialog.component';
import { MediaLibraryComponent } from '../../media-library/media-library.component';
import { ChatService } from 'src/app/shared/services/chat.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-screen-layout-view',
  templateUrl: './screen-layout-view.component.html',
  styleUrls: ['./screen-layout-view.component.scss']
})
export class ScreenLayoutViewComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public loadingPicture: boolean = false;
  public defaultSetting = DefaultSettings.defaultSetting;
  public isMobile: boolean = false;
  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;
  public joined: boolean = false;
  public sessionLayoutSettings: any = undefined;

  private layoutVideoComponents: any = new Map();
  private layoutAudioComponents: any = new Map();
  private mediaLibraries: any = undefined;

  public resizingObjectX: any = {};
  public resizingObjectY: any = {};
  public isTablet: boolean = false;
  public isIOS: boolean = false;

  private allMediaComponentsLoaded: any = [];
  private allMediaComponentsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mediaStarted: boolean = false;

  @Input() bgBackgroundColor: any = undefined;
  @Input() bgBackgroundLink: any = undefined;
  @Input() settingObject: any = undefined;
  @Input() sessionData: any = undefined;
  @Input() roomId: string = undefined;
  @Input() roomName: string = undefined;

  @ViewChildren(MediaLibraryComponent) mediaLibraryChildren: QueryList<MediaLibraryComponent>;

  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private roomSessionService: RoomSessionService, private pageScope: PageScopeService,
    private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private chatService: ChatService, public deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
    }
    this.isTablet = this.deviceService.isTablet();
    this.isIOS = this.deviceService.os.toLowerCase() == 'ios' ? true : false;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.bgUpdate(this.bgBackgroundLink, this.bgBackgroundColor);
        }
      })
    );

    this.subscriptions.push(this.opentokService.showPauseDialog.subscribe(value => {
      if (value) {
        this.showPauseDialogForAudio(true);
      }
    }));

    this.subscriptions.push(this.roomSessionService.changeVolume.subscribe(volume => {
      if (volume !== undefined) {
        this.opentokService.setVolume(volume, undefined, this.mediaLibraries, this.layoutVideoComponents);
      }
    }));

    // this.subscriptions.push(this.opentokService.sessionConnected.subscribe(isConnected => {
    //   if (isConnected) {
    //     this.joined = true;
    //     this.detectChanges();
    //     this.startMedia();
    //   }
    // }));

    // let hasMediaInSession = false;
    if (this.sessionData) {
      this.sessionLayoutSettings = this.opentokService.setLayoutSettings(this.sessionData, this.sessionData.active, undefined, 'attendee');
      // hasMediaInSession = this.sessionLayoutSettings['columns'][0].components.find(i => (i.name === 'guest-camera' || i.name === 'presenter-camera' || i.name === 'camera' || i.name === 'videolibrary' || i.name === 'panelView' || i.name === 'audio' || i.name === 'defaultvideo') && i.active);
    }

    // let hasMediaInRoom = this.settingObject['columns'][0].components.find(i => (i.name === 'defaultvideo' || i.name === 'audio' || i.name === 'videolibrary') && i.active);

    // if (hasMediaInSession) {
    //   this.joined = true;
    // }
    // else if (hasMediaInRoom) {
    //   this.joined = false;
    // }
    // else {
    //   this.joined = true;
    // }

    this.layoutAudioComponents = this.settingObject['columns'][0].components.filter(i => i.name === 'audio' && i.active);

    this.settingObject['columns'][0].components.filter(i => i.name === 'defaultvideo' && i.active).forEach((element, index) => {
      // if (element.url && element.type !== 'hls') {
      //   if (element.url.indexOf('?') == -1)
      //     element.url += '?';
      //   element.url += '&enablejsapi=true';
      //   if (element.controls)
      //     element.url += '&controls=0';
      //   if (element.autoStart) {
      //     element.url = element.url + '&autoplay=1&mute=1';
      //   }
      // }

      element.startAt = element.startMin * 60 + element.startSec;

      this.layoutVideoComponents.set(element.uid, {
        videoLabel: 'Video ' + (index + 1),
        element: element
      });
    });

    this.layoutVideoComponents.forEach((video, key) => {
      if (video.element.type === 'facebook') {
        this.allMediaComponentsLoaded.push({ type: video.element.type, loaded: false });
        this.subscriptions.push(this.opentokService.facebookLoaded.subscribe(value => {
          if (value) {
            this.allMediaComponentsLoaded.map(i => {
              if (i.type === video.element.type) {
                i.loaded = true;
              }
            });
            // this.opentokService.startFacebook.next(video.element);
            this.allMediaComponentsLoadedSubject.next(true);
          }
        }));
      }
      else if (video.element.type === 'hls') {
        this.allMediaComponentsLoaded.push({ type: video.element.type, loaded: false });
        this.subscriptions.push(this.opentokService.hlsLoaded.subscribe(value => {
          if (value) {
            this.allMediaComponentsLoaded.map(i => {
              if (i.type === video.element.type) {
                i.loaded = true;
              }
            });
            // this.opentokService.startHls.next(video.element);
            this.allMediaComponentsLoadedSubject.next(true);
          }
        }));
      }
      else if (video.element.type === 'youtube') {
        this.allMediaComponentsLoaded.push({ type: video.element.type, loaded: false });
        this.subscriptions.push(this.opentokService.youtubeLoaded.subscribe(value => {
          if (value) {
            this.allMediaComponentsLoaded.map(i => {
              if (i.type === video.element.type) {
                i.loaded = true;
              }
            });
            // this.opentokService.startYoutube.next(video.element);
            this.allMediaComponentsLoadedSubject.next(true);
          }
        }));
      }
      else if (video.element.type === 'vimeo') {
        this.allMediaComponentsLoaded.push({ type: video.element.type, loaded: false });
        this.subscriptions.push(this.opentokService.vimeoLoaded.subscribe(value => {
          if (value) {
            this.allMediaComponentsLoaded.map(i => {
              if (i.type === video.element.type) {
                i.loaded = true;
              }
            });
            // this.opentokService.startVimeo.next(video.element);
            this.allMediaComponentsLoadedSubject.next(true);
          }
        }));
      }
    });

    this.subscriptions.push(this.allMediaComponentsLoadedSubject.subscribe(value => {
      if (value) {
        if (!this.allMediaComponentsLoaded.find(i => !i.loaded) && !this.mediaStarted) {
          this.mediaLibraryChildren?.changes.subscribe((r) => {
            this.mediaLibraries = this.mediaLibraryChildren;
          });
          this.mediaStarted = true;

          setTimeout(() => this.startMedia(), 3000);
        }
      }
    }));
  }

  // join() {
  //   this.joined = true;
  //   this.detectChanges();
  //   this.startMedia();
  // }

  startMedia() {
    this.layoutVideoComponents.forEach((video, key) => {
      // if (video.element.autoStart) {
      switch (video.element.type) {
        case 'vimeo':
          this.opentokService.startVimeo.next(video.element);
          break;
        case 'youtube':
          this.opentokService.startYoutube.next(video.element);
          break;
        case 'hls':
          this.opentokService.startHls.next(video.element);
          break;
        case 'facebook':
          this.opentokService.startFacebook.next(video.element);
          break;
      }
      // }
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.chatService.toggleCommunicate.next(false);
    this.renderer.removeStyle(document.body, 'background');
    this.renderer.removeStyle(document.body, 'overflow-y');
    this.subscriptions.forEach(el => el.unsubscribe());
    this.layoutAudioComponents = new Map();
    this.layoutVideoComponents = new Map();
  }
  // dragDropped($event, target) {
  //   let position = $event.source.getFreeDragPosition();
  //   console.log('dragDropped $event', $event);
  //   console.log('dragDroppedposition', position);
  // }
  bgUpdate(bgBackgroundLink, bgBackgroundColor) {
    let styleBG = "background: ";
    if (bgBackgroundColor !== '') {
      styleBG = styleBG + bgBackgroundColor;
    } else {
      styleBG = styleBG + '#efefef';
    }

    let backgroundPosition = 'center 55px';
    if (this.isMobile) {
      backgroundPosition = "center 0px";
    }
    if (this.pageScope.onRecordingMode)
      backgroundPosition = 'center center';

    if (bgBackgroundLink !== '') {
      styleBG = styleBG + " url(" + bgBackgroundLink + "); background-repeat:no-repeat; background-size:100%; background-attachment: fixed; background-position: " + backgroundPosition + "; overflow-y: hidden;";
      this.renderer.setAttribute(document.body, 'style', styleBG);
    } else {
      styleBG = styleBG + ";"
      this.renderer.setAttribute(document.body, 'style', styleBG);
    }
  }
  hasComponent(type) {
    for (let cmp of this.settingObject['columns'][0]['components']) {
      if (cmp.name === type) {
        return true
      }
    }
    return false;
  }
  getHeight(h, w, ratioSize, componentName?: string) {
    if (componentName === 'audio' && ((this.isMobile || this.isTablet) && this.isIOS)) {
      return '4vw';
    }
    else {
      if (ratioSize) {
        return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'vw';
      } else {
        return h + 'vw';
      }
    }
  }
  getZ(z, index, subIndex) {
    return z;
  }
  getX(x, mainBoundary, idx) {
    let currentValue = Math.round((x * mainBoundary.offsetWidth) / 100);
    if (!this.resizingObjectX[idx]) {
      this.resizingObjectX[idx] = currentValue;
    }
    return this.resizingObjectX[idx];
  }
  onResize(e) {
    this.resizingObjectX = {};
    this.resizingObjectY = {};
  }

  getY(y, mainBoundary, idx) {
    let currentValue = Math.round((y * mainBoundary.offsetWidth) / 100);
    if (!this.resizingObjectY[idx]) {
      this.resizingObjectY[idx] = currentValue;
    }
    return this.resizingObjectY[idx];
  }
  getGridBorder(total, idx) {
    return total % idx == 0 ? '0px' : '5px';
  }
  getTileLink(id) {
    return this.requestService.getTileLink(undefined, id, true);
  }
  /**
  * getShapePoint function
  */
  getShapePoint(svg, mainBoundary, index, subIndex) {
    let points = '';
    for (let pnt of svg) {
      points = points + this.getPolyX(pnt.x, mainBoundary, index, subIndex) + ',' + this.getPolyY(pnt.y, mainBoundary, index, subIndex) + ' ';
    }
    return points;
  }
  /**
  * getCircleR function
  */
  getPositiveValue(x1, x2) {
    let r = 0;
    if (x1 > x2) {
      r = x1 - x2;
    } else if (x1 > x2) {
      r = x2 - x1;
    }
    return r;
  }
  getPolyX(x, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((x * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((x * mainBoundary.offsetWidth) / 100);
  }
  getPolyY(y, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((y * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((y * mainBoundary.offsetWidth) / 100);
  }
  cancelThru($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
  numberReturn(length) {
    return new Array(length);
  }
  selectPicture(e, cmp, type, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (link && link !== '') {
      if (type === 'room') {
        this.viewData(link);
      } else if (type === 'tile') {
        this.openIframe(link);
      } else if (type === 'page') {
        this.router.navigate(['/' + link], { relativeTo: this.activatedRoute });
      } else if (type === 'imagegallery') {
        this.openImagegalleryDialog(link, cmp);
      } else if (type === 'medialibrary') {
        this.openmedialibraryDialog(link, cmp);
      } else {
        window.open(link, '_blank');
      }
    }
    else if (type === 'communicate') {
      this.chatService.toggleCommunicate.next(true);
    }
  }

  public viewData(roomId) {
    if (!this.loadingPicture) {
      this.loadingPicture = true;
      this.requestService.getSingleData('room', roomId, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loadingPicture = false;
        if (data) {
          let roomData = data.results;
          this.roomSessionService.openRoom(roomData, (sessionsList) => {
            if (sessionsList) {
              //do nothing
            }
          });
        }
      });
    }
  }
  openIframe(tileId) {
    let readOnly = true;
    let sessionId = undefined;
    if (this.sessionData && this.sessionData._id) {
      readOnly = false;
      sessionId = this.sessionData._id;
    }
    const dialogRef = this.dialog.open(ModalIframeDialogComponent, {
      width: '1600px',
      data: {
        //dataType: this.dataType,
        //title: this.translate.instant('Details'),
        data: this.requestService.getTileLink(sessionId, tileId, readOnly),
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
      }
    });
  }
  openImagegalleryDialog(galleryId, cmp) {
    let width = '55vw';
    if (this.isMobile) {
      width = '100%';
    }
    const dialogRef = this.dialog.open(ImageGalleryContainerDialogComponent, {
      width: width,
      data: {
        title: cmp.metadata.linkDisplay,
        galleryId: galleryId,
        libraryId: cmp.uid
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //if (result) {
      //}
    });
  }
  openmedialibraryDialog(mediaId, cmp) {
    let width = '65vw';
    if (this.isMobile || this.isTablet) {
      width = '100%';
    }
    const dialogRef = this.dialog.open(MediaLibraryContainerDialogComponent, {
      width: width,
      data: {
        title: cmp.metadata.linkDisplay,
        width: '55',
        libraryId: cmp.uid,
        loop: cmp.metadata.loop,
        volume: cmp.metadata.volume,
        autoPlay: cmp.metadata.autoPlay,
        mediaId: mediaId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //if (result) {
      //}
    });
  }

  showPauseDialogForAudio(show: boolean) {
    if (show) {
      this.opentokService.promptPauseDialog('', false, this.renderer, this.layoutVideoComponents, this.layoutAudioComponents);
    }
  }

  showPauseDialog(uuid: string, isHost: boolean) {
    this.opentokService.promptPauseDialog(uuid, isHost, this.renderer, this.layoutVideoComponents, this.layoutAudioComponents);
  }

  private detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
