import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { LayoutUtilsService, RoomSessionService, TokBoxService } from "src/app/shared";
import Player from '@vimeo/player';

@Component({
    selector: 'app-vimeo-media',
    templateUrl: './vimeo-media.component.html',
    styleUrls: ['./vimeo-media.component.scss']
})

export class VimeoComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private renderer: Renderer2, private deviceService: DeviceDetectorService, private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private translate: TranslateService, private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private roomSessionService: RoomSessionService) { }

    private subscriptions: Subscription[] = [];
    private dialog: any = undefined;
    private interval: any = undefined;

    public video: any = undefined;

    @Input() uuid: string = undefined;
    @Input() sessionId: string = undefined;
    @Input() isHost: boolean = false;
    @Input() isProducer: boolean = false;
    @Input() session: OT.Session = undefined;
    @Input() zIndex: string = undefined;

    @Output() pauseDialog = new EventEmitter<any>();

    ngOnInit() {
        this.subscriptions.push(this.opentokService.startVimeo.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.startVimeo(video);
            }
        }));

        this.subscriptions.push(this.opentokService.stopVimeo.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.stopVimeo(video);
            }
        }));

        this.subscriptions.push(this.opentokService.playPauseVimeo.subscribe(video => {
            if (video !== undefined && video.uuid === this.uuid && this.video?.player) {
                if (video.play)
                    this.video.player.play().then(() => {
                        this.renderer.addClass(document.getElementById('video-vimeo-' + video.uuid), 'fade-in');
                    }).catch((e) => {
                        if (e.name !== 'PlayInterrupted')
                            this.pauseDialog.emit({ uid: video.uuid, isHost: this.isHost });
                    });
                else
                    this.video.player.pause();
            }
        }));

        this.subscriptions.push(this.opentokService.setVimeoTime.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid && this.video?.player) {
                this.video.player.setCurrentTime(video.startAt);
            }
        }));

        this.subscriptions.push(this.roomSessionService.changeVolume.subscribe(volume => {
            if (this.video?.player && typeof this.video.player.setVolume === 'function')
                this.video.player.setVolume(volume / 100);
        }));
    }

    ngOnDestroy() {
        if (this.dialog)
            this.dialog.close();
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.opentokService.vimeoLoaded.next(true);
    }

    startVimeo(video: any) {
        if (!this.video?.isPlaying && video.url !== this.video?.url) {
            // video.isPlaying = true;
            // if (video.synchost) {
            //     video.controls = true;
            // }

            if (!video.player) {
                if (video.url) {
                    try {
                        if (video.url.indexOf('?') == -1)
                            video.url += '?';
                        video.url += '&enablejsapi=true';
                        if (video.controls)
                            video.url += '&controls=0';
                        video.url += '&autoplay=1&mute=1';

                        if (document.getElementById('video-vimeo-' + video.uid))
                            document.getElementById('video-vimeo-' + video.uid).innerHTML = '';
                        let element = this.renderer.createElement('div');

                        let options: any = {
                            loop: video.loop,
                            autoplay: video.autoStart,
                            url: video.url.replace('/embed', ''),
                            autopause: false
                        };

                        // if (!this.isImmersive) {
                        //     options.height = document.getElementById('video-container-' + video.uid).parentNode['offsetHeight'] + 'px';
                        //     options.width = document.getElementById('video-container-' + video.uid).parentNode['offsetWidth'] + 'px';
                        // }
                        // else {
                        this.renderer.addClass(element, 'vimeo-3d');
                        // }

                        this.renderer.appendChild(document.getElementById('video-vimeo-' + video.uid), element);

                        if (!this.isHost && video.controls && !((this.deviceService.isTablet() || this.deviceService.isMobile()) && this.deviceService.getDeviceInfo().browser == 'Safari')) {
                            options['background'] = 1;
                        }

                        video.player = new Player(element, options);

                        video.player.on('ended', () => {
                            if (this.sessionId) {
                                if (this.interval) {
                                    clearInterval(this.interval);
                                }
                                localStorage.removeItem('stellar-video-' + video.uid + '-' + this.sessionId);
                                localStorage.removeItem('stellar-now-' + video.uid + '-' + this.sessionId);
                            }
                        });
                        video.player.on('volumechange', () => {
                            video.player.getMuted().then((muted) => {
                                video.muted = muted;
                                this.video = video;
                                this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
                            });
                        });
                        video.player.on('pause', () => {
                            if (this.isHost) {
                                video.player.getEnded().then((ended) => {
                                    if (!ended) {
                                        video.isPlaying = false;
                                        if (this.session && video.synchost)
                                            this.opentokService.sendSignal('pauseVimeo', video.uid, this.session);
                                    }
                                });
                                this.video = video;
                                this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
                            }
                        });
                        video.player.on('play', () => {
                            if (this.isHost) {
                                video.isPlaying = true;

                                if (this.session && video.synchost) {
                                    video.player.getCurrentTime().then((seconds) => {
                                        this.opentokService.sendSignal('playVimeo', JSON.stringify({ uuid: video.uid, startAt: seconds }), this.session);
                                    });
                                }
                                this.video = video;
                                this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
                            }
                        });

                        video.player.on('loaded', () => {
                            video.player.unload();

                            // if (this.isImmersive) {
                            let iframe = document.querySelector('#video-vimeo-' + video.uid + ' iframe');
                            this.renderer.setStyle(iframe, 'height', '100%');
                            this.renderer.setStyle(iframe, 'width', '100%');
                            // }

                            if (this.sessionId && this.isHost && localStorage.getItem('stellar-video-' + video.uid + '-' + this.sessionId) && localStorage.getItem('stellar-now-' + video.uid + '-' + this.sessionId)) {
                                let videoNow = (new Date().getTime() - JSON.parse(localStorage.getItem('stellar-now-' + video.uid + '-' + this.sessionId))) / 1000;
                                video.player.getDuration().then((duration) => {
                                    let leftVideoTime = JSON.parse(localStorage.getItem('stellar-video-' + video.uid + '-' + this.sessionId));
                                    if (duration > (leftVideoTime + videoNow))
                                        video.player.setCurrentTime(leftVideoTime + videoNow);
                                });
                            }
                            else if (video.hasOwnProperty('startAt') && video.startAt) {
                                video.player.getDuration().then((duration) => {
                                    if (video.startAt < duration)
                                        video.player.setCurrentTime(video.startAt);
                                });
                            }

                            // if (!video.autoStart) {
                            video.muted = false;
                            video.player.setVolume(this.opentokService.preferredVolume / 100);
                            // }
                            // else {
                            // video.muted = true;
                            // video.player.setVolume(0);
                            // }

                            if (((video.isPlaying || video.autoStart) && !this.isHost) || (this.isHost && video.autoStart)) {
                                video.player.play().then(() => {
                                    this.renderer.addClass(document.getElementById('video-vimeo-' + video.uid), 'fade-in');
                                }).catch((e) => {
                                    if (e.name !== 'PlayInterrupted')
                                        this.pauseDialog.emit({ uid: video.uid, isHost: this.isHost });
                                });

                                let isPaused = false;
                                video.player.getPaused().then((paused) => {
                                    isPaused = paused;
                                });

                                if (!this.isHost && isPaused) {
                                    this.zone.run(() => {
                                        if (this.dialog)
                                            this.dialog.close();

                                        let startTimer = 0;
                                        let startTimerInterval = setInterval(() => {
                                            startTimer++;
                                        }, 1000);

                                        this.dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('The video is starting now. '), {
                                            overlayClickToClose: false,
                                            showCancelButton: false,
                                            declineText: this.translate.instant('OK')
                                        }, 'fit-content');
                                        this.dialog.afterClosed().subscribe((res) => {
                                            clearInterval(startTimerInterval);
                                            let player = video.player;
                                            player.getCurrentTime().then((seconds) => {
                                                player.getDuration().then((duration) => {
                                                    if (duration > (seconds + startTimer)) {
                                                        player.setCurrentTime(seconds + startTimer);
                                                        startTimer = 0;
                                                    }
                                                });
                                            });

                                            if (video.muted)
                                                player.setVolume(this.opentokService.preferredVolume / 100);
                                            player.play().then(() => {
                                                this.renderer.addClass(document.getElementById('video-' + video.uid), 'fade-in');
                                            }).catch((e) => {
                                                if (e.name !== 'PlayInterrupted')
                                                    this.pauseDialog.emit({ uid: video.uid, isHost: false });
                                            });
                                        });
                                    });
                                }
                            }
                            else {
                                video.player.pause();
                            }

                            if (this.isHost && this.sessionId) {
                                if (!this.interval)
                                    this.interval = setInterval(() => {
                                        if (video.player)
                                            video.player.getCurrentTime().then((seconds) => {
                                                if (video.url == '') {
                                                    clearInterval(this.interval);
                                                    this.interval = undefined;
                                                }
                                                localStorage.setItem('stellar-now-' + video.uid + '-' + this.sessionId, new Date().getTime().toString());
                                                localStorage.setItem('stellar-video-' + video.uid + '-' + this.sessionId, seconds);
                                            });
                                    }, 1000);
                            }

                            this.video = video;
                            this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });

                        }).catch((e) => { });
                    }
                    catch (e) { }
                }

                // console.log('dee', this.video)
                // this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });

                this.detectChanges();
            }
        }
    }

    stopVimeo(video: any) {
        if (this.video?.player) {
            if (document.querySelector('#video-vimeo-' + video.uid + ' iframe'))
                this.renderer.removeChild(document.body, document.querySelector('#video-vimeo-' + video.uid + ' iframe'));
            delete this.video.player;
            this.renderer.removeClass(document.getElementById('video-vimeo-' + video.uid), 'fade-in');
            if (this.interval) {
                clearInterval(this.interval);
            }

            if (this.sessionId) {
                localStorage.removeItem('stellar-video-' + video.uid + '-' + this.sessionId);
                localStorage.removeItem('stellar-now-' + video.uid + '-' + this.sessionId);
            }
        }

        video.isPlaying = false;
        video.url = '';
        this.video = video;
        this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    toggleMuteVimeo(event?) {
        if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }
        if (this.video.player) {
            if (this.video.muted)
                this.video.player.setVolume(1);
            else
                this.video.player.setVolume(0);
        }
    }

    permitVimeoFullScreen(event?) {
        if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }
        this.zone.run(() => {
            try {
                if (this.video.player) {
                    if (this.video.muted)
                        this.video.player.setVolume(1);
                    this.video.player.requestFullscreen();
                }
            }
            catch (e) { }
        });
    }
}