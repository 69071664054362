<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo select-page-body">
			<mat-card *ngIf="selectedDataObject">
				<mat-card-content>
					<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
						<div fxFlex class="bodybg">
							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
								<div fxFlex>
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
										<div fxFlex>
											<h2 style="margin:0px 0px 10px">{{data.targetDataTypeDisplay}} List</h2>
										</div>
									</div>
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
										<div fxFlex>
											<mat-form-field [hidden]="!(canSearch)">
												<input matInput name="pagin2" #searchInput
													placeholder="{{'Search' | translate}}" [value]="searchVal">
											</mat-form-field>
										</div>
									</div>
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
										<div fxFlex class="optionData.length > 0">
											<div cdkDropList #todoList="cdkDropList" [cdkDropListData]="optionData"
												[cdkDropListConnectedTo]="[doneList]" class="example-list"
												(cdkDropListDropped)="drop($event)">
												<div style="min-height:30vh;overflow-y: auto;max-height: 35vh;">
													<div class="example-box" *ngFor="let usr of optionData" cdkDrag>
														{{usr.text}}<span *ngIf="usr.email"> ({{usr.email}})</span>
													</div>
												</div>

												<mat-paginator #paginator1 class="paginator" [pageIndex]="pageNumber-1"
													[hidden]="!pagination" [pageSize]="pageSize"
													[pageSizeOptions]="[5, 10, 25]" [length]="paginatorTotal | async"
													[showFirstLastButtons]="true"></mat-paginator>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div fxFlex class="bodybg">
							<nav mat-tab-nav-bar *ngIf="data.data.streamMode !== 'meeting'">
								<ng-container *ngFor="let ppl of peopleTypeKeys">
									<ng-container *ngIf="ppl === 'moderator'">
										<a *ngIf="(!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject.features.includes('production-room-and-green-room')))"
											mat-tab-link (click)="setActiveLink(ppl)" [active]="activeLink == ppl">
											{{peopleType[ppl] | translate}} </a>
										<a *ngIf="subscriptionOrgObject && !subscriptionOrgObject.features.includes('production-room-and-green-room')"
											mat-tab-link matTooltipClass="tooltip-red" disabled
											matTooltip="{{subscriptionUpgradeStatus}}"
											title="{{subscriptionUpgradeStatus}}">
											{{peopleType[ppl] | translate}} </a>
									</ng-container>
									<ng-container *ngIf="ppl === 'interpreter'">
										<a *ngIf="(!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject.features.includes('interpretation')))"
											mat-tab-link (click)="setActiveLink(ppl)" [active]="activeLink == ppl">
											{{peopleType[ppl] | translate}} </a>
										<a *ngIf="subscriptionOrgObject && !subscriptionOrgObject.features.includes('interpretation')"
											mat-tab-link matTooltipClass="tooltip-red" disabled
											matTooltip="{{subscriptionUpgradeStatus}}"
											title="{{subscriptionUpgradeStatus}}">
											{{peopleType[ppl] | translate}} </a>
									</ng-container>
									<a *ngIf="ppl !== 'moderator' && ppl !== 'interpreter'" mat-tab-link
										(click)="setActiveLink(ppl)" [active]="activeLink == ppl">
										{{peopleType[ppl] | translate}} </a>
								</ng-container>
								<!-- <a mat-tab-link disabled>Disabled Link</a> -->
							</nav>

							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
								<div fxFlex>
									<mat-form-field [hidden]="!(canSearch)">
										<input matInput name="pagin2" #searchSelectedInput
											placeholder="{{'Search' | translate}}" [value]="searchSelectedVal">
									</mat-form-field>
								</div>
							</div>
							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
								<div fxFlex class="theDataSelected">
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
										<div fxFlex>
											<div cdkDropList #doneList="cdkDropList"
												[cdkDropListData]="selectedDataObjectFilter[activeLink]"
												[cdkDropListConnectedTo]="[todoList]" class="example-list"
												(cdkDropListDropped)="drop($event, true)">
												<div style="min-height:28.5vh;overflow-y: auto;max-height: 21vh;">
													<div class="example-box-selected"
														*ngFor="let usr of selectedDataObjectFilter[activeLink] | slice:(itemPageNumber * itempageSize):(itemPageNumber * itempageSize) + itempageSize;let index = index"
														cdkDrag>
														<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
															<div fxFlex="60">
																{{usr.text}}
																<div *ngIf="usr.email">({{usr.email}})</div>
															</div>
															<div fxFlex="40" fxLayoutAlign="end center"
																style="text-align: right;">
																<span
																	*ngIf="activeLink === 'interpreter' || activeLink === 'lead' || activeLink === 'guest'"
																	class="selectLanguage">
																	<select class="form-control"
																		(change)="setLanguage(usr._id, index, $event.target.value)"
																		title="{{'Select Language' | translate}}"
																		[value]="usr.language">
																		<!-- <option value="" [selected]="usr.language === undefined || usr.language === ''">{{'Language' | translate}}</option> -->
																		<option *ngFor="let lng of interpreterTypeKeys"
																			value="{{lng}}"
																			[selected]="usr.language === lng">
																			{{interpreterType[lng]}}</option>
																	</select>
																</span>
																<span class="showHost"
																	*ngIf="(activeLink === 'moderator' || activeLink === 'lead') || (activeLink === 'attendee' && data.data.streamMode === 'meeting')"
																	style="text-align: right;">
																	<mat-icon *ngIf="!usr.host || usr.host === false"
																		class="isOff"
																		(click)="usr.host=true;setHost(usr._id, index, true)">
																		toggle_off</mat-icon>
																	<mat-icon *ngIf="usr.host === true" class="isOn"
																		(click)="usr.host=false;setHost(usr._id, index, false)">
																		toggle_on</mat-icon>
																	<div>Host</div>
																</span>
																<span class="extraAction"
																	*ngIf="activeLink === 'moderator'"
																	style="text-align: right;">
																	<button mat-icon-button [matMenuTriggerFor]="menu"
																		#menuTrigger="matMenuTrigger">
																		<mat-icon title="More Actions">more_vert
																		</mat-icon>
																	</button>
																	<mat-menu #menu="matMenu" [overlapTrigger]="true">
																		<button mat-menu-item
																			(click)="setAllRoom(usr, 'moderator')">{{'Set
																			to current room sessions' |
																			translate}}</button>
																	</mat-menu>
																</span>
															</div>
														</div>
														<div fxLayout="row" fxLaout.lt-sm="column" fxFlex
															*ngIf="activeLink === 'guest'">
															<div fxFlex fxLayoutAlign="end center"
																style="text-align: right;">
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.viewPublicChat"
																		(ngModelChange)="setBooleanAttribute('viewPublicChat', usr._id, $event)">
																		{{'Show Public Chat' | translate}}
																	</mat-checkbox>
																</span>
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.allowStarQuestions"
																		(ngModelChange)="setBooleanAttribute('allowStarQuestions', usr._id, $event)">
																		{{'Allow to Star Questions' | translate}}
																	</mat-checkbox>
																</span>
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.allowPushQuestions"
																		(ngModelChange)="setBooleanAttribute('allowPushQuestions', usr._id, $event)">
																		{{'Allow to Push Questions' | translate}}
																	</mat-checkbox>
																</span>
															</div>
														</div>
														<div fxLayout="row" fxLaout.lt-sm="column" fxFlex
															*ngIf="activeLink === 'lead'">
															<div fxFlex fxLayoutAlign="end center"
																style="text-align: right;">
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.viewPublicChat"
																		(ngModelChange)="setBooleanAttribute('viewPublicChat', usr._id, $event)">
																		{{'Show Public Chat' | translate}}
																	</mat-checkbox>
																</span>
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.showInsights"
																		(ngModelChange)="setBooleanAttribute('showInsights', usr._id, $event)">
																		{{'Show Insights' | translate}}
																	</mat-checkbox>
																</span>
															</div>
														</div>
														<div fxLayout="row" fxLaout.lt-sm="column" fxFlex
															*ngIf="activeLink === 'attendee'">
															<div fxFlex fxLayoutAlign="end center"
																style="text-align: right;">
																<span class="showBoolean">
																	<mat-checkbox [(ngModel)]="usr.sessionAtAnyTime"
																		(ngModelChange)="setBooleanAttribute('sessionAtAnyTime', usr._id, $event)">
																		{{'Enter session at any time' | translate}}
																	</mat-checkbox>
																</span>
															</div>
														</div>
													</div>
												</div>
												<mat-paginator #paginator2 class="paginator"
													[pageIndex]="itemPageNumber" [pageSize]="itempageSize"
													[pageSizeOptions]="[5, 10, 25]"
													[length]="selectedDataObjectFilter[activeLink].length"
													[showFirstLastButtons]="true"></mat-paginator>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal()"
			[disabled]="data.columnData && data.columnData === 'host'">{{'Close' | translate}}</button>
		<button mat-raised-button (click)="saveData()">{{'Save' | translate}}</button>
		<button mat-raised-button (click)="goToUserPage()">{{'Manage Users' | translate}}</button>
		<!-- cdkFocusInitial -->
	</div>
	<br />
</div>