<div class="main-container">
  <!-- <app-page-header></app-page-header> -->
  <div class="mb-20 mainTableAgenda dragBoundary" fxLayout="column" fxLayout.lt-md="column" fxFlex
    [class.mainTableAgendaMobile]="isMobile">
    <div fxFlex>
      <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW">
        <div fxFlex [class.forTablet]="isTablet">
          <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
            style="margin: 20px 0px 10px 0px;">
            <div fxFlex="{{tabSelected === 'speaker' || tabSelected === 'moderator' ? '100' : '85'}}"
              style="text-align: left;">
              <button mat-raised-button class="TopButtons" [class.TopButtonsSelected]="tabSelected === 'current'"
                (click)="tabSelected = 'current';clearFilters();mainTab.scrollTop = 0;">
                <mat-icon style="line-height: 21px;">upcoming</mat-icon>
                {{'Current & Upcoming' | translate}}
              </button>
              <button mat-raised-button class="TopButtons" [class.TopButtonsSelected]="tabSelected === 'live'"
                (click)="tabSelected = 'live';clearFilters();mainTab.scrollTop = 0;">
                <img src="/assets/images/StellarLive.png" style="max-width: 125px; max-height: 25px;" />
                {{'Live Now' | translate}}
              </button>
              <button mat-raised-button class="TopButtons" [class.TopButtonsSelected]="tabSelected === 'past'"
                (click)="tabSelected = 'past';clearFilters();mainTab.scrollTop = 0;">
                <mat-icon style="line-height: 21px;">hourglass_bottom</mat-icon>
                {{'Past' | translate}} {{'Sessions' | translate}}
              </button>
              <button mat-raised-button class="TopButtons TopButtonsForeign"
                *ngIf="!settingOrgObject?.web?.topBar?.disableAgenda"
                [class.TopButtonsSelected]="tabSelected === 'favorites'"
                (click)="tabSelected = 'favorites';clearFilters();mainTab.scrollTop = 0;">
                <img src="/assets/images/star.png" style="max-width: 125px; max-height: 25px;cursor:pointer;" />
                {{'My Agenda' | translate}}
              </button>
              <button mat-raised-button class="TopButtons TopButtonsSpeakers"
                *ngIf="!settingOrgObject?.web?.topBar?.disableSpeakers"
                [class.TopButtonsSelected]="tabSelected === 'speaker'"
                (click)="tabSelected = 'speaker';clearFilters();mainTab.scrollTop = 0;">
                <mat-icon style="line-height: 21px;">supervisor_account</mat-icon>
                {{'Leads' | translate}}
              </button>
              <button mat-raised-button class="TopButtons TopButtonsSpeakers"
                *ngIf="!settingOrgObject?.web?.topBar?.disableSpeakers"
                [class.TopButtonsSelected]="tabSelected === 'moderator'"
                (click)="tabSelected = 'moderator';clearFilters();mainTab.scrollTop = 0;">
                <mat-icon style="line-height: 21px;">supervisor_account</mat-icon>
                {{'Guests' | translate}}
              </button>
            </div>
            <div fxFlex="15" fxLayoutAlign="end center"
              *ngIf="tabSelected !== 'speaker' && tabSelected !== 'moderator'">
              <mat-slide-toggle class="example-margin"
                [hidden]="tabSelected === 'speaker' || tabSelected === 'moderator'" [color]="'primary'"
                [checked]="showMore" (toggleChange)="showMore=!showMore"
                style="float: right;margin-right: 16px;font-size: 1rem;"
                title="{{'Show detailed descriptions of sessions if available' | translate}}">
                {{'More Details' | translate}}
              </mat-slide-toggle>
            </div>
          </div>
          <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
            style="padding-bottom: 12px;">
            <div fxFlex="15" fxLayoutAlign="start center">
              <mat-form-field style="width: 100%;" [hidden]="tabSelected === 'speaker' || tabSelected === 'moderator'">
                <input matInput #searchInput placeholder="{{ 'Search' | translate }}" [value]="searchVal"
                  autocomplete="off">
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="tabSelected !== 'speaker' && tabSelected !== 'moderator'">
                <input matInput #searchInputSpeaker placeholder="{{ 'Search' | translate }}" [value]="searchValSpeaker"
                  autocomplete="off">
              </mat-form-field>
            </div>
            <div fxFlex="15" fxLayoutAlign="start center">

              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'current')">
                <mat-label>{{'Speakers' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="speakerFilter"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of speakerList" [value]="dt">
                    {{speakerListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'live')">
                <mat-label>{{'Speakers' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="speakerFilterLive"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of speakerListLive" [value]="dt">
                    {{speakerListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'past')">
                <mat-label>{{'Speakers' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="speakerFilterOld"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of speakerListOld" [value]="dt">
                    {{speakerListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'favorites')">
                <mat-label>{{'Speakers' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="speakerFilterFavorites"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of speakerListFavorites" [value]="dt">
                    {{speakerListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="15" fxLayoutAlign="start center">
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'current')">
                <mat-label>{{'Rooms' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="roomFilter"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of roomList" [value]="dt">
                    {{roomListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'live')">
                <mat-label>{{'Rooms' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="roomFilterLive"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of roomListLive" [value]="dt">
                    {{roomListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'past')">
                <mat-label>{{'Rooms' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="roomFilterOld"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of roomListOld" [value]="dt">
                    {{roomListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'favorites')">
                <mat-label>{{'Rooms' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="roomFilterFavorites"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of roomListFavorites" [value]="dt">
                    {{roomListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="15" fxLayoutAlign="start center">
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'current') || typeList.length === 0">
                <mat-label>{{'Session Type' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="typeFilter"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of typeList" [value]="dt">
                    {{typeListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'live') || typeListLive.length === 0">
                <mat-label>{{'Session Type' | translate}}</mat-label>
                <mat-select [(value)]="typeFilterLive" (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of typeListLive" [value]="dt">
                    {{typeListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'past') || typeListOld.length === 0">
                <mat-label>{{'Session Type' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="typeFilterOld"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of typeListOld" [value]="dt">
                    {{typeListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;"
                [hidden]="!(tabSelected === 'favorites') || typeListFavorites.length === 0">
                <mat-label>{{'Session Type' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="myPanelClass" [(value)]="typeFilterFavorites"
                  (selectionChange)="loadData(false)" multiple>
                  <mat-option *ngFor="let dt of typeListFavorites" [value]="dt">
                    {{typeListAll[dt].name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="10" fxLayoutAlign="start center">
              <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
                *ngIf="tabSelected !== 'speaker' && tabSelected !== 'moderator'">
                <div fxFlex>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'current')">
                    <input matInput type="time" name="mytimeFrom" [(value)]="timeFromValue" placeholder="Between Time"
                      (change)="timeFromValue = $event.target.value;timeChanged = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'live')">
                    <input matInput type="time" name="mytimeFromLive" [(value)]="timeFromValueLive"
                      placeholder="Between Time"
                      (change)="timeFromValueLive = $event.target.value;timeChangedLive = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'past')">
                    <input matInput type="time" name="mytimeFromOld" [(value)]="timeFromValueOld"
                      placeholder="Between Time"
                      (change)="timeFromValueOld = $event.target.value;timeChangedOld = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'favorites')">
                    <input matInput type="time" name="mytimeFromFavorites" [(value)]="timeFromValueFavorites"
                      placeholder="Between Time"
                      (change)="timeFromValueFavorites = $event.target.value;timeChangedFavorites = true;loadData(false)">
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'current')">
                    <input matInput type="time" name="mytimeTo" [(value)]="timeToValue" placeholder="And Time"
                      (change)="timeToValue = $event.target.value;timeChanged = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'live')">
                    <input matInput type="time" name="mytimeToLive" [(value)]="timeToValueLive" placeholder="And Time"
                      (change)="timeToValueLive = $event.target.value;timeChangedLive = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'past')">
                    <input matInput type="time" name="mytimeToOld" [(value)]="timeToValueOld" placeholder="And Time"
                      (change)="timeToValueOld = $event.target.value;timeChangedOld = true;loadData(false)">
                  </mat-form-field>
                  <mat-form-field style="width: 110px" [hidden]="!(tabSelected === 'favorites')">
                    <input matInput type="time" name="mytimeToFavorites" [(value)]="timeToValueFavorites"
                      placeholder="And Time"
                      (change)="timeToValueFavorites = $event.target.value;timeChangedFavorites = true;loadData(false)">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxFlex="30" fxLayoutAlign="end center" *ngIf="!isTablet">

              <button mat-button class="clearButtons" (click)="clearFilters()" title="{{'Clear Filters' | translate}}">
                <mat-icon style="margin-right: 5px;
    margin-bottom: 2px;">clear_all
                </mat-icon>{{'Clear Filters' | translate}}
              </button>
            </div>
          </div>
          <div fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="1VW" *ngIf="isTablet">
            <div fxFlex fxLayoutAlign="end center">
              <button mat-button class="clearButtons" (click)="clearFilters()" title="{{'Clear Filters' | translate}}">
                <mat-icon style="margin-right: 5px;
    margin-bottom: 2px;">clear_all
                </mat-icon>{{'Clear Filters' | translate}}
              </button>
            </div>
          </div>
          <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
            *ngIf="tabSelected !== 'speaker' && tabSelected !== 'moderator'">
            <!-- <div fxFlex="19" fxLayoutAlign="start center">
                   <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'current')">
                     <mat-label>{{'Select Dates' | translate}}</mat-label>
                     <mat-select [(value)]="dateFilter" (selectionChange)="loadData(false)" multiple>
                       <mat-option *ngFor="let dt of dateList" [value]="dt">
                         {{dt | date: 'EEEE - MMM dd,yyyy'}}
                       </mat-option>
                     </mat-select>
                   </mat-form-field>
                   <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'live')">
                     <mat-label>{{'Select Dates' | translate}}</mat-label>
                     <mat-select [(value)]="dateFilterLive" (selectionChange)="loadData(false)" multiple>
                       <mat-option *ngFor="let dt of dateListLive" [value]="dt">
                         {{dt | date: 'EEEE - MMM dd,yyyy'}}
                       </mat-option>
                     </mat-select>
                   </mat-form-field>
                   <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'past')">
                     <mat-label>{{'Select Dates' | translate}}</mat-label>
                     <mat-select [(value)]="dateFilterOld" (selectionChange)="loadData(false)" multiple>
                       <mat-option *ngFor="let dt of dateListOld" [value]="dt">
                         {{dt | date: 'EEEE - MMM dd,yyyy'}}
                       </mat-option>
                     </mat-select>
                   </mat-form-field>
                   <mat-form-field style="width: 100%;" [hidden]="!(tabSelected === 'favorites')">
                     <mat-label>{{'Select Dates' | translate}}</mat-label>
                     <mat-select [(value)]="dateFilterFavorites" (selectionChange)="loadData(false)" multiple>
                       <mat-option *ngFor="let dt of dateListFavorites" [value]="dt">
                         {{dt | date: 'EEEE - MMM dd,yyyy'}}
                       </mat-option>
                     </mat-select>
                   </mat-form-field>
               </div> -->

          </div>
          <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
            style="margin: 0px 0px 5px 0px;">
            <div fxFlex style="text-align: left;">
              <span [hidden]="!(tabSelected === 'current')">
                <button *ngFor="let dt of dateList.slice(0, maxDatesUI);let index = index" mat-button
                  class="TopButtonsDays" [class.TopButtonsDaysSelected]="dateFilter.indexOf(dt) > -1"
                  (click)="topButtonsDateFilter(dt)">{{dt |
                  date: 'EEE, MMM dd'}}</button>
                <!-- <button mat-icon-button *ngIf="dateList.length > maxDatesUI" [matMenuTriggerFor]="menudateFilter">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsDays" *ngIf="dateList.length > maxDatesUI"
                  [matMenuTriggerFor]="menudateFilter">
                  {{'More' | translate}}
                </button>
                <mat-menu #menudateFilter="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of dateList.slice(maxDatesUI, dateList.length );let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{dt | date: 'EEE, MMM dd'}}">
                      <mat-icon *ngIf="!(dateFilter.indexOf(dt) > -1)" (click)="topButtonsDateFilter(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="dateFilter.indexOf(dt) > -1" (click)="topButtonsDateFilter(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{dt | date: 'EEE, MMM dd'}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'live')">
                <button *ngFor="let dt of dateListLive.slice(0, maxDatesUI);let index = index" mat-button
                  class="TopButtonsDays" [class.TopButtonsDaysSelected]="dateFilterLive.indexOf(dt) > -1"
                  (click)="topButtonsDateFilterLive(dt)">{{dt | date: 'EEE, MMM dd'}}</button>
                <!-- <button mat-icon-button *ngIf="dateListLive.length > maxDatesUI" [matMenuTriggerFor]="menudateFilterLive">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsDays" *ngIf="dateListLive.length > maxDatesUI"
                  [matMenuTriggerFor]="menudateFilterLive">
                  {{'More' | translate}}
                </button>
                <mat-menu #menudateFilterLive="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of dateListLive.slice(maxDatesUI, dateListLive.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{dt | date: 'EEE, MMM dd'}}">
                      <mat-icon *ngIf="!(dateFilterLive.indexOf(dt) > -1)" (click)="topButtonsDateFilterLive(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="dateFilterLive.indexOf(dt) > -1" (click)="topButtonsDateFilterLive(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{dt | date: 'EEE, MMM dd'}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'past')">
                <button *ngFor="let dt of dateListOld.slice(0, maxDatesUI);let index = index" mat-button
                  class="TopButtonsDays" [class.TopButtonsDaysSelected]="dateFilterOld.indexOf(dt) > -1"
                  (click)="topButtonsDateFilterOld(dt)">{{dt | date: 'EEE, MMM dd'}}</button>
                <!-- <button mat-icon-button *ngIf="dateListOld.length > maxDatesUI" [matMenuTriggerFor]="menudateFilterOld">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsDays" *ngIf="dateListOld.length > maxDatesUI"
                  [matMenuTriggerFor]="menudateFilterOld">
                  {{'More' | translate}}
                </button>
                <mat-menu #menudateFilterOld="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of dateListOld.slice(maxDatesUI, dateListOld.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{dt | date: 'EEE, MMM dd'}}">
                      <mat-icon *ngIf="!(dateFilterOld.indexOf(dt) > -1)" (click)="topButtonsDateFilterOld(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="dateFilterOld.indexOf(dt) > -1" (click)="topButtonsDateFilterOld(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{dt | date: 'EEE, MMM dd'}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'favorites')">
                <button *ngFor="let dt of dateListFavorites.slice(0, maxDatesUI);let index = index" mat-button
                  class="TopButtonsDays" [class.TopButtonsDaysSelected]="dateFilterFavorites.indexOf(dt) > -1"
                  (click)="topButtonsDateFilterFavorites(dt)">{{dt | date: 'EEE, MMM dd'}}</button>
                <!-- <button mat-icon-button *ngIf="dateListFavorites.length > maxDatesUI"
                  [matMenuTriggerFor]="menudateFilterFavorites">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsDays" *ngIf="dateListFavorites.length > maxDatesUI"
                  [matMenuTriggerFor]="menudateFilterFavorites">
                  {{'More' | translate}}
                </button>
                <mat-menu #menudateFilterFavorites="matMenu">
                  <div style="max-height: 200px;">
                    <button
                      *ngFor="let dt of dateListFavorites.slice(maxDatesUI, dateListFavorites.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{dt | date: 'EEE, MMM dd'}}">
                      <mat-icon *ngIf="!(dateFilterFavorites.indexOf(dt) > -1)"
                        (click)="topButtonsDateFilterFavorites(dt)" title="{{dt | date: 'EEE, MMM dd'}}"
                        style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="dateFilterFavorites.indexOf(dt) > -1" (click)="topButtonsDateFilterFavorites(dt)"
                        title="{{dt | date: 'EEE, MMM dd'}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{dt | date: 'EEE, MMM dd'}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
            </div>
          </div>
          <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1VW"
            style="margin: 0px 0px 5px 0px;">
            <div fxFlex style="text-align: left;">
              <span [hidden]="!(tabSelected === 'current')">
                <button *ngFor="let dt of tagList.slice(0, maxCategoriesUI);let index = index" mat-button
                  class="TopButtonsTags" [class.TopButtonsTagsSelected]="tagFilter.indexOf(dt) > -1"
                  (click)="topButtonsTagFilter(dt)">
                  {{tagListAll[dt].name}}
                </button>
                <!-- <button mat-icon-button *ngIf="tagList.length > maxCategoriesUI" [matMenuTriggerFor]="menutagFilter">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsTags" *ngIf="tagList.length > maxCategoriesUI"
                  [matMenuTriggerFor]="menutagFilter">
                  {{'More' | translate}}
                </button>
                <mat-menu #menutagFilter="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of tagList.slice(maxCategoriesUI, tagList.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{tagListAll[dt].name}}">
                      <mat-icon *ngIf="!(tagFilter.indexOf(dt) > -1)" (click)="topButtonsTagFilter(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="tagFilter.indexOf(dt) > -1" (click)="topButtonsTagFilter(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{tagListAll[dt].name}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'live')">
                <button *ngFor="let dt of tagListLive.slice(0, maxCategoriesUI);let index = index" mat-button
                  class="TopButtonsTags" [class.TopButtonsTagsSelected]="tagFilterLive.indexOf(dt) > -1"
                  (click)="topButtonsTagFilterLive(dt)">
                  {{tagListAll[dt].name}}
                </button>
                <!-- <button mat-icon-button *ngIf="tagListLive.length > maxCategoriesUI" [matMenuTriggerFor]="menutagFilterLive">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsTags" *ngIf="tagListLive.length > maxCategoriesUI"
                  [matMenuTriggerFor]="menutagFilterLive">
                  {{'More' | translate}}
                </button>
                <mat-menu #menutagFilterLive="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of tagListLive.slice(maxCategoriesUI, tagListLive.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{tagListAll[dt].name}}">
                      <mat-icon *ngIf="!(tagFilterLive.indexOf(dt) > -1)" (click)="topButtonsTagFilterLive(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="tagFilterLive.indexOf(dt) > -1" (click)="topButtonsTagFilterLive(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{tagListAll[dt].name}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'past')">
                <button *ngFor="let dt of tagListOld.slice(0, maxCategoriesUI);let index = index" mat-button
                  class="TopButtonsTags" [class.TopButtonsTagsSelected]="tagFilterOld.indexOf(dt) > -1"
                  (click)="topButtonsTagFilterOld(dt)">
                  {{tagListAll[dt].name}}
                </button>
                <!-- <button mat-icon-button *ngIf="tagListOld.length > maxCategoriesUI" [matMenuTriggerFor]="menutagFilterOld">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsTags" *ngIf="tagListOld.length > maxCategoriesUI"
                  [matMenuTriggerFor]="menutagFilterOld">
                  {{'More' | translate}}
                </button>
                <mat-menu #menutagFilterOld="matMenu">
                  <div style="max-height: 200px;">
                    <button *ngFor="let dt of tagListOld.slice(maxCategoriesUI, tagListOld.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{tagListAll[dt].name}}">
                      <mat-icon *ngIf="!(tagFilterOld.indexOf(dt) > -1)" (click)="topButtonsTagFilterOld(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">
                        check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="tagFilterOld.indexOf(dt) > -1" (click)="topButtonsTagFilterOld(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{tagListAll[dt].name}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
              <span [hidden]="!(tabSelected === 'favorites')">
                <button *ngFor="let dt of tagListFavorites.slice(0, maxCategoriesUI);let index = index" mat-button
                  class="TopButtonsTags" [class.TopButtonsTagsSelected]="tagFilterFavorites.indexOf(dt) > -1"
                  (click)="topButtonsTagFilterFavorites(dt)">
                  {{tagListAll[dt].name}}
                </button>
                <!-- <button mat-icon-button *ngIf="tagListFavorites.length > maxCategoriesUI"
                  [matMenuTriggerFor]="menutagFilterFavorites">
                  <mat-icon class="refreshmore" title="{{ 'More' | translate }}">more_vert
                  </mat-icon>
                </button> -->
                <button mat-button class="TopButtonsTags" *ngIf="tagListFavorites.length > maxCategoriesUI"
                  [matMenuTriggerFor]="menutagFilterFavorites">
                  {{'More' | translate}}
                </button>
                <mat-menu #menutagFilterFavorites="matMenu">
                  <div style="max-height: 200px;">
                    <button
                      *ngFor="let dt of tagListFavorites.slice(maxCategoriesUI, tagListFavorites.length);let index=index"
                      mat-menu-item (click)="cancelThru($event)" title="{{tagListAll[dt].name}}">
                      <mat-icon *ngIf="!(tagFilterFavorites.indexOf(dt) > -1)"
                        (click)="topButtonsTagFilterFavorites(dt)" title="{{tagListAll[dt].name}}"
                        style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                      <mat-icon *ngIf="tagFilterFavorites.indexOf(dt) > -1" (click)="topButtonsTagFilterFavorites(dt)"
                        title="{{tagListAll[dt].name}}" style="height: 30px;vertical-align: middle;">check_box
                      </mat-icon>
                      <span style="liveLogoText">
                        {{tagListAll[dt].name}}
                      </span>
                    </button>
                  </div>
                </mat-menu>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1vw">
        <div fxFlex class="parentTabs" #mainTab>
          <div [hidden]="!(tabSelected === 'current')">
            <agenda-dialog-table [type]="'current'" [dataSource]="dataList" [loading]="loading" [showMore]="showMore"
              [displayedColumns]="displayedColumns" (toggleAction)="handleToggleAction($event)"></agenda-dialog-table>
          </div>
          <div [hidden]="!(tabSelected === 'past')">
            <agenda-dialog-table [type]="'past'" [dataSource]="dataListOld" [loading]="loading" [showMore]="showMore"
              [displayedColumns]="displayedColumns" (toggleAction)="handleToggleAction($event)"></agenda-dialog-table>
          </div>
          <div [hidden]="!(tabSelected === 'live')">
            <agenda-dialog-table [type]="'live'" [dataSource]="dataListLive" [loading]="loading" [showMore]="showMore"
              [displayedColumns]="displayedColumns" (toggleAction)="handleToggleAction($event)"></agenda-dialog-table>
          </div>
          <div [hidden]="!(tabSelected === 'favorites')">
            <agenda-dialog-table [type]="'favorites'" [dataSource]="dataListFavorites" [loading]="loading"
              [showMore]="showMore" [displayedColumns]="displayedColumns" (toggleAction)="handleToggleAction($event)">
            </agenda-dialog-table>
          </div>
          <div [hidden]="!(tabSelected === 'speaker')" class="speakerData">
            <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1vw">
              <div fxFlex>
                <agenda-dialog-speakers-table [speakerFilter]="speakerFilter" [dataSource]="leadusers"
                  [loading]="loading" (toggleAction)="handleToggleAction($event)" [fieldsDetails]="fieldsDetails">
                </agenda-dialog-speakers-table>
              </div>
            </div>
          </div>
          <div [hidden]="!(tabSelected === 'moderator')" class="speakerData">
            <div fxFlex fxLayout="{{!isMobile ? 'row' : 'column'}}" fxLayout.lt-sm="column" fxLayoutGap="1vw">
              <div fxFlex>
                <agenda-dialog-speakers-table [speakerFilter]="speakerFilter" [dataSource]="guestusers"
                  [loading]="loading" (toggleAction)="handleToggleAction($event)" [fieldsDetails]="fieldsDetails">
                </agenda-dialog-speakers-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-resources [resources]="resources" [sessionData]="sessionData" [hide]="hideResources"
      (hideDialog)="toggleResources($event)"></app-resources>
  </div>
</div>