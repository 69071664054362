import { Component, Inject, OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {utc} from 'moment';


interface ViewDialogData {
	title: string;
	data: any;
	fields: any;
	confirmData: any;
	canEdit: boolean;
	canDelete: boolean;
	canOpen: boolean;
	canCreate: boolean;
	contentTemplate: TemplateRef<any>;
}

@Component({
	selector: 'app-view-event-dialog-modal',
	templateUrl: './view-event-dialog.component.html',
	styleUrls: ['./view-event-dialog.component.scss']
})
export class ModalViewEventDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public canEdit: boolean = false;
	public canDelete: boolean = false;
	public canCreate: boolean = false;
	public canOpen: boolean = false;
	public selectedEvent: any = undefined;
	public metaFieldSetting = undefined;
	public contentTemplate: TemplateRef<any> = undefined;
	utc=utc;
	constructor(private translate: TranslateService,
		public dialogRef: MatDialogRef<ModalViewEventDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalViewEventDialogComponent', data);
		if (this.data.fields) {
			this.metaFieldSetting = this.buildMetaSetting(this.data, parent = undefined);
		}
		if (this.data.contentTemplate) {
			this.contentTemplate = this.data.contentTemplate;
		}
		// console.log('this.metaFieldSetting', this.metaFieldSetting);
		if(data.data){
			this.selectedEvent = data.data;
		}
		if(data.canEdit){
			this.canEdit = data.canEdit;
		}
		if(data.canCreate){
			this.canCreate = data.canCreate;
		}
		if(data.canDelete){
			this.canDelete = data.canDelete;
		}
		if(data.canOpen){
			this.canOpen = data.canOpen;
		}
	}

	ngOnInit() {
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		// let tabObject = [];
		for (let col of data.fields) {
		  if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
			if (parent) {
			  col['inputName'] = parent + col['name'];
			}
			dataObject[col.name] = col;
		  } else if (col.type === 'object') {
			dataObject[col.name] = this.buildMetaSetting(col);
		  }
		  else if (col.type === 'table') {
			dataObject[col.name] = col;
		  }
		}
		return dataObject;
	  }
}
