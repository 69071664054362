import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BdcWalkModule } from 'bdc-walkthrough';

import { BdcWalkModule } from './modules/bdc-walkthrough';
import { CalendarModule } from './modules/calendar';
// import { GlobalService } from './services/global.service';

import { AlertActionEntityDialogComponent, SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent, ActionNotificationComponent, ConfirmCkeditorEntityDialogComponent, ConfirmCameraEntityDialogComponent, ConfirmInputEntityDialogComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, ModalViewImageDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ModalUserDialogComponent, ModalDialogComponent, ModalPasswordDialogComponent, ModalUserViewDialogComponent, CustomSelectComponent, CustomTagsComponent, TileWidgetComponent, ModalIframeHeaderDialogComponent, WidgetComponent, ConfirmSelectEntityDialogComponent, RightSidebarComponent } from './components';

import { CkeditorBlockComponent, WidgetDummyComponent, TextBlockComponent, LinkDescriptionBlockComponent, VideoBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, ShareBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, AttendanceBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, ModalGalleryDialogComponent, ModalRegistrationDialogComponent } from './components';

import { CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent, CustomMetadataTableComponent, CustomMultipleSelectComponent, CustomSelectCreateAutocompleteDialogComponent, ModalPreviewDialogComponent, TilesListComponent, IframeUrlComponent, TileAnswersComponent, TileAnswersPercentageComponent, ModalTileCreateDialogComponent, TileDataComponent, LectureTilesListComponent, ModalLayoutDialogComponent, TileSelectDialogComponent, SliderComponent, BillboardSelectDialogComponent, PictureSelectDialogComponent, CustomSelectDialogComponent, ModalCropDialogComponent, MarginViewComponent, CustomDateTimeComponent, TileQuestionsAnswersComponent, CustomScreenCaptureComponent, SessionAssignDialogComponent, SessionUsersAssignDialogComponent, ResourcesSessionAssignDialogComponent, ImageMapComponent } from './components';
import { LayoutDynamicDataComponent, ColumnSettingsDialogComponent, ScreenLayoutComponent, ScreenLayoutViewComponent, ModalAdvancedGalleryDialogComponent, PictureWallDialogComponent, PictureDialogComponent, LayoutComponent, ModalcustomSingleImageUploadDialogComponent, PartyStreamDialogComponent, SchedulerComponent, ShareVideoDialogComponent, GuestEditDialogComponent, AudioEditDialogComponent, FileModalDialogComponent, FileModalCropDialogComponent, ModalPosterViewDialogComponent, PanelistsUpdateDialogComponent, PanelLayoutComponent, ModalTileDialogComponent, CustomTileViewComponent, ModalVideoDialogComponent, VideoPlaylistDialogComponent, ModalImagegalleryDialogComponent, ImageGalleryDialogComponent } from './components';
import { ImageGalleryContainerDialogComponent, MediaLibraryContainerDialogComponent, ModalAudioGalleryDialogComponent, CustomDynamicDialogComponent, OrientationScreenComponent, EventInvolvedObjectComponent, ScreenLayoutPreviewComponent, CustomSchedulerComponent, ModalSchedulerDialogComponent, QuuRadioDialogComponent, MultipleModalDialogComponent, ModalUploaderDialogComponent, RoomsGalleryComponent, CustomCheckboxComponent, SessionsGalleryComponent } from './components';
import { RecurrenceDialogComponent, ModalViewEventDialogComponent } from './modules/calendar';
import { AgendaDialogComponent, AgendaDialogTableComponent, AgendaDialogSpeakersTableComponent } from './components';
import { ClickOutSideDirective } from './directives';

import { Utils } from './helpers/utils';

import { CKEditorModule } from 'ngx-ckeditor';
import { ResizableModule } from './directives/angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';
import { DNDAlertEntityDialogComponent } from './components/modals/dnd-entity-dialog/dnd-entity-dialog.component';
import { GlobalChatComponent } from './components/global-chat/global-chat.component';
import { SpeakerCameraComponent } from '../pages/rooms/components/session-page/camera-play/camera-play.component';
import { ConfirmRTMPEntityDialogComponent } from './components/modals/confirm-rtmp-entity-dialog/confirm-rtmp-entity-dialog.component';
import { BubbleWorldMapComponent } from './components/bubble-worldmap/bubble-worldmap.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TimeSeriesChartComponent } from './components/timeseries-chart/timeseries-chart.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SessionResourcesComponent } from './components/resources/resources.component';
import { PictureWallComponent } from './components/picture-wall/picture-wall.component';
import { AudioComponent } from './components/audio/audio.component';
import { MediaLibraryComponent } from './components/media-library/media-library.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { CopyDialogComponent } from './components/modals/copy-dialog/copy-dialog.component';
import { VideoComponent } from './components/video/video.component';
import { AlertStatsDialogComponent } from './components/modals/alert-stats-dialog/alert-stats-dialog.component';
import { RoomSessionDialogComponent } from './components/room-session-dialog/room-session-dialog.component';
import { SessionEntryComponent } from '../pages/rooms/components/session-page/session-entry/session-entry.component';
import { YoutubeComponent } from './components/layout-components/youtube-media/youtube-media.component';
import { VimeoComponent } from './components/layout-components/vimeo-media/vimeo-media.component';
import { HlsComponent } from './components/layout-components/hls-media/hls-media.component';
import { FacebookComponent } from './components/layout-components/facebook-media/facebook-media.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IframeComponent } from './components/layout-components/iframe/iframe.component';
import { AttendeeListChatComponent } from '../pages/rooms/components/session-page/attendee-list-chat/attendee-list-chat.component';
import { QuestionsAnswersComponent } from '../pages/rooms/components/session-page/questions-answers/questions-answers.component';
import { ShareScreenComponent } from '../pages/rooms/components/session-page/share-screen/share-screen.component';
import { QuuNowPlayingComponent } from './components/quu/now-playing/now-playing.component';
import { QuuPlaylistComponent } from './components/quu/playlist/playlist.component';
import { YoutubeDialogComponent } from './components/youtube-dialog/youtube-dialog.component';
import { QuuSongDialogComponent } from './components/quu/quu-song-dialog/quu-song-dialog.component';
import { ElectronScreenShareDialogComponent } from './components/layout-components/electron-screenshare-dialog/electron-screenshare-dialog.component';
import { VideoFilterDialogComponent } from './components/layout-components/video-filter-dialog/video-filter-dialog.component';
// import { ChartsModule } from 'ng2-charts';

@NgModule({
        imports: [
                CommonModule,
                RouterModule,
                SharedModule,
                TranslateModule,
                FormsModule,
                ReactiveFormsModule,
                CKEditorModule,
                BdcWalkModule,
                CalendarModule,
                ResizableModule,
                ImageCropperModule,
                FlexLayoutModule.withConfig({ addFlexToParent: false }),
                NgApexchartsModule,
                // ChartsModule,
                PickerModule
        ],
        providers: [
                // GlobalService
                Utils
        ],
        entryComponents: [
                SnackBarComponent,
                AlertActionEntityDialogComponent,
                CopyDialogComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                DNDAlertEntityDialogComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmCameraEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                CustomDynamicDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                LoadingScreenComponent,
                OrientationScreenComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                YoutubeDialogComponent,
                EnumViewComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                FileModalCropDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                GlobalChatComponent,
                SpeakerCameraComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                TileAnswersPercentageComponent,
                TileQuestionsAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                SliderComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                ScreenLayoutPreviewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                CustomScreenCaptureComponent,
                BubbleWorldMapComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                SessionUsersAssignDialogComponent,
                ResourcesSessionAssignDialogComponent,
                ImageMapComponent,
                ModalcustomSingleImageUploadDialogComponent,
                PartyStreamDialogComponent,
                SchedulerComponent,
                ShareVideoDialogComponent,
                SessionResourcesComponent,
                GuestEditDialogComponent,
                AudioEditDialogComponent,
                ModalPosterViewDialogComponent,
                PanelistsUpdateDialogComponent,
                PanelLayoutComponent,
                ModalTileDialogComponent,
                CustomTileViewComponent,
                ModalVideoDialogComponent,
                VideoPlaylistDialogComponent,
                ModalImagegalleryDialogComponent,
                ImageGalleryDialogComponent,
                ImageGalleryComponent,
                PictureWallComponent,
                AudioComponent,
                VideoComponent,
                MediaLibraryComponent,
                ImageGalleryContainerDialogComponent,
                MediaLibraryContainerDialogComponent,
                ModalAudioGalleryDialogComponent,
                RecurrenceDialogComponent,
                ModalViewEventDialogComponent,
                AlertStatsDialogComponent,
                RoomSessionDialogComponent,
                SessionEntryComponent,
                YoutubeComponent,
                VimeoComponent,
                HlsComponent,
                FacebookComponent,
                IframeComponent, ShareScreenComponent, AttendeeListChatComponent, QuestionsAnswersComponent,
                EventInvolvedObjectComponent,
                CustomSchedulerComponent,
                ModalSchedulerDialogComponent,
                QuuPlaylistComponent,
                QuuRadioDialogComponent,
                QuuNowPlayingComponent,
                QuuSongDialogComponent,
                MultipleModalDialogComponent,
                AgendaDialogComponent, AgendaDialogTableComponent, AgendaDialogSpeakersTableComponent,
                ModalUploaderDialogComponent,
                RoomsGalleryComponent,
                CustomCheckboxComponent,
                SessionsGalleryComponent,
                ElectronScreenShareDialogComponent,
                VideoFilterDialogComponent
        ],
        declarations: [
                SidebarComponent,
                RightSidebarComponent,
                AlertActionEntityDialogComponent,
                CopyDialogComponent,
                TopnavComponent,
                NavComponent,
                PageHeaderComponent,
                SnackBarComponent,
                EnumViewComponent,
                ActionNotificationComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmCameraEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                CustomDynamicDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                YoutubeDialogComponent,
                AlertComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                DNDAlertEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                LoadingScreenComponent,
                OrientationScreenComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                FileModalCropDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                GlobalChatComponent,
                SpeakerCameraComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                TileAnswersPercentageComponent,
                TileQuestionsAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                ClickOutSideDirective,
                SliderComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                ScreenLayoutPreviewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                CustomScreenCaptureComponent,
                BubbleWorldMapComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                SessionUsersAssignDialogComponent,
                ResourcesSessionAssignDialogComponent,
                ImageMapComponent,
                ModalcustomSingleImageUploadDialogComponent,
                PartyStreamDialogComponent,
                SchedulerComponent,
                ShareVideoDialogComponent,
                SessionResourcesComponent,
                GuestEditDialogComponent,
                AudioEditDialogComponent,
                ModalPosterViewDialogComponent,
                PanelistsUpdateDialogComponent,
                PanelLayoutComponent,
                ModalTileDialogComponent,
                CustomTileViewComponent,
                ModalVideoDialogComponent,
                VideoPlaylistDialogComponent,
                ModalImagegalleryDialogComponent,
                ImageGalleryDialogComponent,
                ImageGalleryComponent,
                PictureWallComponent,
                AudioComponent,
                VideoComponent,
                MediaLibraryComponent,
                ImageGalleryContainerDialogComponent,
                MediaLibraryContainerDialogComponent,
                ModalAudioGalleryDialogComponent,
                RecurrenceDialogComponent,
                ModalViewEventDialogComponent,
                AlertStatsDialogComponent,
                RoomSessionDialogComponent,
                SessionEntryComponent,
                YoutubeComponent,
                VimeoComponent,
                HlsComponent,
                FacebookComponent,
                IframeComponent, ShareScreenComponent, AttendeeListChatComponent, QuestionsAnswersComponent,
                EventInvolvedObjectComponent,
                CustomSchedulerComponent,
                ModalSchedulerDialogComponent,
                QuuPlaylistComponent,
                QuuRadioDialogComponent,
                QuuNowPlayingComponent,
                QuuSongDialogComponent,
                MultipleModalDialogComponent,
                AgendaDialogComponent, AgendaDialogTableComponent, AgendaDialogSpeakersTableComponent,
                ModalUploaderDialogComponent,
                RoomsGalleryComponent,
                CustomCheckboxComponent,
                SessionsGalleryComponent,
                ElectronScreenShareDialogComponent,
                VideoFilterDialogComponent
        ],
        exports: [
                SidebarComponent,
                RightSidebarComponent,
                AlertActionEntityDialogComponent,
                CopyDialogComponent,
                TopnavComponent,
                NavComponent,
                PageHeaderComponent,
                ActionNotificationComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmCameraEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                CustomDynamicDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                YoutubeDialogComponent,
                AlertComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                DNDAlertEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                EnumViewComponent,
                LoadingScreenComponent,
                OrientationScreenComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                FileModalCropDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                GlobalChatComponent,
                SpeakerCameraComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                TileAnswersPercentageComponent,
                TileQuestionsAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                ClickOutSideDirective,
                SliderComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                ScreenLayoutPreviewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                CustomScreenCaptureComponent,
                BubbleWorldMapComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                SessionUsersAssignDialogComponent,
                ResourcesSessionAssignDialogComponent,
                ImageMapComponent,
                ModalcustomSingleImageUploadDialogComponent,
                PartyStreamDialogComponent,
                SchedulerComponent,
                ShareVideoDialogComponent,
                SessionResourcesComponent,
                GuestEditDialogComponent,
                AudioEditDialogComponent,
                ModalPosterViewDialogComponent,
                PanelistsUpdateDialogComponent,
                PanelLayoutComponent,
                ModalTileDialogComponent,
                CustomTileViewComponent,
                ModalVideoDialogComponent,
                VideoPlaylistDialogComponent,
                ModalImagegalleryDialogComponent,
                ImageGalleryDialogComponent,
                ImageGalleryComponent,
                PictureWallComponent,
                AudioComponent,
                VideoComponent,
                MediaLibraryComponent,
                ImageGalleryContainerDialogComponent,
                MediaLibraryContainerDialogComponent,
                ModalAudioGalleryDialogComponent,
                RecurrenceDialogComponent,
                ModalViewEventDialogComponent,
                AlertStatsDialogComponent,
                RoomSessionDialogComponent,
                SessionEntryComponent,
                YoutubeComponent,
                VimeoComponent,
                HlsComponent,
                FacebookComponent,
                IframeComponent, ShareScreenComponent, AttendeeListChatComponent, QuestionsAnswersComponent,
                EventInvolvedObjectComponent,
                CustomSchedulerComponent,
                ModalSchedulerDialogComponent,
                QuuPlaylistComponent,
                QuuRadioDialogComponent,
                QuuNowPlayingComponent,
                QuuSongDialogComponent,
                MultipleModalDialogComponent,
                AgendaDialogComponent, AgendaDialogTableComponent, AgendaDialogSpeakersTableComponent,
                ModalUploaderDialogComponent,
                RoomsGalleryComponent,
                CustomCheckboxComponent,
                SessionsGalleryComponent,
                ElectronScreenShareDialogComponent,
                VideoFilterDialogComponent
        ]
})
export class LayoutComponentModule { }
