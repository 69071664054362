import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { MatDialog } from '@angular/material/dialog';
import { ConfDefaultSettings } from './../../pages/admin-page/components/configuration-page';
import { I } from '@angular/cdk/keycodes';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable()
export class FeaturesPermissionService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public defaultFeatures: any = ConfDefaultSettings.defaultFeatures;
  public defaultSetting: any = ConfDefaultSettings.defaultSetting;
  public currentFeatures: any = undefined;
  public currentFeaturesSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public currentSettings: any = undefined;
  public currentSettingsSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public currentSubscription: any = undefined;
  public currentSubscriptionSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public currentUISettings: any = undefined;
  public currentUISettingsSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public currentUserSettings: any = undefined;
  public currentUserSettingsSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public loading: boolean = false;

  constructor(private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, protected router: Router,
    protected activatedRoute: ActivatedRoute, public dialog: MatDialog) {
  }
  public setUpOrg(orgData) {
    if (orgData) {
      this.setUpSubscription(orgData.subscriptions);
      this.setUpSettings(orgData.settings);
      this.setUpFeatures(orgData.features);
    }
  }
  public setUpSettings(settings) {
    if (settings && Object.keys(settings).length > 0) {
      this.currentSettings = JSON.parse(JSON.stringify(settings));
    } else {
      this.currentSettings = JSON.parse(JSON.stringify(this.defaultSetting));
    }
    this.currentSettings = this.validateOrgSettings(this.currentSettings)
    this.currentSettingsSubject.next(this.currentSettings);
  }
  public setUpFeatures(features) {
    if (features && Object.keys(features).length > 0) {
      this.currentFeatures = JSON.parse(JSON.stringify(features));
    } else {
      this.currentFeatures = JSON.parse(JSON.stringify(this.defaultFeatures));
    }
    this.currentFeaturesSubject.next(this.currentFeatures);
  }
  public setUpSubscription(subscription) {
    this.currentSubscription = undefined;
    if (subscription) {
      let subscriptionKeys = Object.keys(subscription);
      if (subscriptionKeys.length > 0) {
        let currentSubscription = JSON.parse(JSON.stringify(subscription));
        let packageType: string = 'no-package';// default package
        if (currentSubscription.hasOwnProperty('selected_package')) {
          packageType = currentSubscription.selected_package;
        }
        currentSubscription['selected_package'] = packageType;
        currentSubscription['features'] = this.getFeatures(packageType, currentSubscription);
        this.currentSubscription = currentSubscription;
      }
      // comment below it is for debugging
      // else {
      //   let currentSubscription = JSON.parse(JSON.stringify(subscription));
      //   let packageType: string = 'no-package';
      //   currentSubscription['package'] = packageType;
      //   currentSubscription['features'] = this.getFeatures(packageType, currentSubscription);
      //   this.currentSubscription = currentSubscription;
      // }
    }
    this.currentSubscriptionSubject.next(this.currentSubscription);
  }
  public setUpUISettings(uisettings) {// by user by org
    if (uisettings) {
      let uisettingsObject = {};
      for (let itm of uisettings) {
        uisettingsObject[itm.key] = itm.value;
      }
      this.currentUISettings = uisettingsObject;
    } else {
      this.currentUISettings = undefined;
    }
    this.currentUISettingsSubject.next(this.currentUISettings);
  }
  public setUpUserSettings(settings) {// by user
    if (settings) {
      let settingsObject = {};
      for (let itm of settings) {
        settingsObject[itm.key] = itm.value;
      }
      this.currentUserSettings = settingsObject;
    } else {
      this.currentUserSettings = undefined;
    }
    this.currentUserSettingsSubject.next(this.currentUserSettings);
  }
  public canAccessFeature(sessionType) {
    return true;
  }
  public getFeatures(packageType, currentSubscription) {
    let features = [];
    if (packageType === 'professional') {
      features = [
        'room-breakouts',
        'audience-view/spot-light',
        'production-room-and-green-room',
        'communicate',
        'support-chat-bot',
        'customized-registration-fields',
        'interpretation',
        'posters',
        'customized-subdomain-and-link-to-ga-account'];
      if (currentSubscription && currentSubscription.total_subscription_exhibit_rooms > 0) {
        features.push('exhibit-booths');
      }
    } else if (packageType === 'full') {
      features = [
        'room-breakouts',
        'audience-view/spot-light',
        'production-room-and-green-room',
        'communicate',
        'support-chat-bot',
        'customized-registration-fields'];
      if (currentSubscription && currentSubscription.total_subscription_exhibit_rooms > 0) {
        features.push('exhibit-booths');
      }
    } else {
      features = [];
    }
    if (currentSubscription && currentSubscription.selected_cloudlive && currentSubscription.selected_cloudlive !== '' && currentSubscription.selected_cloudlive !== 'none' && currentSubscription.cloudlive_end_date) {
      let endD: Moment = moment.utc(currentSubscription.cloudlive_end_date).local();
      let currentDate: Moment = moment.utc();
      if (currentDate.diff(endD, 'minutes') < 0) {
        features.push('cloudlive-streaming');
      }
    }
    if (currentSubscription && currentSubscription.subscription_status === 'paid' && currentSubscription.end_date) {
      let endD: Moment = moment.utc(currentSubscription.end_date).local();
      let currentDate: Moment = moment.utc();
      if (currentDate.diff(endD, 'minutes') < 0) {
        features.push('paid-valid');
      }
    }
    return features;
  }
  public validateOrgSettings(settings) {
    let settingsData = JSON.parse(JSON.stringify(settings));
    if (this.currentSubscription) {
      if (!this.currentSubscription['features'].includes('support-chat-bot')) {
        settingsData['disableSupportBot'] = true;
      }
      if (!this.currentSubscription['features'].includes('communicate')) {
        settingsData['web']['topBar']['disableCommunicate'] = true;
        settingsData['web']['topBar']['disableVideoCommunicate'] = true;
        settingsData['mobile']['topBar']['disableCommunicate'] = true;
        settingsData['mobile']['topBar']['disableVideoCommunicate'] = true;
      }
      if (!this.currentSubscription['features'].includes('posters')) {
        settingsData['web']['topBar']['disablePoster'] = true;
        settingsData['mobile']['topBar']['disablePoster'] = true;
      }
      if (!this.currentSubscription['features'].includes('exhibit-booths')) {
        settingsData['web']['topBar']['disableExhibitor'] = true;
        settingsData['mobile']['topBar']['disableExhibitor'] = true;
      }
    }
    return settingsData;
  }
  public validateSessionUsers(users) {
    if (users) {
      let usersData = JSON.parse(JSON.stringify(users));
      if (this.currentSubscription) {
        if (!this.currentSubscription['features'].includes('production-room-and-green-room')) {
          usersData.map((usr) => {
            if (usr.type === 'moderator') {
              usr.type = 'lead';
            }
            return usr;
          });
        }
        if (!this.currentSubscription['features'].includes('interpretation')) {
          usersData.map((usr) => {
            if (usr.type === 'interpreter') {
              usr.type = 'attendee';
            }
            return usr;
          });
        }
      }
      return usersData;
    }
    return [];
  }
}
