<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
				</div>
			</div>
			<div class="form-group kt-form__group row">
				<div class="col-lg-12" *ngIf="data.overlay && data.overlay.type">
					<div class="fieldInput">
						<mat-form-field style="width: 100%;">
							<mat-label>{{'Type' | translate}}</mat-label>
							<mat-select (selectionChange)="onChange('type', $event.value)"
								[(ngModel)]="data.overlay['type']"
								title="{{'Select' | translate}} {{'Type' | translate}}">
								<mat-option value="text">{{'Text'}}</mat-option>
								<mat-option value="image">{{'Image'}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="fieldInput" *ngIf="data.overlay['type'] === 'text'">
						<ckeditor-block [config]="editorConfig" [value]="data.overlay['content']"
							[widget_Buttons_map]="widget_Buttons_map" (textView)="onChange('content', $event)">
						</ckeditor-block>
					</div>
					<div class="fieldInput" *ngIf="data.overlay['type'] === 'image'">
						<div>
							<button (click)="openImageLibrary()" mat-button color="primary"
								title="{{'Select Image' | translate}}" style="margin-right: 10px;">
								{{'Select Image' | translate}}
							</button>
						</div>
						<img class="imageSelected"
							*ngIf="data.overlay['type'] === 'image' && data.overlay['content'] !== ''"
							[src]="data.overlay['content']">
					</div>
					<div class="fieldInput" *ngIf="data.overlay['type'] === 'text'">
						<mat-form-field style="width: 100%;">
							<mat-label>{{'Align' | translate}}</mat-label>
							<mat-select (selectionChange)="onChange('align', $event.value)"
								[disabled]="data.overlay.motion === 'marquee'" [(ngModel)]="data.overlay['align']"
								title="{{'Select' | translate}} {{'Alignment' | translate}}">
								<mat-option value="right">{{'Right'}}</mat-option>
								<mat-option value="center">{{'Center'}}</mat-option>
								<mat-option value="left">{{'Left'}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="fieldInput" *ngIf="data.overlay['type'] === 'text'">
						<mat-form-field style="width: 100%;">
							<mat-label>{{'Motion' | translate}}</mat-label>
							<mat-select (selectionChange)="onChange('motion', $event.value)"
								[(ngModel)]="data.overlay['motion']"
								title="{{'Select' | translate}} {{'Motion' | translate}}">
								<mat-option value="static">{{'Static'}}</mat-option>
								<mat-option value="marquee">{{'Marquee'}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="fieldInput">
						<mat-form-field style="width: 100%;">
							<mat-label>{{'Trigger' | translate}}</mat-label>
							<mat-select (selectionChange)="onChange('trigger', $event.value)"
								[(ngModel)]="data.overlay['trigger']"
								title="{{'Select' | translate}} {{'Trigger' | translate}}">
								<mat-option value="manual">{{'Manual'}}</mat-option>
								<mat-option value="schedule" [disabled]="data.taskAction === 'play'">{{'Scheduled'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="fieldInput" [hidden]="data.overlay['trigger'] === 'manual'">
						<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" placeholder="{{ 'Start Time (Seconds)' | translate }}"
								[(ngModel)]="data.overlay['startTime']" name="startTime"
								[disabled]="data.taskAction === 'play'"
								(change)="editNumericAttribute($event, 'startTime', $event.target.value, 0)">
						</mat-form-field>
					</div>
					<div class="fieldInput" [hidden]="data.overlay['trigger'] === 'manual'">
						<mat-form-field style="width: 100%;">
							<input matInput type="number" placeholder="{{ 'Duration (Minutes)' | translate }}"
								[(ngModel)]="data.overlay['duration']" [disabled]="data.taskAction === 'play'"
								name="duration"
								(change)="editNumericAttribute($event, 'duration', $event.target.value, 0, undefined, true)">
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br />
				<button mat-raised-button (click)="onNoClick()" color="danger">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button (click)="onYesClick()">{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>