<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="example-chip-list">
    <mat-chip-list #chipList aria-label="placeholder" *ngIf="!canSelectRole">
      <mat-chip
        *ngFor="let tg of value;let index = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(tg)"
        [class.userchip]="tg.type === 'user'"
        [class.rolechip]="tg.type === 'role'"
        title="{{tg.type === 'user' ? 'User: ' + tg.email  : 'Role: ' + tg.name}}"
        >
        {{tg.name}}
        <mat-icon class="closeBtn" matChipRemove *ngIf="removable">close</mat-icon>
      </mat-chip>
      <input
        placeholder="{{ placeholder | translate}} {{required ? '*' : ''}}"
        #tagInput
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)" matChipInputAddOnBlur="false">
    </mat-chip-list>
    <input type="text" *ngIf="canSelectRole"
           placeholder="{{ placeholder | translate}} {{required ? '*' : ''}}"
           aria-label="Number"
           matInput
           #tagInput
           [formControl]="tagCtrl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
      <div class="tag-title" *ngIf="type.includes('role') && hasRolesOrgRequired">Roles</div>
      <ng-container *ngIf="type.includes('role') && hasRolesOrgRequired">
        <mat-option *ngFor="let tg of filteredRoleTags | async" [value]="{_id: tg._id, name: tg.name, type: 'role'}">
          {{tg.name}}
        </mat-option>
      </ng-container>
      <div class="tag-title" *ngIf="type.includes('user')">Users</div>
      <ng-container *ngIf="type.includes('user')">
      <mat-option *ngFor="let tg of filteredTags | async" [value]="{_id: tg._id, name: tg.name, type: 'user', email: tg.email, userType: tg.userType, host: false}">
        {{tg.name}} ({{tg.email}})
      </mat-option>
    </ng-container>
    </mat-autocomplete>
    <!-- <mat-hint align="end">{{'To create a new item you need to write down the word if it is not in the list and click enter to create it'| translate}}</mat-hint> -->
    <div class="my-error mat-error mat-form-field-subscript-wrapper" *ngIf="required && (value === undefined || value.length === 0)">
      {{placeholder | translate}} is required.
    </div>
  </mat-form-field>
</form>

<table *ngIf="canSelectRole" class="tableRoleData" >
  <tr>
    <th>Name</th>
    <th>Type</th>
    <th>Event Role</th>
    <th>Host</th>
    <th *ngIf="removable">Delete</th>
  </tr>
  <tr *ngFor="let tg of value;let index = index">
    <td>{{tg.name}}</td>
    <td>{{tg.type=== 'role'? 'Role' : 'User' }}</td>
    <td>
      <mat-form-field *ngIf="tg.type==='user'">
      <select  matNativeControl style="margin-left: 10px;
      max-width: 90%;" (change)="setSessionUserType(index, $event.target.value)" [value]="tg.userType">
        <option value="{{ppl}}" *ngFor="let ppl of peopleTypeKeys" [selected]="tg.userType === ppl">{{peopleType[ppl] | translate}}</option>
      </select>
      </mat-form-field>
    </td>
    <td><input id="host" *ngIf="tg.type==='user' && ['lead','moderator', 'attendee'].includes(tg.userType)" [checked]="tg.host" [(ngModel)]="tg.host" (change)="unsetAllOthers(tg, $event.target.checked)" type="checkbox"></td>
    <td *ngIf="removable"><mat-icon class="deleteBtn" (click)="remove(tg)" *ngIf="removable">close</mat-icon></td>
  </tr>
</table>