import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, ViewChild, ElementRef, Output, EventEmitter, Input } from "@angular/core";
import { RequestService, LayoutUtilsService, SubheaderService, StoreService, RoomSessionService, LoaderService } from "src/app/shared/services";
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { ModalUserViewDialogComponent } from 'src/app/shared/components/custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from "src/environments/environment";
import { Subscription, fromEvent } from "rxjs";
import { startWith, map, tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Moment } from 'moment';
import * as moment from 'moment';
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: 'app-agenda-dialog-page',
  templateUrl: './agenda-dialog.component.html',
  styleUrls: ['./agenda-dialog.component.scss']
})

export class AgendaDialogComponent implements OnInit, OnDestroy {

  public orgType = environment.orgType;
  public errorMessage: string = '';
  dataListAll: any[] = [];
  dataList: any[] = [];
  dataListOld: any[] = [];
  dataListFavorites: any[] = [];
  dataListLive: any[] = [];
  speakerListAll: any = {};
  speakerListLeadsAll: any = {};
  speakerListGuestsAll: any = {};
  speakerList: any = [];
  speakerListOld: any = [];
  speakerListFavorites: any = [];
  speakerListLive: any = [];
  speakerFilter: string[] = [];
  speakerFilterOld: string[] = [];
  speakerFilterFavorites: string[] = [];
  speakerFilterLive: string[] = [];
  dateList: any[] = [];
  dateListOld: any[] = [];
  dateListFavorites: any[] = [];
  dateListLive: any[] = [];
  dateFilter: string[] = [];
  dateFilterOld: string[] = [];
  dateFilterFavorites: string[] = [];
  dateFilterLive: string[] = [];
  roomListAll: any = {};
  roomList: any[] = [];
  roomListOld: any[] = [];
  roomListFavorites: any[] = [];
  roomListLive: any[] = [];
  roomFilter: string[] = [];
  roomFilterOld: string[] = [];
  roomFilterFavorites: string[] = [];
  roomFilterLive: string[] = [];
  tagListAll: any = {};
  tagList: any[] = [];
  tagListOld: any[] = [];
  tagListFavorites: any[] = [];
  tagListLive: any[] = [];
  tagFilter: string[] = [];
  tagFilterOld: string[] = [];
  tagFilterFavorites: string[] = [];
  tagFilterLive: string[] = [];
  typeListAll: any = {};
  typeList: any[] = [];
  typeListOld: any[] = [];
  typeListFavorites: any[] = [];
  typeListLive: any[] = [];
  typeFilter: string[] = [];
  typeFilterOld: string[] = [];
  typeFilterFavorites: string[] = [];
  typeFilterLive: string[] = [];
  timeChanged: boolean = false;
  timeFromValue: string = '00:01:00';
  timeToValue: string = '23:59:00';
  timeChangedOld: boolean = false;
  timeFromValueOld: string = '00:01:00';
  timeToValueOld: string = '23:59:00';
  timeChangedFavorites: boolean = false;
  timeFromValueFavorites: string = '00:01:00';
  timeToValueFavorites: string = '23:59:00';
  timeChangedLive: boolean = false;
  timeFromValueLive: string = '00:01:00';
  timeToValueLive: string = '23:59:00';
  public fieldsDetails: any = undefined;
  public metaFieldTabsSetting = [];
  users: any = undefined;
  leadusers: any = undefined;
  guestusers: any = undefined;
  isFiltering: boolean = false;
  loading: boolean = false;
  showMore: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 10000;
  private subscriptions: Subscription[] = [];
  searchVal: string = '';
  searchValSpeaker: string = '';
  storedFilters = undefined;
  // userJoined: any = [];
  selectedUser: any;
  selectedTags: any[] = [];
  maxCategoriesUI: number = 4;
  maxDatesUI: number = 7;
  public dataType: string = 'session';
  public dataTypeDisplay: string = this.translate.instant('Sessions');
  public noFilter: string = this.translate.instant('No Filter');
  displayedColumns: string[] = ['favorite', 'startsAt', 'active', 'name', 'specificusers', 'room', 'resources', 'calender'];
  public hideResources: boolean = true;
  public resources: any[] = [];
  public settingOrgObject: any = undefined;
  public sessionData: any = undefined;
  public isMobile: boolean = false;
  public isTablet: boolean = false;

  @Input() tabSelected: string = 'current';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputSpeaker') searchInputSpeaker: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private subheaderService: SubheaderService, private store: StoreService, private router: Router,
    public renderer: Renderer2, public dialog: MatDialog, private loaderService: LoaderService, public deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
    }
    if (this.deviceService.isTablet()) {
      this.isTablet = true;
    }
  }

  ngOnInit() {
    // this.subheaderService.setTitle(this.translate.instant('Agenda'));
    // this.subheaderService.setBreadcrumbs([
    //   { title: this.translate.instant('Agenda'), page: undefined }
    // ]);
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          if (data.hasOwnProperty('settings')) {
            this.settingOrgObject = data.settings;
          }
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.userBuildSetting();
        }
      })
    );
  }
  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.toggleResources(true);
            this.loadData();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const searchSubscriptionSpeaker = fromEvent(this.searchInputSpeaker.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfigurationSpeaker();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.toggleResources(true);
            this.loadUserData();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscriptionSpeaker);
  }
  setTabPage(e) {
    this.tabSelected = e;
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  ngOnDestroy() {
    // this.renderer.removeStyle(document.body, 'background'); // remove bgcolor brand
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  handleToggleAction(action) {
    if (action.action === 'setFavorite') {
      this.setFavorite(action.status, action.id);
    } else if (action.action === 'addSpeakerFilter') {
      this.speakerPageFilter(action.id);
    } else if (action.action === 'showResources') {
      this.resources = action.resources;
      this.sessionData = action.session;
      // this.toggleResources(true);
      this.toggleResources(false);
      // call popup you can action.ids
    }
  }
  public setTimeAttribute(val) {
    // moment( this.dateValue + 'T' + val).format('YYYY-MM-DDTHH:mm');
    console.log('setTimeAttribute', val);
  }
  setFavorite(status, id) {
    this.loading = true;
    this.requestService.setFavoriteData(status, 'session', id
      , (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let idx: number = 0;
          for (let item of this.dataListAll) {
            if (item._id === id) {
              this.dataListAll[idx]['favorite'] = status;
              break;
            }
            idx++;
          }
          this.prepareTabsData();
        }
      });
  }
  loadData(isDb = true) {
    if (isDb) {
      this.loadDBData()
    } else {
      this.prepareTabsData();
    }
  }
  loadDBData() {
    this.loading = true;
    this.loaderService.display(true);
    let filters: any = {
      "$and": [
        { 'organizationId._id': { '$eq': this.requestService.orgId } },
        { 'inagenda': { '$eq': true } }
        // { "type": { "$ne": '3' } } // exhibit not for conference
      ]
    };
    let filterObj = { page: this.pageNumber, term: '', orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'name', 'inagenda', 'main'], filter: filters };
    this.updateFilterStored(filterObj);
    this.requestService.getDataList('room',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataList = data.results || [];
          let dataListIds = this.requestService.getDataFromList(dataList);
          if (dataListIds.length > 0) {
            this.loadSessionData(dataListIds);
          } else {
            this.loaderService.display(false);
            this.loading = false;
          }
        } else {
          this.loaderService.display(false);
          this.loading = false;
        }
      });
  }
  loadFavoritesSession(dataListSession) {
    this.loading = true;
    let dataListSessionIds = this.requestService.getDataFromList(dataListSession);
    let filters = {
      "$and": [
        { 'organizationId': { '$eq': this.requestService.orgId } },
        { "favoriteId": { "$in": dataListSessionIds } },
        { "type": { "$eq": 'session' } },
        { "userId": { "$eq": this.selectedUser._id } }
      ]
    };
    let filterObj = { page: this.pageNumber, term: '', orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'favoriteId'], filter: filters };
    this.requestService.getDataList('favorites',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataList = data.results || [];
          let dataListIds = this.requestService.getDataFromList(dataList, 'favoriteId');
          this.setUpSessionData(dataListSession, dataListIds);
        } else {
          this.loading = false;
          this.loaderService.display(false);
        }
      });
  }
  loadSessionData(dataListRoomsIds) {
    this.loading = true;
    let termConfiguration = this.termConfiguration();
    if (termConfiguration !== '') {
      this.isFiltering = true;
    } else {
      this.isFiltering = false;
    }
    let filters: any = {
      "$and": [{ 'organizationId._id': { '$eq': this.requestService.orgId } },
      { "room._id": { "$in": dataListRoomsIds } },
      // { "users.type": { "$in": ['lead', 'guest'] } },
      { "type": { "$ne": '3' } },
      { 'excludeagenda': { '$eq': false } }] //, filter: filters
    };
    if (this.selectedTags.length > 0) {
      filters['$and'].push({ "tags._id": { "$in": this.requestService.getDataFromList(this.selectedTags) } });
    }
    let filterObj = { page: this.pageNumber, term: termConfiguration, orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'room', 'description', 'url', 'type', 'recordSource', 'recordAvailable', 'tags', 'showbg', 'resources', 'allowAttendeeByTime'], filter: filters, termfields: ['name', 'users.name', 'tags.name'] };
    this.updateFilterStored(filterObj);
    this.requestService.getDataList(this.dataType,
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataList = data.results || [];
          if (dataList.length > 0) {
            this.loadFavoritesSession(dataList);
          } else {
            this.dataListAll = dataList;
            this.prepareTabsData();
          }
        } else {
          this.loading = false;
          this.loaderService.display(false);
        }
      });
  }
  setUpSessionData(dataListSession, dataListFavoritesIds) {
    this.speakerListAll = {};
    this.roomListAll = {};
    this.tagListAll = {};
    this.typeListAll = {};
    let dataList = dataListSession.map((item) => {
      let newItem = item;
      // newItem['type'] = '';

      newItem['calender'] = true;
      newItem['showMore'] = false;
      newItem['favorite'] = false;
      if (dataListFavoritesIds.includes(newItem._id)) {
        newItem['favorite'] = true;
      }

      let currentUsers = newItem['users'];
      let newUsers = [];
      let newUsersIds = [];
      let newUsersLeads = [];
      let newUsersLeadsIds = [];
      let newUsersGuests = [];
      let newUsersGuestsIds = [];
      if (currentUsers) {
        for (let user of currentUsers) {
          // added user.hideFromAgenda !== true to exclude users from list of select speaker
          if ((user.type === 'lead' || user.type === 'guest') && user.hideFromAgenda !== true) {
            newUsers.push(user);
            newUsersIds.push(user._id);
            this.speakerListAll[user._id] = user;
          }
          if (user.type === 'lead' && user.hideFromAgenda !== true) {
            this.speakerListLeadsAll[user._id] = user;
            newUsersLeads.push(user);
            newUsersLeadsIds.push(user._id);
          } else if (user.type === 'guest' && user.hideFromAgenda !== true) {
            this.speakerListGuestsAll[user._id] = user;
            newUsersGuests.push(user);
            newUsersGuestsIds.push(user._id);
          }
        }
      }

      newItem['specificusers'] = newUsers;
      newItem['specificusersIds'] = newUsersIds;
      newItem['specificusersLeads'] = newUsersLeads;
      newItem['specificusersLeadsIds'] = newUsersLeadsIds;
      newItem['specificusersGuests'] = newUsersGuests;
      newItem['specificusersGuestsIds'] = newUsersGuestsIds;

      let currentRoom = newItem['room'];
      if (currentRoom) {
        this.roomListAll[currentRoom._id] = currentRoom;
      }

      let currentType = newItem['type'];
      if (currentType) {
        this.typeListAll[currentType] = { _id: currentType, name: newItem['typeName'] };
      }

      let currentTags = newItem['tags'];
      let tagsIds = [];
      if (currentTags) {
        for (let tg of currentTags) {
          tagsIds.push(tg._id);
          this.tagListAll[tg._id] = tg;
        }
      }
      newItem['tagsIds'] = tagsIds;
      // if(item.hasOwnProperty('room')){
      //   if(item.room.hasOwnProperty('typeName')){
      //     newItem['type'] = item.room.typeName;
      //   }
      // }
      newItem['sameDate'] = false;
      let currentDate: Moment = moment.utc();
      let startD: Moment = moment.utc(item.startsAt).local();
      let endD: Moment = moment.utc(item.endAt).local();
      if (startD.diff(endD, 'days') === 0) {
        newItem['sameDate'] = true;
      }
      newItem['showLive'] = false;
      if (((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) && item.url && item.url.trim() !== '') || item.active) {
        newItem['showLive'] = true;
      }
      return newItem;
    }
    );

    this.dataListAll = dataList;
    this.loadUserData();
    this.prepareTabsData();
  }
  speakerPageFilter(dt) {
    if (this.speakerList.indexOf(dt) === -1 && this.speakerListOld.indexOf(dt) > -1) {
      let speakerFilterOld = JSON.parse(JSON.stringify(this.speakerFilterOld));
      if (speakerFilterOld.indexOf(dt) > -1) {
        // speakerFilter.splice(speakerFilter.indexOf(dt), 1);
      } else {
        speakerFilterOld.push(dt);
      }
      this.speakerFilterOld = JSON.parse(JSON.stringify(speakerFilterOld));
      this.tabSelected = 'past';
    } else {
      let speakerFilter = JSON.parse(JSON.stringify(this.speakerFilter));
      if (speakerFilter.indexOf(dt) > -1) {
        // speakerFilter.splice(speakerFilter.indexOf(dt), 1);
      } else {
        speakerFilter.push(dt);
      }
      this.speakerFilter = JSON.parse(JSON.stringify(speakerFilter));
      this.tabSelected = 'current';
    }
    this.loadData(false);
  }
  topButtonsDateFilter(dt) {
    let dateFilter = JSON.parse(JSON.stringify(this.dateFilter));
    if (dateFilter.indexOf(dt) > -1) {
      dateFilter.splice(dateFilter.indexOf(dt), 1);
    } else {
      dateFilter.push(dt);
    }
    this.dateFilter = JSON.parse(JSON.stringify(dateFilter));
    this.loadData(false);
  }
  topButtonsDateFilterOld(dt) {
    let dateFilterOld = JSON.parse(JSON.stringify(this.dateFilterOld));
    if (dateFilterOld.indexOf(dt) > -1) {
      dateFilterOld.splice(dateFilterOld.indexOf(dt), 1);
    } else {
      dateFilterOld.push(dt);
    }
    this.dateFilterOld = JSON.parse(JSON.stringify(dateFilterOld));
    this.loadData(false);
  }
  topButtonsDateFilterFavorites(dt) {
    let dateFilterFavorites = JSON.parse(JSON.stringify(this.dateFilterFavorites));
    if (dateFilterFavorites.indexOf(dt) > -1) {
      dateFilterFavorites.splice(dateFilterFavorites.indexOf(dt), 1);
    } else {
      dateFilterFavorites.push(dt);
    }
    this.dateFilterFavorites = JSON.parse(JSON.stringify(dateFilterFavorites));
    this.loadData(false);
  }
  topButtonsDateFilterLive(dt) {
    let dateFilterLive = JSON.parse(JSON.stringify(this.dateFilterLive));
    if (dateFilterLive.indexOf(dt) > -1) {
      dateFilterLive.splice(dateFilterLive.indexOf(dt), 1);
    } else {
      dateFilterLive.push(dt);
    }
    this.dateFilterLive = JSON.parse(JSON.stringify(dateFilterLive));
    this.loadData(false);
  }
  topButtonsTagFilter(dt) {
    let tagFilter = JSON.parse(JSON.stringify(this.tagFilter));
    if (tagFilter.indexOf(dt) > -1) {
      tagFilter.splice(tagFilter.indexOf(dt), 1);
    } else {
      tagFilter.push(dt);
    }
    this.tagFilter = JSON.parse(JSON.stringify(tagFilter));
    this.loadData(false);
  }
  topButtonsTagFilterOld(dt) {
    let tagFilterOld = JSON.parse(JSON.stringify(this.tagFilterOld));
    if (tagFilterOld.indexOf(dt) > -1) {
      tagFilterOld.splice(tagFilterOld.indexOf(dt), 1);
    } else {
      tagFilterOld.push(dt);
    }
    this.tagFilterOld = JSON.parse(JSON.stringify(tagFilterOld));
    this.loadData(false);
  }
  topButtonsTagFilterFavorites(dt) {
    let tagFilterFavorites = JSON.parse(JSON.stringify(this.tagFilterFavorites));
    if (tagFilterFavorites.indexOf(dt) > -1) {
      tagFilterFavorites.splice(tagFilterFavorites.indexOf(dt), 1);
    } else {
      tagFilterFavorites.push(dt);
    }
    this.tagFilterFavorites = JSON.parse(JSON.stringify(tagFilterFavorites));
    this.loadData(false);
  }
  topButtonsTagFilterLive(dt) {
    let tagFilterLive = JSON.parse(JSON.stringify(this.tagFilterLive));
    if (tagFilterLive.indexOf(dt) > -1) {
      tagFilterLive.splice(tagFilterLive.indexOf(dt), 1);
    } else {
      tagFilterLive.push(dt);
    }
    this.tagFilterLive = JSON.parse(JSON.stringify(tagFilterLive));
    this.loadData(false);
  }
  prepareTabsData() {
    this.toggleResources(true);
    let dataList = this.dataListAll;
    let dataListWithNoFilter = this.dataListAll;
    let dataListOld = this.dataListAll;
    let dataListOldWithNoFilter = this.dataListAll;
    let dataListFavorites = this.dataListAll;
    let dataListLive = this.dataListAll;

    let speakerList = [];
    let speakerListOld = [];
    let speakerListFavorites = [];
    let speakerListLive = [];

    let dateList = [];
    let dateListOld = [];
    let dateListFavorites = [];
    let dateListLive = [];

    let roomList = [];
    let roomListOld = [];
    let roomListFavorites = [];
    let roomListLive = [];

    let typeList = [];
    let typeListOld = [];
    let typeListFavorites = [];
    let typeListLive = [];

    let tagList = [];
    let tagListOld = [];
    let tagListFavorites = [];
    let tagListLive = [];

    if (!this.isFiltering) {
      dataListWithNoFilter = dataListWithNoFilter.filter((item) => {
        // console.log(this.timeFromValue);
        // console.log(this.timeToValue);
        let currentDate: Moment = moment.utc();
        let startD: Moment = moment.utc(item.startsAt).local();
        let endD: Moment = moment.utc(item.endAt).local();

        if (((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) || item.active) {
          return true;
        }
        return false;
      }
      );
      dataListOldWithNoFilter = dataListOldWithNoFilter.filter((item) => {
        // console.log(this.timeFromValue);
        // console.log(this.timeToValue);
        let currentDate: Moment = moment.utc();
        let startD: Moment = moment.utc(item.startsAt).local();
        let endD: Moment = moment.utc(item.endAt).local();

        if ((!((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0)) && !item.active) {
          return true;
        }
        return false;
      }
      );
      if (this.tabSelected === 'current' && dataListWithNoFilter.length === 0 && dataListOldWithNoFilter.length > 0) {
        this.tabSelected = 'past'
      }
    }
    dataList = dataList.filter((item) => {
      // console.log(this.timeFromValue);
      // console.log(this.timeToValue);
      let currentDate: Moment = moment.utc();
      let startD: Moment = moment.utc(item.startsAt).local();
      let endD: Moment = moment.utc(item.endAt).local();

      if (((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) || item.active) {
        let startDString: string = startD.format('YYYY-MM-DD');
        if (!dateList.includes(startDString)) {
          dateList.push(startDString);
        }
        // speakerList
        let currentUsers = item['specificusers'];
        if (currentUsers) {
          for (let user of currentUsers) {
            if (!speakerList.includes(user._id)) {
              speakerList.push(user._id);
            }
          }
        }
        if (item.room && !roomList.includes(item.room._id)) {
          roomList.push(item.room._id);
        }
        if (item.type && !typeList.includes(item.type)) {
          typeList.push(item.type);
        }
        // tagList
        let currentTags = item['tags'];
        if (currentTags) {
          for (let tg of currentTags) {
            if (!tagList.includes(tg._id)) {
              tagList.push(tg._id);
            }
          }
        }
        // console.log('this.dateFilter', this.dateFilter);
        if (this.dateFilter.length > 0 || this.speakerFilter.length > 0 || this.roomFilter.length > 0 || this.tagFilter.length > 0 || this.typeFilter.length > 0 || this.timeChanged) {
          let hasDateItem = true;
          if (this.dateFilter.length > 0) {
            hasDateItem = false;
            for (let df of this.dateFilter) {
              let filterDate: Moment = moment(df);
              let startDDate: Moment = moment(startD.format('YYYY-MM-DD'));
              let endDDate: Moment = moment(endD.format('YYYY-MM-DD'));
              if (filterDate.diff(startDDate, 'days') >= 0 && filterDate.diff(endDDate, 'days') <= 0) {
                hasDateItem = true;
                break;
              }
            }
          }
          let hasSpeakerItem = true;
          if (this.speakerFilter.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.speakerFilter) {
              if (item.specificusersIds.includes(df)) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasRoomItem = true;
          if (this.roomFilter.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.roomFilter) {
              if (item.room && df === item.room._id) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasTagItem = true;
          if (this.tagFilter.length > 0) {
            hasTagItem = false;
            for (let df of this.tagFilter) {
              if (item.tagsIds.includes(df)) {
                hasTagItem = true;
                break;
              }
            }
          }
          let hasTypeItem = true;
          if (this.typeFilter.length > 0) {
            hasTypeItem = false;
            for (let df of this.typeFilter) {
              if (item.type && df === item.type) {
                hasTypeItem = true;
                break;
              }
            }
          }
          let hasTimeItem = true;
          if (this.timeChanged) {
            hasTimeItem = false;

            let startdateValue = startD.format('YYYY-MM-DD');
            let enddateValue = endD.format('YYYY-MM-DD');
            // let timeValue = startD.format('HH:mm');
            let startFromfullDateValue = moment(startdateValue + 'T' + this.timeFromValue);
            let startTofullDateValue = moment(startdateValue + 'T' + this.timeToValue);
            let endFromfullDateValue = moment(enddateValue + 'T' + this.timeFromValue);
            let endTofullDateValue = moment(enddateValue + 'T' + this.timeToValue);

            if ((startFromfullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startFromfullDateValue, 'minutes') >= 0) ||
              (startTofullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startTofullDateValue, 'minutes') >= 0) ||
              (startD.diff(startFromfullDateValue, 'minutes') >= 0 && startTofullDateValue.diff(startD, 'minutes') >= 0) ||
              (endD.diff(endFromfullDateValue, 'minutes') >= 0 && endTofullDateValue.diff(endD, 'minutes') >= 0)
            ) {
              hasTimeItem = true;
            }
          }
          return hasDateItem && hasSpeakerItem && hasRoomItem && hasTagItem && hasTimeItem && hasTypeItem;
        }
        return true;
      }
      return false;
    }
    );

    dataListFavorites = dataListFavorites.filter((item) => {
      let startD: Moment = moment.utc(item.startsAt).local();
      let endD: Moment = moment.utc(item.endAt).local();

      if (item.favorite) {
        // let startDStringView: string = startD.format('dddd - MMM d,yyyy');
        let startDString: string = startD.format('YYYY-MM-DD');
        if (!dateListFavorites.includes(startDString)) {
          dateListFavorites.push(startDString);
        }
        // speakerList
        let currentUsers = item['specificusers'];
        if (currentUsers) {
          for (let user of currentUsers) {
            if (!speakerListFavorites.includes(user._id)) {
              speakerListFavorites.push(user._id);
            }
          }
        }
        if (item.room && !roomListFavorites.includes(item.room._id)) {
          roomListFavorites.push(item.room._id);
        }
        if (item.type && !typeListFavorites.includes(item.type)) {
          typeListFavorites.push(item.type);
        }
        // tagList
        let currentTags = item['tags'];
        if (currentTags) {
          for (let tg of currentTags) {
            if (!tagListFavorites.includes(tg._id)) {
              tagListFavorites.push(tg._id);
            }
          }
        }
        // console.log('this.dateFilter', this.dateFilter);
        if (this.dateFilterFavorites.length > 0 || this.speakerFilterFavorites.length > 0 || this.roomFilterFavorites.length > 0 || this.tagFilterFavorites.length > 0 || this.typeFilterFavorites.length > 0 || this.timeChangedFavorites) {
          let hasDateItem = true;
          if (this.dateFilterFavorites.length > 0) {
            hasDateItem = false;
            for (let df of this.dateFilterFavorites) {
              let filterDate: Moment = moment(df);
              let startDDate: Moment = moment(startD.format('YYYY-MM-DD'));
              let endDDate: Moment = moment(endD.format('YYYY-MM-DD'));
              if (filterDate.diff(startDDate, 'days') >= 0 && filterDate.diff(endDDate, 'days') <= 0) {
                hasDateItem = true;
                break;
              }
            }
          }
          let hasSpeakerItem = true;
          if (this.speakerFilterFavorites.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.speakerFilterFavorites) {
              if (item.specificusersIds.includes(df)) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasRoomItem = true;
          if (this.roomFilterFavorites.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.roomFilterFavorites) {
              if (item.room && df === item.room._id) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasTagItem = true;
          if (this.tagFilterFavorites.length > 0) {
            hasTagItem = false;
            for (let df of this.tagFilterFavorites) {
              if (item.tagsIds.includes(df)) {
                hasTagItem = true;
                break;
              }
            }
          }
          let hasTypeItem = true;
          if (this.typeFilterFavorites.length > 0) {
            hasTypeItem = false;
            for (let df of this.typeFilterFavorites) {
              if (item.type && df === item.type) {
                hasTypeItem = true;
                break;
              }
            }
          }
          let hasTimeItem = true;
          if (this.timeChangedFavorites) {
            hasTimeItem = false;

            let startdateValue = startD.format('YYYY-MM-DD');
            let enddateValue = endD.format('YYYY-MM-DD');
            // let timeValue = startD.format('HH:mm');
            let startFromfullDateValue = moment(startdateValue + 'T' + this.timeFromValueFavorites);
            let startTofullDateValue = moment(startdateValue + 'T' + this.timeToValueFavorites);
            let endFromfullDateValue = moment(enddateValue + 'T' + this.timeFromValueFavorites);
            let endTofullDateValue = moment(enddateValue + 'T' + this.timeToValueFavorites);

            if ((startFromfullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startFromfullDateValue, 'minutes') >= 0) ||
              (startTofullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startTofullDateValue, 'minutes') >= 0) ||
              (startD.diff(startFromfullDateValue, 'minutes') >= 0 && startTofullDateValue.diff(startD, 'minutes') >= 0) ||
              (endD.diff(endFromfullDateValue, 'minutes') >= 0 && endTofullDateValue.diff(endD, 'minutes') >= 0)
            ) {
              hasTimeItem = true;
            }
          }
          return hasDateItem && hasSpeakerItem && hasRoomItem && hasTagItem && hasTimeItem && hasTypeItem;
        }
        return true;
      }
      return false;
    }
    );

    dataListLive = dataListLive.filter((item) => {
      let currentDate: Moment = moment.utc();
      let startD: Moment = moment.utc(item.startsAt).local();
      let endD: Moment = moment.utc(item.endAt).local();

      if (item.active) {
        let startDString: string = startD.format('YYYY-MM-DD');
        if (!dateListLive.includes(startDString)) {
          dateListLive.push(startDString);
        }
        // speakerList
        let currentUsers = item['specificusers'];
        if (currentUsers) {
          for (let user of currentUsers) {
            if (!speakerListLive.includes(user._id)) {
              speakerListLive.push(user._id);
            }
          }
        }
        if (item.room && !roomListLive.includes(item.room._id)) {
          roomListLive.push(item.room._id);
        }
        if (item.type && !typeListLive.includes(item.type)) {
          typeListLive.push(item.type);
        }
        // tagList
        let currentTags = item['tags'];
        if (currentTags) {
          for (let tg of currentTags) {
            if (!tagListLive.includes(tg._id)) {
              tagListLive.push(tg._id);
            }
          }
        }
        // console.log('this.dateFilter', this.dateFilter);
        if (this.dateFilterLive.length > 0 || this.speakerFilterLive.length > 0 || this.roomFilterLive.length > 0 || this.tagFilterLive.length > 0 || this.typeFilterLive.length > 0 || this.timeChangedLive) {
          let hasDateItem = true;
          if (this.dateFilterLive.length > 0) {
            hasDateItem = false;
            for (let df of this.dateFilterLive) {
              let filterDate: Moment = moment(df);
              let startDDate: Moment = moment(startD.format('YYYY-MM-DD'));
              let endDDate: Moment = moment(endD.format('YYYY-MM-DD'));
              if (filterDate.diff(startDDate, 'days') >= 0 && filterDate.diff(endDDate, 'days') <= 0) {
                hasDateItem = true;
                break;
              }
            }
          }
          let hasSpeakerItem = true;
          if (this.speakerFilterLive.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.speakerFilterLive) {
              if (item.specificusersIds.includes(df)) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasRoomItem = true;
          if (this.roomFilterLive.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.roomFilterLive) {
              if (item.room && df === item.room._id) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasTagItem = true;
          if (this.tagFilterLive.length > 0) {
            hasTagItem = false;
            for (let df of this.tagFilterLive) {
              if (item.tagsIds.includes(df)) {
                hasTagItem = true;
                break;
              }
            }
          }
          let hasTypeItem = true;
          if (this.typeFilterLive.length > 0) {
            hasTypeItem = false;
            for (let df of this.typeFilterLive) {
              if (item.type && df === item.type) {
                hasTypeItem = true;
                break;
              }
            }
          }
          let hasTimeItem = true;
          if (this.timeChangedLive) {
            hasTimeItem = false;

            let startdateValue = startD.format('YYYY-MM-DD');
            let enddateValue = endD.format('YYYY-MM-DD');
            // let timeValue = startD.format('HH:mm');
            let startFromfullDateValue = moment(startdateValue + 'T' + this.timeFromValueLive);
            let startTofullDateValue = moment(startdateValue + 'T' + this.timeToValueLive);
            let endFromfullDateValue = moment(enddateValue + 'T' + this.timeFromValueLive);
            let endTofullDateValue = moment(enddateValue + 'T' + this.timeToValueLive);

            if ((startFromfullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startFromfullDateValue, 'minutes') >= 0) ||
              (startTofullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startTofullDateValue, 'minutes') >= 0) ||
              (startD.diff(startFromfullDateValue, 'minutes') >= 0 && startTofullDateValue.diff(startD, 'minutes') >= 0) ||
              (endD.diff(endFromfullDateValue, 'minutes') >= 0 && endTofullDateValue.diff(endD, 'minutes') >= 0)
            ) {
              hasTimeItem = true;
            }
          }
          return hasDateItem && hasSpeakerItem && hasRoomItem && hasTagItem && hasTimeItem && hasTypeItem;
        }
        return true;
      }
      return false;
    }
    );
    dataListOld = dataListOld.filter((item) => {
      let currentDate: Moment = moment.utc();
      let startD: Moment = moment.utc(item.startsAt).local();
      let endD: Moment = moment.utc(item.endAt).local();
      if ((!((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0)) && !item.active) {
        let startDString: string = startD.format('YYYY-MM-DD');
        if (!dateListOld.includes(startDString)) {
          dateListOld.push(startDString);
        }
        // speakerList
        let currentUsers = item['specificusers'];
        if (currentUsers) {
          for (let user of currentUsers) {
            if (!speakerListOld.includes(user._id)) {
              speakerListOld.push(user._id);
            }
          }
        }
        if (item.room && !roomListOld.includes(item.room._id)) {
          roomListOld.push(item.room._id);
        }
        if (item.type && !typeListOld.includes(item.type)) {
          typeListOld.push(item.type);
        }
        // tagList
        let currentTags = item['tags'];
        if (currentTags) {
          for (let tg of currentTags) {
            if (!tagListOld.includes(tg._id)) {
              tagListOld.push(tg._id);
            }
          }
        }
        // console.log('this.dateFilter', this.dateFilter);
        if (this.dateFilterOld.length > 0 || this.speakerFilterOld.length > 0 || this.roomFilterOld.length > 0 || this.tagFilterOld.length > 0 || this.typeFilterOld.length > 0 || this.timeChangedOld) {
          let hasDateItem = true;
          if (this.dateFilterOld.length > 0) {
            hasDateItem = false;
            for (let df of this.dateFilterOld) {
              let filterDate: Moment = moment(df);
              let startDDate: Moment = moment(startD.format('YYYY-MM-DD'));
              let endDDate: Moment = moment(endD.format('YYYY-MM-DD'));
              if (filterDate.diff(startDDate, 'days') >= 0 && filterDate.diff(endDDate, 'days') <= 0) {
                hasDateItem = true;
                break;
              }
            }
          }
          let hasSpeakerItem = true;
          if (this.speakerFilterOld.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.speakerFilterOld) {
              if (item.specificusersIds.includes(df)) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasRoomItem = true;
          if (this.roomFilterOld.length > 0) {
            hasSpeakerItem = false;
            for (let df of this.roomFilterOld) {
              if (item.room && df === item.room._id) {
                hasSpeakerItem = true;
                break;
              }
            }
          }
          let hasTagItem = true;
          if (this.tagFilterOld.length > 0) {
            hasTagItem = false;
            for (let df of this.tagFilterOld) {
              if (item.tagsIds.includes(df)) {
                hasTagItem = true;
                break;
              }
            }
          }
          let hasTypeItem = true;
          if (this.typeFilterOld.length > 0) {
            hasTypeItem = false;
            for (let df of this.typeFilterOld) {
              if (item.type && df === item.type) {
                hasTypeItem = true;
                break;
              }
            }
          }
          let hasTimeItem = true;
          if (this.timeChangedOld) {
            hasTimeItem = false;

            let startdateValue = startD.format('YYYY-MM-DD');
            let enddateValue = endD.format('YYYY-MM-DD');
            // let timeValue = startD.format('HH:mm');
            let startFromfullDateValue = moment(startdateValue + 'T' + this.timeFromValueOld);
            let startTofullDateValue = moment(startdateValue + 'T' + this.timeToValueOld);
            let endFromfullDateValue = moment(enddateValue + 'T' + this.timeFromValueOld);
            let endTofullDateValue = moment(enddateValue + 'T' + this.timeToValueOld);

            if ((startFromfullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startFromfullDateValue, 'minutes') >= 0) ||
              (startTofullDateValue.diff(startD, 'minutes') >= 0 && endD.diff(startTofullDateValue, 'minutes') >= 0) ||
              (startD.diff(startFromfullDateValue, 'minutes') >= 0 && startTofullDateValue.diff(startD, 'minutes') >= 0) ||
              (endD.diff(endFromfullDateValue, 'minutes') >= 0 && endTofullDateValue.diff(endD, 'minutes') >= 0)
            ) {
              hasTimeItem = true;
            }
          }
          return hasDateItem && hasSpeakerItem && hasRoomItem && hasTagItem && hasTimeItem && hasTypeItem;
        }
        return true;
      }
      return false;
    }
    );
    // tab Data
    this.dataListFavorites = dataListFavorites;
    this.dataListOld = dataListOld;
    this.dataList = dataList;
    this.dataListLive = dataListLive;

    this.speakerListFavorites = speakerListFavorites.sort((a, b) => { return this.speakerListAll[a].name.localeCompare(this.speakerListAll[b].name) });
    this.speakerListOld = speakerListOld.sort((a, b) => { return this.speakerListAll[a].name.localeCompare(this.speakerListAll[b].name) });
    this.speakerList = speakerList.sort((a, b) => { return this.speakerListAll[a].name.localeCompare(this.speakerListAll[b].name) });
    this.speakerListLive = speakerListLive.sort((a, b) => { return this.speakerListAll[a].name.localeCompare(this.speakerListAll[b].name) });

    //Date
    this.dateListFavorites = dateListFavorites;
    this.dateListOld = dateListOld;
    this.dateList = dateList;
    this.dateListLive = dateListLive;

    this.roomListFavorites = roomListFavorites.sort((a, b) => { return this.roomListAll[a].name.localeCompare(this.roomListAll[b].name) });
    this.roomListOld = roomListOld.sort((a, b) => { return this.roomListAll[a].name.localeCompare(this.roomListAll[b].name) });
    this.roomList = roomList.sort((a, b) => { return this.roomListAll[a].name.localeCompare(this.roomListAll[b].name) });
    this.roomListLive = roomListLive.sort((a, b) => { return this.roomListAll[a].name.localeCompare(this.roomListAll[b].name) });

    this.typeListFavorites = typeListFavorites.sort((a, b) => { return this.typeListAll[a].name.localeCompare(this.typeListAll[b].name) });
    this.typeListOld = typeListOld.sort((a, b) => { return this.typeListAll[a].name.localeCompare(this.typeListAll[b].name) });
    this.typeList = typeList.sort((a, b) => { return this.typeListAll[a].name.localeCompare(this.typeListAll[b].name) });
    this.typeListLive = typeListLive.sort((a, b) => { return this.typeListAll[a].name.localeCompare(this.typeListAll[b].name) });

    this.tagListFavorites = tagListFavorites.sort((a, b) => { return this.tagListAll[a].name.localeCompare(this.tagListAll[b].name) });
    this.tagListOld = tagListOld.sort((a, b) => { return this.tagListAll[a].name.localeCompare(this.tagListAll[b].name) });
    this.tagList = tagList.sort((a, b) => { return this.tagListAll[a].name.localeCompare(this.tagListAll[b].name) });
    this.tagListLive = tagListLive.sort((a, b) => { return this.tagListAll[a].name.localeCompare(this.tagListAll[b].name) });

    this.loading = false;
    this.loaderService.display(false);
  }
  setFilterStored() {
    let keyData = 'agenda';
    this.storedFilters = this.store.get('storedFilters');
    if (this.storedFilters) {
      if (this.storedFilters.hasOwnProperty(keyData)) {
        this.searchVal = this.storedFilters[keyData].term;
      }
    }
  }

  updateFilterStored(storedFilters) {
    let keyData = 'agenda';
    this.storedFilters = {};
    this.storedFilters[keyData] = storedFilters;
    this.store.set('storedFilters', this.storedFilters);
  }

  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  termConfigurationSpeaker(): any {
    if (this.searchInputSpeaker) {
      const searchText: string = this.searchInputSpeaker.nativeElement.value;
      return searchText;
    }
    return '';
  }

  clearFilters(): void {
    this.leadusers = undefined;
    this.guestusers = undefined;
    this.dataList = undefined;
    this.dataListOld = undefined;
    this.dataListLive = undefined;
    this.dataListFavorites = undefined;
    //if (this.tabSelected === 'speaker') {
    this.searchValSpeaker = '';
    this.searchInputSpeaker.nativeElement.value = '';
    //  this.loadUserData();
    //} else {
    this.searchVal = '';
    this.searchInput.nativeElement.value = '';
    this.timeChanged = false;
    this.timeFromValue = '00:01:00';
    this.timeToValue = '23:59:00';
    this.timeChangedOld = false;
    this.timeFromValueOld = '00:01:00';
    this.timeToValueOld = '23:59:00';
    this.timeChangedFavorites = false;
    this.timeFromValueFavorites = '00:01:00';
    this.timeToValueFavorites = '23:59:00';
    this.timeChangedLive = false;
    this.timeFromValueLive = '00:01:00';
    this.timeToValueLive = '23:59:00';
    this.speakerFilter = [];
    this.speakerFilterOld = [];
    this.speakerFilterFavorites = [];
    this.speakerFilterLive = [];
    this.dateFilter = [];
    this.dateFilterOld = [];
    this.dateFilterFavorites = [];
    this.dateFilterLive = [];
    this.roomFilter = [];
    this.roomFilterOld = [];
    this.roomFilterFavorites = [];
    this.roomFilterLive = [];
    this.typeFilter = [];
    this.typeFilterOld = [];
    this.typeFilterFavorites = [];
    this.typeFilterLive = [];
    this.tagFilter = [];
    this.tagFilterOld = [];
    this.tagFilterFavorites = [];
    this.tagFilterLive = [];
    this.loadData();
    // }
  }
  refresh(): void {
    if (this.tabSelected === 'speaker') {
      this.loadUserData();
    } else {
      this.loadData();
    }
  }
  filterByTags(val): void {
    this.selectedTags = val;
    this.loadData();
  }
  cancelThru(e) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    }
  }
  loadUserData() {
    let speakerListIdAll = Object.keys(this.speakerListAll);
    let speakerListLeadsIdAll = Object.keys(this.speakerListLeadsAll);
    let speakerListGuestsIdAll = Object.keys(this.speakerListGuestsAll);
    this.loading = true;
    let termConfiguration = this.termConfigurationSpeaker();
    let filters = {};
    filters['$and'] = [
      { "organizationId._id": { "$eq": this.requestService.orgId } },
      { "_id": { "$in": speakerListIdAll } },
      { "hideFromAgenda": { "$ne": true } }
    ];
    let fields = ['_id', 'firstName', 'lastName', 'title', 'email', 'pictureLink', 'company', 'phone', 'isEmailAddress', 'isVideoChat', 'isTextChat', 'isPhoneNumber', 'chat_dnd', 'details', 'linkedIn', 'website', 'hideFromAgenda'];
    let filterObj = { page: this.pageNumber, term: termConfiguration, perpage: 20000, order: [{ field: 'firstName', order: 'asc' }, { field: 'lastName', order: 'asc' }], fieldKeys: fields, filter: filters, termfields: ["name", "firstName", "lastName", "details"] };
    this.updateFilterStored(filterObj);
    this.requestService.getUsersList(filterObj, (data, error) => {
      //console.log(data);
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        this.users = data.results;
        this.leadusers = data.results.filter((user) => speakerListLeadsIdAll.includes(user._id));
        this.guestusers = data.results.filter((user) => speakerListGuestsIdAll.includes(user._id));
      }
      this.loading = false;
    });
  }
  viewProfile(user) {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '800px',
      data: {
        dataType: 'resource/user',
        title: user.name + ' ' + this.translate.instant('Profile'),
        onlyView: true,
        data: { _id: user._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }

  toggleResources(bool: boolean) {
    this.hideResources = bool;
  }
  private userBuildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let orgId = this.requestService.orgId;
      let lang = this.requestService.lang;
      this.requestService.getMetaData('resource/user', undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.fieldsDetails = this.buildUserMetaSetting(data.results);
          this.loadData();
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      }, orgId, lang);
    }
  }
  buildUserMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildUserMetaSetting(col);
        tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildUserMetaSetting(col, col.name) });
      } else if (col.type === 'table') {
        dataObject[col.name] = this.buildUserMetaSetting(col);
      }
    }
    this.metaFieldTabsSetting = tabObject;
    return dataObject;
  }
}
