<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">
  <div *ngIf="!hideBecauseOne">
    <div *ngIf="apiTarget !== 'search' && apiTarget !== 'searchObject'">
      <mat-form-field>
        <input [style.visibility]="'hidden'" matInput name="{{itemName}}" [(ngModel)]="value" [required]="required" [errorStateMatcher]="esMatcher">
        <mat-label>{{placeholder | translate}}</mat-label>
        <mat-select ng-model="itemName" [required]="required" (selectionChange)="setAttribute($event)" [value]="value">
          <mat-option value="" >Select {{placeholder | translate}}</mat-option>
          <mat-option *ngFor="let itm of selectData" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="parentFormSubmitted && required && (value === undefined || value === '')">
          {{placeholder | translate}} is required.
        </mat-error>
      </mat-form-field>
      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
    <div *ngIf="(apiTarget === 'search' || apiTarget === 'searchObject') && dataType !== 'layout'" class="searchClass">
      <ng-select *ngIf="value.hasOwnProperty('_id')" class="custom" ng-model="itemName" name="{{itemName}}"
        [items]="filteredData | async" bindLabel="text" autofocus bindValue="_id" [loading]="loading"
        [clearable]="clearable" (search)="termSearch($event)" (clear)="termSearch('')" (change)="setAttribute($event)"
        [(ngModel)]="value._id" placeholder="{{placeholder | translate}}{{required ? ' *' : ''}}">
      </ng-select>
      <ng-select *ngIf="!value.hasOwnProperty('_id')" class="custom" ng-model="itemName" name="{{itemName}}"
        [items]="filteredData | async" bindLabel="text" autofocus bindValue="_id" [loading]="loading"
        [clearable]="clearable" (search)="termSearch($event)" (clear)="termSearch('')" (change)="setAttribute($event)"
        [(ngModel)]="value" placeholder="{{placeholder | translate}}{{required ? ' *' : ''}}">
      </ng-select>
      <div class="my-error mat-error mat-form-field-subscript-wrapper"
        *ngIf="parentFormSubmitted && required && (value === undefined || value.length === 0)">
        {{placeholder | translate}} is required.
      </div>
    </div>
    <div *ngIf="(apiTarget === 'search' || apiTarget === 'searchObject') && dataType === 'layout'">
      <mat-form-field class="example-full-width">
        <input matInput type="text" [value]="dataText" placeholder="{{placeholder | translate}}{{required ? ' *' : ''}}"
          aria-label="Number" class="autocomplete" [matAutocomplete]="auto" style="width: 100%;"
          (blur)="onBlurMethod($event.target.value)" [formControl]="myControl">
        <mat-autocomplete class="autocomplete" #auto="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)="setAttribute($event.option.value)">
          <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text}}">
            {{option.text}}
            <span class="layoutBoxPreview" (mouseenter)="toggleClass($event,'layoutBoxPreviewShow', option)"
              (mouseleave)="toggleClass($event,'layoutBoxPreviewShow', option)">
              <mat-icon class="previewIcon" style="margin-top: 10px;" matTooltip="{{'Preview' | translate}}"
                matTooltipClass="tooltip-red">remove_red_eye</mat-icon>
              <div class="layoutpreview">
                <app-screen-layout-preview *ngIf="settingLayoutObject" [settingObject]="settingLayoutObject.desktop">
                </app-screen-layout-preview>
              </div>
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>