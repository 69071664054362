import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';
import { LoaderService } from '../../../../shared/services';
import { Utils } from '../../../../shared/helpers/utils';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmCkeditorEntityDialogComponent, ConfirmInputEntityDialogComponent } from '../../modals';
declare var CKEDITOR;

@Component({
    selector: 'questionnaire-sub-option',
    templateUrl: './questionnaire-sub-option.component.html',
    styleUrls: ['../tileblocks.component.scss', './questionnaire-sub-option.component.scss']
})
export class QuestionnaireSubOptionComponent implements BlockComponent {
  @Input() block: any;

  @Input('subOption') subOption: any;
  @Input('parentIndex') parentIndex: number;
  @Input('currentIndex') currentIndex: number;
  @Input('questionWidth') questionWidth: any;
  @Input('optionWidth') optionWidth: any;
  @Input('levelIndex') levelIndex: number;
  @Input('isLevel') isLevel: boolean;
  @Output() changeSubLevel = new EventEmitter<any>();
  @Output() removeSubLevel = new EventEmitter<any>();
  public config = {
      uiColor: '#F0F3F4',
      height: '250',
      allowedContent : true,
      removeButtons : 'Image'
  };
  public editorConfig: any = {
    toolbar: [
      // { name: 'document', items: ['Source', '-'] },
      { name: 'clipboard', items: ['Undo', 'Redo', '-'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
      { name: 'links', items: ['Link', 'Unlink', '-'] },
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', '-'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
    ],
    height: '310px',
    shiftEnterMode: CKEDITOR.ENTER_DIV,
    enterMode: CKEDITOR.ENTER_DIV,
    removePlugins: 'magicline',
    linkShowAdvancedTab: false,
    linkShowTargetTab: false,
    resize_enabled: false
  };
  constructor(public utils: Utils, public dialog: MatDialog, private loaderService: LoaderService, private translate: TranslateService) {}

  public onChange(e: any = undefined) {
      // e.preventDefault();
      let level = { "parentIndex": this.parentIndex, "currentIndex": this.currentIndex };
      this.changeSubLevel.emit(level);
  }


  getAlphaLetter(i: number) {
    return (i >= 26 ? this.getAlphaLetter((i / 26 >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];
  };

  addOption(e: any) {
    var optionObj = { "option": "" };
    this.subOption.options.push(optionObj);
  };
  controlQuestionType(type: any) {
    this.onChange();
  };

  controlChange(value: any) {
    this.onChange();
  };
  addSubOption(e: any, index: any, type: string) {
    e.preventDefault();

    if (!this.subOption.options[index].hasOwnProperty("subQuestions")) {
      this.subOption.options[index]["subQuestions"] = [];
    }

    if (type === "questions") {
      this.subOption.options[index]["subQuestions"].push({
        "type": "questions",
        "questionText": "",
        "questionType": "single",
        "inputControlType": "radio",
        "options": [{ "option": "" }, { "option": "" }]
      });
    } else if (type === "description") {
      this.subOption.options[index]["subQuestions"].push({
        "type": "description",
        "controlType": "text",
        "questionText": ""
      });
    }
  };

  trackByIndex(index: number, obj: any): any {
    return index;
  };

  getLevelIndex(idx) {
    let idxVal = "i";

    for (let i=0; i < idx; i++) {
      idxVal = idxVal + "i";
    }

    return idxVal;
  };

  deleteSubLevel(e: any) {
    let level = { "parentIndex": this.parentIndex, "currentIndex": this.currentIndex };
    this.removeSubLevel.emit(level);
  };

  deleteLevel(level: any) {
    // console.log('deleteLevel', level, this.subOption.options[level.parentIndex].subQuestions[level.currentIndex]);
    this.subOption.options[level.parentIndex].subQuestions.splice(level.currentIndex, 1);
  };

  updateLevel(level: any) {
    // console.log('updateLevel', level, this.subOption.options[level.parentIndex].subQuestions[level.currentIndex]);
  };

  editPopup(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
        width: '1200px',
        disableClose: false,
        data: {
          title: this.translate.instant('Edit Pop Up'),
          data: this.subOption.options[index]!.popup,
          config: this.editorConfig,
          cancelbtn: this.translate.instant('Close'),
          confirmbtn: this.translate.instant('Confirm'),
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.subOption.options[index].popup = result;
          this.onChange();
        }
      });
  };
  editConfirm(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
        width: '1200px',
        disableClose: false,
        data: {
          title: this.translate.instant('Edit Confirmation'),
          data: this.subOption.options[index]!.confirmation,
          config: this.editorConfig,
          cancelbtn: this.translate.instant('Close'),
          confirmbtn: this.translate.instant('Confirm'),
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.subOption.options[index].confirmation = result;
          this.onChange();
        }
      });
  };

  removeOption(e: any, index) {
    if (index !== -1 && this.subOption.options.length > 1) {
      this.subOption.options.splice(index, 1);
      this.onChange();
    }
  };
}
