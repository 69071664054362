import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { LoaderService } from "./loader.service";
import { RequestService } from "./request.service";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { Router } from '@angular/router';
import { UserActivityService } from ".";
import { DeviceDetectorService } from "ngx-device-detector";
import * as Ably from 'ably';

@Injectable()
export class AblyService {

    ably: any = undefined;
    private channelName: string = 'global';
    channel: any = undefined;
    globalChannel: any = undefined;
    myChannel: any = undefined;
    private connectedChannels: any = [];
    private dialog: any = undefined;

    constructor(private requestService: RequestService, private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private loadService: LoaderService, private router: Router, private userActivityService: UserActivityService, private deviceService: DeviceDetectorService) { }

    init() {
        if (typeof Ably != 'undefined' && !this.ably) {
            this.ably = new Ably.Realtime({
                log: { level: 1 },
                authCallback: (tokenParams, callback) => {
                    try {
                        this.requestService.getAblyToken().subscribe((data: any) => {
                            callback(null, data.result)
                        });
                        // this.requestService.getAblyToken((data, error) => {
                        //     console.log('da', data, error)
                        //     callback(null, data)
                        // }) // Make a network request to your server                        
                    } catch (error) {
                        callback(error, null)
                    }
                }

                // authUrl: environment.serverUrl + 'resource/auth', authHeaders: {
                //     'Authorization': this.requestService.getToken()
                // },
                // authCallback: (tokenParams, callback) => {
                //     console.log('tokenParams', tokenParams, 'callback', callback)
                // }
            });

            this.ably.connection.on('failed', () => {
                this.showAblyErrorDialog();
            });

            this.ably.connection.on('suspended', () => {
                this.showAblyErrorDialog();
            });

            // this.ably.connection.on('connected', () => {
            //     console.log('ably connected')
            // });
        }
    }

    setupGlobalChannel() {
        this.channel = this.getChannel(this.channelName);
        this.globalChannel = this.getGlobalChannel(this.channelName);
    }

    getOwnChannel(user: any) {
        if (!this.myChannel) {
            this.myChannel = this.getChannel('private-' + user._id);
            let presenceObj = this.buildPresence(user);
            this.myChannel.presence.enter(presenceObj);
            // this.myChannel.presence.subscribe((member) => {
            //     if (member.action === 'present' && member.clientId === user._id && member.data.browser === this.deviceService.getDeviceInfo().browser.toLowerCase()) {
            //         this.userActivityService.insertLog('Window', 'Duplicate tabs', 'Redirected to empty page');
            //         this.router.navigate(['/empty/close'], { skipLocationChange: false }).then((e) => window.location.reload());
            //     }
            // });
        }
    }

    private buildPresence(user: any): any {
        let presenceObj;
        let nickname;
        if (user.hasOwnProperty('nickName') && user['nickName'])
            nickname = user['nickName'];
        else {
            nickname = user['firstName'];
            if (user['lastName']) {
                nickname += ' ' + user['lastName'].substr(0, 1);
            }
        }

        presenceObj = { 'userId': user['_id'], 'name': user['name'], 'nickname': nickname };

        if (user['chat_dnd'] == undefined)
            presenceObj['chat_dnd'] = false;
        else
            presenceObj['chat_dnd'] = user['chat_dnd'];

        if (user['listedInTheDirectory'] == 'no') {
            presenceObj['showTextChat'] = false;
            presenceObj['showVideoChat'] = false;
        }
        else {
            if (user['isTextChat'] == undefined)
                presenceObj['showTextChat'] = false;
            else
                presenceObj['showTextChat'] = user['isTextChat'];

            if (user['isVideoChat'] == undefined)
                presenceObj['showVideoChat'] = false;
            else
                presenceObj['showVideoChat'] = user['isVideoChat'];
        }
        presenceObj['browser'] = this.deviceService.getDeviceInfo().browser.toLowerCase();
        return presenceObj;
    }

    detachChannel(channelName: string) {
        let channel = this.connectedChannels.find(i => i.name === channelName + '-' + this.requestService.orgId + '-' + environment.environment);
        if (channel)
            channel.detach();
        this.connectedChannels = this.connectedChannels.filter(i => i.name !== channelName + '-' + this.requestService.orgId + '-' + environment.environment)
    }

    getGlobalChannel(channelName: string) {
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName + '-' + environment.environment);
        if (channelIdx !== -1)
            return this.connectedChannels[channelIdx];
        else {
            let channel = this.ably.channels.get(channelName + '-' + environment.environment);
            this.connectedChannels.push(channel);
            return channel;
        }
    }
    getChannel(channelName: string, firstTimeGetCallBack?: any) {
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName + '-' + this.requestService.orgId + '-' + environment.environment);
        if (channelIdx !== -1) {
            if (firstTimeGetCallBack)
                firstTimeGetCallBack(this.connectedChannels[channelIdx]);

            return this.connectedChannels[channelIdx];
        }
        else {
            let channel = this.ably.channels.get(channelName + '-' + this.requestService.orgId + '-' + environment.environment);
            this.connectedChannels.push(channel);

            if (firstTimeGetCallBack)
                firstTimeGetCallBack(channel);
            return channel;
        }
    }

    publishGlobal(messageName: string, payload: any, callBack?: any) {
        this.globalChannel.publish(messageName, payload, (error) => {
            if (error) {
                console.log('error publishing ably', error);
            }
            if (callBack)
                callBack();
        });
    }
    publish(messageName: string, payload: any, channel: any = this.channel, callBack?: any) {
        channel.publish(messageName, payload, (error) => {
            if (error) {
                console.log('error publishing ably', error);
            }
            if (callBack)
                callBack();
        });
    }

    subscribeGlobal(messageName: string, callBack: (message) => void) {
        this.globalChannel.subscribe(messageName, (message) => {
            // console.log(message);
            try {
                callBack(message.data);
            }
            catch (e) {
                console.error(e);
            }
        }, (error) => {
            if (error) {
                if (error.code == '90007') {
                    this.showAblyErrorDialog();
                }
                // console.log('error publishing ably', error);
            }
        });
    }

    subscribe(messageName: string, callBack: (message) => void, channel: any = this.channel) {
        channel.subscribe(messageName, (message) => {
            // console.log(message);
            try {
                callBack(message.data);
            }
            catch (e) {
                console.error(e);
            }
        }, (error) => {
            if (error) {
                if (error.code == '90007') {
                    this.showAblyErrorDialog();
                }
                // console.log('error publishing ably', error);
            }
        });
    }

    quickPublishToPrivateChannel(userId: string, messageType: string, payload: any, callBack?: any) {
        let channel = this.getChannel('private-' + userId);
        if (channel) {
            this.publish(messageType, payload, channel, callBack);
        }
    }
    quickPublishToChannel(channelId: string, id: string, messageType: string, payload: any, callBack?: any) {
        let channel = this.getChannel(channelId + '-' + id);
        if (channel) {
            this.publish(messageType, payload, channel, callBack);
        }
    }

    sendSessionSignal(sessionId: string, messageType: string, payload: any) {
        let channel = this.getChannel('session-' + sessionId);
        if (channel) {
            this.publish(messageType, payload, channel);
        }
    }

    sendRoomSignal(roomId: string, messageType: string, payload: any) {
        let channel = this.getChannel('room-' + roomId);
        if (channel) {
            this.publish(messageType, payload, channel);
        }
    }

    subscribeToSessionSignals(messageName: string, callBack: (message) => void, sessionId: any) {
        this.subscribe(messageName, callBack, this.getChannel('session-' + sessionId));
    }

    subscribeToRoomSignals(messageName: string, callBack: (message) => void, roomId: any) {
        this.subscribe(messageName, callBack, this.getChannel('room-' + roomId));
    }

    subscribeToChannelSignals(messageName: string, callBack: (message) => void, channelId: string, id: string) {
        this.subscribe(messageName, callBack, this.getChannel(channelId + '-' + id));
    }

    connectToChannelAndSetPresence(user, channelId: string, id: string, callBack: (members) => void, returnPresence: boolean = false ) {
        this.getChannel(channelId + '-' + id, (channel)=>{  
            let presenceObj = this.buildPresence(user);
            channel.presence.enter(presenceObj);
            if(returnPresence){
                channel.presence.get((error, members) => {
                    callBack(members);
                });
                // channel.presence.subscribe((members) => {
                //     callBack(members);
                // });
            }else{
                callBack(undefined);
            }
        });
    }
    connectToPrivateUserChannelAndGetPresence(userId: string) {
        let channel = this.getChannel('private-' + userId);
        channel.presence.subscribe((member) => {
            console.log(member);
        });
    }

    sendUpdateToUsersInMyChannel(user: any) {
        let presenceObj = this.buildPresence(user);
        this.myChannel.presence.update(presenceObj);
    }

    getStatus(callback: (status: any | undefined) => void) {
        if (this.ably) {
            if (this.ably.connection && this.ably.connection.state === 'connected') {
                callback('connected');
            } else {
                this.ably.connection.on('connected', () => {
                    callback('connected');
                });
            }
        } else {
            callback('failed');
        }
    }

    private showAblyErrorDialog() {
        if (!this.dialog) {
            this.zone.run(() => {
                this.loadService.display(false);
                this.dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('You were idle for a long time or you experienced a connection issue'), {
                    overlayClickToClose: false,
                    showCancelButton: false,
                    declineText: this.translate.instant('Refresh')
                }, 'fit-content');
                this.dialog.afterClosed().subscribe((res) => {
                    if (res != undefined) {
                        window.location.reload();
                    }
                });
            });
        }
    }

    unsubscribeFromChannelIfExists(channelName: string) {
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName + '-' + this.requestService.orgId + '-' + environment.environment);
        if (channelIdx !== -1) {
            this.connectedChannels[channelIdx].unsubscribe();
            this.detachChannel(channelName);
        }
    }
    unsubscribeFromChannelIdIfExists(channelId: string, id: string) {
        let channelName = channelId + '-' + id;
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName + '-' + this.requestService.orgId + '-' + environment.environment);
        if (channelIdx !== -1) {
            this.connectedChannels[channelIdx].unsubscribe();
            this.detachChannel(channelName);
        }
    }
}
